import React, {useState, useEffect} from "react";
import { useSnackbar } from 'react-simple-snackbar'
import { CustomOptions } from '../login/Login';
import { USER_TYPE } from "../../../util/EnvHelper";
import { checkPassword } from '../../../api/API'
import { useNavigate } from 'react-router'
import './login.scss'
import { Modal } from 'bootstrap'



const PartnerSelectModal = ({ branchList, password,onModalClose })=> {
    
    let partRid = null
    const navigate = useNavigate();

    const branchLogin = () => {
        onModalClose(partRid)
    }

    const handleChange = (e) => {
        partRid = e.target.value
    }

    const refreshPage = () => {
        navigate('/login')
    }

    useEffect(() => {
        window.onpopstate = e => {
            window.location.reload()
        }
    },[])

    return (
        <div className="modal fade custom-modal" id="partnerModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-scrollable modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-body px-0">
                        <div className="container  p-2rem">
                            <div className="row">
                                <div className="col-md-12 my-auto mb-2">
                                    <p className="float-start fs-large-med text-dark">
                                        {branchList[0].partName}
                                    </p>
                                    <button type="button" onClick={()=>refreshPage()} className="float-end px-0 custom-btn bg-transparent border-0" data-bs-dismiss="modal" aria-label="Close">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-x-circle float-end text-primary" viewBox="0 0 16 16">
                                            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                                            <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                                        </svg>
                                    </button>
                                </div>
                                {branchList!==null? branchList.map((item,index) => (
                                    <>
                                        <hr></hr>
                                            <div className="col-md-12">
                                                <input className="form-check-input pointer" name="radio-group" type="radio" value={item.partRid} id={item.partCode} onChange={(e)=>{handleChange(e)}}></input>
                                                <label className="form-check-label ms-2 pointer" htmlFor={item.partCode}>
                                                    {item.partCode} ( {item.contactPerson} )
                                                </label>
                                                <label className="fs-normal-reg pointer" htmlFor={item.partCode}><span className="fs-normal-reg">{item.address1}{item.address2}<br></br>{item.address3}</span></label>
                                                <p className="fs-normal-reg" htmlFor={item.partCode}>{item.stateName}</p>
                                                
                                            </div>
                                    </>
                                )):null}
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer pb-0">
                            <button onClick={() =>  {branchLogin()}} type="button" className="btn btn-success text-white fs-large-med me-3 mb-3" data-bs-dismiss="modal">Continue</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PartnerSelectModal