import React, { useEffect, useState } from 'react'
import './order.scss';
import { Modal } from 'bootstrap'
import Pagination from '@mui/material/Pagination';
import AdminLayout from '../../../AdminLayout';
import SearchIcon from "@mui/icons-material/SearchOutlined";
import OrderDetailsModal from './OrderDetailsModal';
import OrderEditModal from './OrderEditModal';
import { CustomOptions } from '../../user/login/Login';
import { useSnackbar } from 'react-simple-snackbar';
import { getOrderList, getTaxInvoice } from '../../../api/API';
import { ShimmerHome, ShimmerOrders, ShimmerOrdersTable } from '../../../components/shimmer-effect/ShimmerEffect';
import moment from 'moment'
import { displayOrderId, toDisplayDateTime, toPrefixedLocalePrice, calendarFormat, toDisplayDateMonth } from '../../../util/Util';
import { Stack } from '@mui/material';
import { CSVLink } from 'react-csv';
import { PAGINATION_ITEM_PER_PAGE } from '../../../util/EnvHelper';
import AddOrderModal from './AddOrderModal';
const OrderList = () => {


    const [orderItemID, setOrderItemID] = useState(0);
    const [orderDetailList, setOrderDetailList] = useState([]);
    const [isLoading, setIsLoading] = React.useState(true);
    const [openSnackbar] = useSnackbar(CustomOptions)
    const [state, setState] = React.useState({
        orderList: [], noOfPages: 0
    });
    const [date, setDate] = useState({
        startDate: new Date(), endDate: new Date(),
    })
    const itemsPerPage = PAGINATION_ITEM_PER_PAGE;
    const [page, setPage] = React.useState(1);
    const fileName = "order-detail.xls";
    const headers = [
        { label: "Order No", key: "ohRid" },
        { label: "Customer Name", key: "partName" },
        { label: "Customer Code", key: "partCode" },
        { label: "Order Date", key: "orderDateTime" },
        { label: "Order status", key: "statusText" },
        { label: "Item count", key: "itemCount" },
        { label: "Total price", key: "netAmount" },
        { label: "Payment Id", key: "pgPaymentId" },
        { label: "Tracking Id", key: "tracking_id" },
    ];
    const [filterList, setFilterList] = useState([state.orderList]);

    useEffect(() => {
        getOrderListData()
    }, [])



    const getOrderListData = async () => {
        getOrderList()
            .then((data) => {
                // console.log(data)
                setState(
                    {
                        ...state,
                        orderList: data,
                        noOfPages: Math.ceil(data.length / itemsPerPage)

                    })
                setFilterList(data)
                setIsLoading(false)
            }).catch(e => {
                setIsLoading(false)
                openSnackbar(e)
            })
    };

    const downloadInvoice = (ohRid) => {
        getTaxInvoice(ohRid)
            .then(res => {
                const filename = res.headers['content-disposition'].split('"')[1]
                console.log(filename);
                const url = window.URL.createObjectURL(new Blob([res.data]));
                const link = document.createElement('a');

                link.href = url;
                link.setAttribute('download', filename);

                document.body.appendChild(link);
                link.click();
            })
    }

    const editOrderModal = async () => {
        const modalVendorDetails = new Modal(document.querySelector('#modalEditOrder'), {
            keyboard: false
        })
        modalVendorDetails.show()
    }

    const viewOrderModal = async (orderDetails, itemId) => {
        setOrderDetailList(orderDetails)
        setOrderItemID(itemId)
        const modalDelVendor = new Modal(document.querySelector('#modalOrderDetails'), {
            keyboard: false
        })
        modalDelVendor.show()
    }

    const addOrderModal = async () => {
        const addOrderModal = new Modal(document.querySelector('#addOrder'), {
            keyboard: false,
            backdrop: 'static'
        })
        addOrderModal.show()
    }

    const handleChange = (event) => {
        let searchDate = event.target.value;
        setDate({
            ...date,
            [event.target.name]: event.target.value,
        });
        if (event.target.name == 'endDate') orderFilter(date.startDate, searchDate)
        else orderFilter(searchDate, date.endDate)
        // setIsLoading(true)
    };


    const orderFilter = (startDate, endDate) => {
        setPage(1);
        const filteredArray = (state.orderList).filter(function (item) {
            let orderDate = calendarFormat(item.orderDateTime)

            return (new Date(orderDate).getTime() >= new Date(calendarFormat(startDate)).getTime() &&
                new Date(orderDate).getTime() <= new Date(calendarFormat(endDate)).getTime());
        });
        setFilterList(filteredArray);

    }

    const statusFilter = (e) => {
        console.log(e.target.value)
        if (e.target.value === "false") {
            getOrderListData()
        }
        else {
            setPage(1);
            let filterStatus = e.target.value;
            const filteredArray = (state.orderList).filter(function (item) {
                return (item.orderStatus == filterStatus);
            });
            setFilterList(filteredArray);
        }
    }

    const searchFliter = (e) => {
        console.log(state.orderList)
        setPage(1);
        let searchText = e.target.value;
        let searchResult = [];
        searchResult = (state.orderList).filter((item) => {
            const orderId = item.orderId ? item.orderId.toUpperCase() : ''.toUpperCase();

            return (orderId.search(searchText) != -1)
        });
        setFilterList(searchResult);

    }

    const onPageChange = (event, value) => {
        setPage(value);
    };

    return (
        <AdminLayout title="Orders">
            <OrderDetailsModal orderDetailsData={orderDetailList} item={orderItemID} />
            <OrderEditModal />
            <AddOrderModal />
            <div className="container-fluid   align-self-center">
                <div className="row justify-content-center">

                    <div className="col-md-12 d-flex flex-row justify-content-between">
                        <div className="search-input-btn d-flex flex-row justify-content-between border-0 rounded-bg px-3  py-2 col-md-3">

                            <div className="my-auto">
                                <SearchIcon />
                            </div>
                            <input type="text"
                                className="form-control border-0 bg-transparent fs-large-regular "
                                placeholder="Search by Order Number" onChange={(e) => { searchFliter(e) }} />
                        </div>
                        <div className="my-auto col-md-2">
                            <input type="date" className="form-control login-input p-07rem mb-3 w-100 fs-large-med px-4"
                                onChange={handleChange}
                                name="startDate" value={calendarFormat(date.startDate)} />
                        </div>
                        <div className="my-auto col-md-2">
                            <input type="date" className="form-control login-input p-07rem mb-3 w-100 fs-large-med px-4"
                                onChange={handleChange}
                                name="endDate" value={calendarFormat(date.endDate)} />
                        </div>
                        <div className="my-auto col-md-2">
                            <select className="form-select custom-select mb-3 fs-large-med"
                                aria-label="Default select example" placeholder='Filter by Status' onChange={(e) => { statusFilter(e) }}>
                                <option value={false}>All</option>
                                <option value="1">Order Placed</option>
                                <option value="2">Invoice Generated</option>
                                <option value="3">Dispatched</option>
                            </select>
                        </div>
                        <div className="col-md-1 custom-excel-upload">
                            <div className="my-auto">
                                <CSVLink
                                    headers={headers}
                                    data={state.orderList}
                                    filename={fileName}
                                >
                                    <div className=' col-md-12'>
                                        <label className="text-primary px-3 py-2  rounded-bg fs-large-semibold pointer">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-file-earmark-excel text-primary me-3" viewBox="0 0 16 16">
                                                <path d="M5.884 6.68a.5.5 0 1 0-.768.64L7.349 10l-2.233 2.68a.5.5 0 0 0 .768.64L8 10.781l2.116 2.54a.5.5 0 0 0 .768-.641L8.651 10l2.233-2.68a.5.5 0 0 0-.768-.64L8 9.219l-2.116-2.54z" />
                                                <path d="M14 14V4.5L9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2zM9.5 3A1.5 1.5 0 0 0 11 4.5h2V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h5.5v2z" />
                                            </svg>
                                            {isLoading ? 'Loading ...' : 'Export'}
                                        </label>

                                    </div>

                                </CSVLink>
                            </div>
                        </div>
                    </div>
                    <div className='col-md-12'>
                        <div className="col-md-2">
                            <button onClick={() => { addOrderModal() }} className="px-4 py-2 b-none w-100 rounded-bg fs-normal-semibold text-green ">+ Add new order</button>
                        </div>
                    </div>

                    <div className=" col-md-12  p-3 table-container mt-3">
                        <table className="table table-striped table-borderless  " id="order-table">
                            <thead>
                                <tr className=" fs-large-light ">

                                    <td scope="col " >
                                        <div className=" d-flex flex-row my-auto">
                                            <div className="my-auto ">
                                                <p className="me-2 ">Order No</p>
                                            </div>
                                        </div>
                                    </td>
                                    <td scope="col">
                                        <div className=" d-flex flex-row my-auto">
                                            <div className="my-auto">
                                                <p className="me-2 ">Customer</p>
                                            </div>
                                        </div>
                                    </td>
                                    <td scope="col">
                                        <div className=" d-flex flex-row my-auto">
                                            <div className="my-auto">
                                                <p className="me-2 ">Order date</p>
                                            </div>
                                        </div>
                                    </td>
                                    <td scope="col" >
                                        <div className=" d-flex flex-row my-auto">
                                            <div className="my-auto">
                                                <p className="me-2 ">Status</p>
                                            </div>
                                        </div>
                                    </td>
                                    <td scope="col" >
                                        <div className="d-flex flex-row my-auto justify-content-center">
                                            <div className="my-auto">
                                                <p className="me-2 ">Total</p>
                                            </div>
                                        </div>
                                    </td>
                                    <td scope="col" >
                                        <div className=" d-flex flex-row my-auto justify-content-center">
                                            <div className="my-auto">
                                                <p className="me-2 ">Tracking ID</p>
                                            </div>
                                        </div>
                                    </td>
                                    <td scope="col " >
                                        <div className=" d-flex flex-row my-auto">
                                            <div className="my-auto">
                                                <p className="me-2 mb-0"></p>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            </thead>
                            <tbody >
                                {isLoading ? (<ShimmerOrdersTable />) :
                                    filterList !== null ?
                                        filterList
                                            .slice((page - 1) * itemsPerPage, page * itemsPerPage)
                                            .map((order, index) => {
                                                return (


                                                    <tr key={filterList.indexOf(order)} className="align-middle fs-large-med" >

                                                        <td scope="row " >{(order.orderId)}</td>
                                                        <td>{order.partName} - ({order.partCode})</td>
                                                        {/* <td className="text-center">{order.partCode}</td> */}
                                                        <td>{toDisplayDateMonth(order.orderDateTime)}</td>
                                                        <td>{order.statusText}</td>
                                                        <td className="text-center">{toPrefixedLocalePrice(order.netAmount)}</td>
                                                        <td className="text-center">-</td>
                                                        <td>
                                                            <div className="d-flex flex-row justify-content-center">

                                                                {/*<button className="action-circle me-2" onClick={editOrderModal} >
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-pencil text-green" viewBox="0 0 16 16">
                                                                <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z" />
                                                            </svg>
                                        </button>*/}            {order.orderStatus === 3 ?
                                                                    <a onClick={() => { downloadInvoice(order.ohRid) }} target="_blank" rel="noreferrer" className="action-circle me-2 p-1 pointer">
                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-download" viewBox="0 0 16 16">
                                                                            <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z" />
                                                                            <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z" />
                                                                        </svg>

                                                                    </a>
                                                                    : null
                                                                }
                                                                <button className="action-circle me-2" onClick={() => { viewOrderModal(order.orderDetailList, order.ohRid) }}>
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-cart-check  text-green" viewBox="0 0 16 16">
                                                                        <path d="M11.354 6.354a.5.5 0 0 0-.708-.708L8 8.293 6.854 7.146a.5.5 0 1 0-.708.708l1.5 1.5a.5.5 0 0 0 .708 0l3-3z" />
                                                                        <path d="M.5 1a.5.5 0 0 0 0 1h1.11l.401 1.607 1.498 7.985A.5.5 0 0 0 4 12h1a2 2 0 1 0 0 4 2 2 0 0 0 0-4h7a2 2 0 1 0 0 4 2 2 0 0 0 0-4h1a.5.5 0 0 0 .491-.408l1.5-8A.5.5 0 0 0 14.5 3H2.89l-.405-1.621A.5.5 0 0 0 2 1H.5zm3.915 10L3.102 4h10.796l-1.313 7h-8.17zM6 14a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm7 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
                                                                    </svg>
                                                                </button>
                                                            </div>
                                                        </td>
                                                    </tr>

                                                );
                                            }) :
                                        <tbody> <td className=" w-3"></td>
                                            <td scope="row"></td>
                                            <td scope="row"><div className="w-100 mt-3 text-center justify-content-center">
                                                <h1 className="fs-large-regular"><i>No records</i></h1>
                                            </div></td></tbody>
                                }
                            </tbody>
                        </table>

                        <div className=" mt-5 justify-content-center text-center">
                            {state.orderList !== null || state.orderList !== '' ?
                                state.orderList.length == 0 ? null : <Stack spacing={2} alignItems="center">
                                    <Pagination
                                        count={Math.ceil((filterList).length / itemsPerPage)}
                                        page={page}
                                        onChange={onPageChange}
                                        defaultPage={1}
                                        size="large"
                                        variant="outlined" color="success"
                                        showFirstButton
                                        showLastButton
                                    />
                                </Stack>
                                : <></>}
                        </div>
                    </div>
                </div>
            </div>



        </AdminLayout>
    )

}
export default OrderList