import React, { useEffect, useState } from 'react'
import './query.scss';
import { Modal } from 'bootstrap'
import Pagination from '@mui/material/Pagination';
import AdminLayout from '../../../AdminLayout';
import SearchIcon from "@mui/icons-material/SearchOutlined";
import { CustomOptions } from '../../user/login/Login';
import { useSnackbar } from 'react-simple-snackbar';
import { getComplaintdetails, getComplaintListDetails, getOrderList } from '../../../api/API';
import { feedListData } from '../feedback/Feedback';
import ViewQuery from './ViewQuery';
import { toDisplayDateTimeMulLocale,toDisplayDateMonth } from '../../../util/Util';
import { ShimmerComp } from '../../../components/shimmer-effect/ShimmerEffect';
import { Stack } from '@mui/material';
import UsePagination from '../../../components/pagination/UsePagination';
import { PAGINATION_ITEM_PER_PAGE } from '../../../util/EnvHelper';


const QueryList = () => {

    const [showPIndex, setShowPIndex] = useState(-1);
    const [compId, setCompId] = useState([]);
    const [isLoading, setIsLoading] = React.useState(true);
    const [openSnackbar] = useSnackbar(CustomOptions)
    const [state, setState] = React.useState({
        queryList: [], noOfPages: 0, 
    });
    const itemsPerPage = PAGINATION_ITEM_PER_PAGE;
    const [page, setPage] = React.useState(1);
    const [filterList, setFilterList] = useState([state.queryList]);

    useEffect(() => {
        getQueryList()
    }, [])


    const getQueryList = async () => {
        getComplaintdetails()
            .then((data) => {
                if (data) {
                    setState(
                        {
                            ...state,
                            queryList: data,
                            noOfPages: Math.ceil(data.length / itemsPerPage)

                        })
                        setFilterList(data)
                }
                setIsLoading(false)
            }).catch(e => {
                setIsLoading(false)
                openSnackbar(e)
            })
    };

    useEffect(() => {
        if (showPIndex > -1) {
            const modalQuery = new Modal(document.querySelector('#queryViewModal'), {
                keyboard: false
            })
            modalQuery.show()
        }
    }, [showPIndex])

    const setQueryData = (index, cRid) => {
        setShowPIndex(index)
        setCompId(cRid)
    }

    const ViewModal = () => {
        return showPIndex > -1 ? (<ViewQuery queryDetails={state.queryList[showPIndex]}
            compId={compId} onModalClose={() => setQueryData(-1)} />) : (<></>)
    }

    const onPageChange = (event, value) => {
        setPage(value);
    };

    const searchFliter = (e) => {
        setPage(1);
        let searchText = e.target.value.toUpperCase();
        let searchResult = [];
     
        searchResult = (state.queryList).filter((item) => {
            const contactPerson = item.contactPerson ? item.contactPerson.toUpperCase() : ''.toUpperCase();
            // const remarks = item.remarks ? item.remarks.toUpperCase() : ''.toUpperCase();
            return (contactPerson.search(searchText) != -1)
          
        });
        setFilterList(searchResult);
    }

    return (
        <AdminLayout title="Queries">
            <ViewModal />
            <div className="container-fluid align-self-center">
                <div className="row justify-content-start">

                    <div className="col-md-6 d-flex flex-row justify-content-between">
                        <div className="search-input-btn d-flex flex-row justify-content-between border-0 rounded-bg px-3 col-md-8">

                            <div className="my-auto">
                                <SearchIcon />
                            </div>
                            <input type="text" className="form-control border-0 bg-transparent fs-large-regular "
                                onChange={(e) => { searchFliter(e) }} placeholder="Search by Customer Name " />
                        </div>


                    </div>

                    <div className=" col-md-7 p-3 table-container mt-3">
                        <table className="table table-striped table-borderless  ">
                            <thead>
                                <tr className="fs-large-light">
                                    <td scope="col" >
                                        <div className="d-flex flex-row my-auto">
                                            <div className="my-auto ">
                                                <p className="me-2 mb-0 ws-no-wrap">Complaint id</p>
                                            </div>
                                        </div>
                                    </td>
                                    <td scope="col">
                                        <div className="d-flex flex-row my-auto">
                                            <div className="my-auto">
                                                <p className="me-2 mb-0 ws-no-wrap">Customer name</p>
                                            </div>
                                        </div>
                                    </td>
                                    <td scope="col" >
                                        <div className="d-flex flex-row my-auto">
                                            <div className="my-auto">
                                                <p className="me-2 mb-0">Raised on</p>
                                            </div>
                                        </div>
                                    </td>

                                    <td scope="col " >
                                        <div className=" d-flex flex-row my-auto">
                                            <div className="my-auto">
                                                <p className="me-2 mb-0"></p>
                                            </div>

                                        </div>
                                    </td>
                                </tr>
                            </thead>
                            <tbody>
                            {isLoading ? (<ShimmerComp />) :
                                filterList !== null ?
                                    // state.queryList.from({ length: 1 }, (_, o) => {
                                        filterList
                                        .slice((page - 1) * itemsPerPage, page * itemsPerPage)
                                        .map((query, index) => {
                                            return (
                                                
                                                    <tr key={filterList.indexOf(query)} className="align-middle fs-large-med" >
                                                        <td scope="row">{query.chRid}</td>
                                                        <td>{query.contactPerson}</td>
                                                        <td>
                                                            {toDisplayDateMonth(query.dateTime)} <br />
                                                        </td>
                                                        <td>
                                                            <div className="d-flex flex-row justify-content-center">
                                                                <button className="action-circle me-2" onClick={() => setQueryData(state.queryList.indexOf(query), query.chRid)}>
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-eye text-green" viewBox="0 0 16 16">
                                                                        <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z" />
                                                                        <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z" />
                                                                    </svg>
                                                                </button>

                                                            </div>
                                                        </td>
                                                    </tr>
                                               
                                            );
                                        })
                                    // })
                                    :
                                    <tbody> <td className=" w-3"></td>
                                        <td scope="row"></td>
                                        <td scope="row"><div className="w-100 mt-3 text-center justify-content-center">
                                            <h1 className="fs-large-regular"><i>No records</i></h1>
                                        </div></td></tbody>
                            }
                            </tbody>
                        </table>

                        <div className=" mt-5 justify-content-center text-center">
                            <Stack spacing={2} alignItems="center">
                                <Pagination
                                      count={Math.ceil((filterList).length / itemsPerPage)}
                                      page={page}
                                      onChange={onPageChange}
                                      defaultPage={1}
                                    size="large"
                                    variant="outlined" color="success"
                                    showFirstButton
                                    showLastButton
                                />
                            </Stack>
                        </div>
                    </div>
                </div>
            </div>

            {/* Vendor view and edit modal */}

            {/* Vendor view and edit modal */}

        </AdminLayout>
    )

}
export default QueryList