import React from 'react'
import BottomNavbar from './pages/user/navbar/BottomNavbar'
import TopNavbar from './pages/user/navbar/TopNavbar'

const UserLayout = ({ children }) => {
    return (
        <>
            <TopNavbar></TopNavbar>

            {children}

            <BottomNavbar></BottomNavbar>
        </>
    )
}

export default UserLayout
