import React, { useEffect, useState } from 'react'
import './order.scss';
import UserLayout from '../../../UserLayout';
import { ShimmerOrders } from '../../../components/shimmer-effect/ShimmerEffect';
import { useSnackbar } from 'react-simple-snackbar';
import { CustomOptions } from '../login/Login';
import { getMyOrders, getTaxInvoice } from '../../../api/API';
import fanImg from '../../../assets/fan_img.svg';
import { BASE_URL } from '../../../util/EnvHelper';
import { displayOrderId, toDisplayDateTime, toPrefixedLocalePrice } from '../../../util/Util';
import { Link } from 'react-router-dom';
import { TRACK_URL } from '../../../util/EnvHelper'
import { Modal } from 'bootstrap'
import TrackModal from './TrackModal'
import Select from 'react-select';


const Order = () => {

    const [isLoading, setIsLoading] = React.useState(true);
    const [ordersList, setOrdersList] = useState([]);
    const [orderDetailList, setOrderDetailList] = useState([]);
    const [openSnackbar] = useSnackbar(CustomOptions)
    const [trackNo,setTrackNo] = useState(null)
    const [searchQuery,setSearchQuery] = useState(0);

    useEffect(() => {
        getMyOrdersFunction(0)
    }, [])

    const trackModal = async (lrNo) => {
        setTrackNo(lrNo)
        const openTrackModal = new Modal(document.querySelector('#trackModal'), {
            keyboard: false
        })
        openTrackModal.show()
    }

    const getMyOrdersFunction = async (value) => {
        getMyOrders(value)
            .then((data) => {
                setOrdersList(data);
                setIsLoading(false)
            }).catch(e => {
                setIsLoading(false)
                openSnackbar(e)
            })
    };

    const orderFilter = (value) => {
        getMyOrdersFunction(value)
    }

    const options = [
        { value: 0, label: 'All' },
        { value: 1, label: 'Pending' },
        { value: 3, label: 'Dispatched' }
      ]
      
    const downloadInvoice = (ohRid) => {
        getTaxInvoice(ohRid)
        .then(res => {
            const filename = res.headers['content-disposition'].split('"')[1]
            console.log(filename);
            const url = window.URL.createObjectURL(new Blob([res.data]));
            const link = document.createElement('a');

            link.href = url;
            link.setAttribute('download', filename);

            document.body.appendChild(link);
            link.click();
            })
    }
        
    return (
        <UserLayout>
            <TrackModal trackingNo={trackNo}/>
            {isLoading ? (<ShimmerOrders />) :

                <div className="container-fluid   align-self-center">
                    {ordersList !== null ?
                        <>
                            <div className="row justify-content-center">

                                <div className="container my-md-200">
                                    <div className="row justify-content-center ">
                                        <div className="col-md-8 ">
                                            <div className='col-md-12 d-flex justify-content-between mb-3'>
                                                <div className="mb-3 d-lg-block d-md-block d-none">
                                                    <div className="d-flex flex-row ">
                                                        <Link className="d-flex flex-row  a-nonlink" to="/landing">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="#5CA772" className="bi bi-chevron-left mt-1" viewBox="0 0 16 16">
                                                                <path fill-rule="evenodd" d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z" />
                                                            </svg>
                                                            <p className="fs-large-med btn-link text-green ms-2">Home</p>
                                                        </Link>
                                                        <p className="fs-large-light text-dark ms-2">/ Orders</p>
                                                    </div>
                                                </div>
                                                <Select className='col-md-4 col-12'
                                                    options={options}
                                                    onChange = {opt => orderFilter(opt.value)}
                                                    isSearchable={false}
                                                    defaultValue={options[0]}
                                                />
                                            </div>

                                            {ordersList.map((item, index) => (
                                                <div className="container px-0">
                                                    <div className="card mb-4">
                                                        <div className="card-header ">
                                                            <div className='row d-flex flex-column flex-md-row fs-large-regular ps-2'>
                                                                <div className='col d-flex flex-column mb-2 p-0 pe-5'>
                                                                    <p className='fs-normal-med mb-0'>Order Placed On</p>
                                                                    <div className='fs-normal-semibold'>{toDisplayDateTime(item.orderDateTime)}</div>
                                                                </div>
                                                                <div className='col d-flex flex-column mb-2 p-0'>
                                                                    <p className='fs-normal-med mb-0'>No of items</p>
                                                                    <div className='fs-normal-semibold'>{item.itemCount}</div>
                                                                </div>
                                                                <div className='col d-flex flex-column mb-2 p-0 pe-4'>
                                                                    <p className='fs-normal-med mb-0'>Total Amount</p>
                                                                    <div className='fs-normal-semibold'>{toPrefixedLocalePrice(item.netAmount)}</div>
                                                                </div>
                                                                <div className='col d-flex flex-column mb-2 p-0'>
                                                                    <p className='fs-normal-med mb-0'>Order ID</p>
                                                                    <div className='fs-normal-semibold'>{(item.orderId)}</div>
                                                                </div>
                                                                <div className='col d-flex flex-column mb-2 p-0'>
                                                                    <p className='fs-normal-med mb-0'>Status</p>
                                                                    <div className='fs-normal-semibold'>{item.statusText}</div>
                                                                </div>
                                                                <div className='col d-flex flex-column mb-2 p-0'>
                                                                    <p className='fs-normal-med mb-0'>Tracking ID</p>
                                                                    {item.orderStatus === 3 ? 
                                                                        <a href={TRACK_URL} target='_blank' rel="noopener noreferrer">
                                                                            <p className='btn-link fs-normal-semibold p-0' onClick={() => {navigator.clipboard.writeText(item.lrNo);openSnackbar("Tracking ID Copied to Clipboard")}}>{item.lrNo}</p>
                                                                        </a>
                                                                        :<div className='fs-normal-semibold'>-</div>
                                                                    }
                                                                    
                                                                </div>
                                                            </div>
                                                            <div className='row d-flex justify-content-end'>
                                                                {item.statusText === 'Dispatched' ? 
                                                                        <a onClick ={() => {downloadInvoice(item.ohRid)}}target="_blank" rel="noreferrer"  className="btn col-md-3 btn-box btn-outline-success col mb-2 p-0 pb-1 mx-3">
                                                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-download" viewBox="0 0 16 16">
                                                                                <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z" />
                                                                                <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z" />
                                                                            </svg>
                                                                            <span className='fs-normal-reg text-green ps-3'>Download Invoice</span>
                                                                        </a>
                                                                        : null
                                                                }
                                                            </div>
                                                        </div>
                                                        {item.orderDetailList !== null ?

                                                            (item.orderDetailList).map((item, index) => (
                                                                <div className="card-body mx-2 border-bottom">
                                                                    <div className="row align-items-center">
                                                                        {/* <div className="col-md-2 ps-3 col-small-3 px-0 ">
                                                                            <img src={BASE_URL + "/" + item.imageUrl} alt="" className="img-fluid w-100 my-4"/>
                                                                        </div> */}
                                                                        <div className="col-md-9 col-sm-8 d-md-flex d-lg-flex flex-row justify-content-between">
                                                                            <div className='col-md-3 col-sm-3 d-flex flex-row'> 
                                                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" className='mt-1'>
                                                                                    <path fill="#5CA772" d="M19 19H5V5H19M19 3H5A2 2 0 0 0 3 5V19A2 2 0 0 0 5 21H19A2 2 0 0 0 21 19V5A2 2 0 0 0 19 3M7 9H9.31L9.63 6H11.63L11.31 9H13.31L13.63 6H15.63L15.31 9H17V11H15.1L14.9 13H17V15H14.69L14.37 18H12.37L12.69 15H10.69L10.37 18H8.37L8.69 15H7V13H8.9L9.1 11H7V9M11.1 11L10.9 13H12.9L13.1 11Z" />
                                                                                </svg>
                                                                                <p className='fs-large-med'>{item.skuCode}</p>
                                                                            </div>
                                                                            <div className='col-md-7 col-sm-7 me-3'> 
                                                                                <p className="fs-large-regular"> {item.title} </p>
                                                                            </div>
                                                                            <div className='col-md-3 col-sm-3'> 
                                                                                <p className="fs-large-regular"> Qty : {item.qty} </p>
                                                                            </div>
                                                                            {/* <h1 className="fs-large-regular">Status: <strong>Order placed</strong></h1> */}
                                                                        </div>
                                                                        {/* <div className="col-md-8 col-small-8 d-flex flex-row justify-content-between">
                                                                            <div className='col-3'> 
                                                                                <p className='fs-large-med'>{item.skuCode}</p>
                                                                            </div>
                                                                            <div className='col-6'> 
                                                                                <p className="fs-large-regular"> {item.title} </p>
                                                                            </div>
                                                                            <div className='col-3'> 
                                                                                <p className="fs-large-regular text-center"> Qty x {item.qty} </p>
                                                                            </div>
                                                                            {/* <h1 className="fs-large-regular">Status: <strong>Order placed</strong></h1> 
                                                                        </div> */}
                                                                    </div>
                                                                </div>
                                                            ))
                                                            : null}
                                                    </div>

                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </> : <div className="col-md-12 text-center">
                            <h1 className="fs-large-regular"><i>You haven't ordered anything yet</i></h1>
                        </div>
                    }

                </div>
            }
        </UserLayout>
    )
}
export default Order