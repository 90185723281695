import React, { useEffect, useState } from 'react'
import './Dashboard.scss';
import AdminLayout from '../../../AdminLayout';
import Rechart from './Recharts';
import { getUser } from '../../../util/LocalStorageUtil';
import {getDashboard} from '../../../api/API'
import {toPrefixedDashboardPrice,toDisplayDateMonth, toLocalePrice} from '../../../util/Util'


const Dashboard = () => {
    const [user, setUser] = useState()
    const [stats,setStats] = useState([])
    const [amount,setAmount] = useState([])
    // const [dataKey,setDataKey] = useState()

    useEffect(() => {
        setUser(getUser())
        getData()
    }, [])

    const getData = () => {
        getDashboard()
        .then(data => {
            setStats(data)
            setAmount((data.daywiseSaleList.map(item => item.amount)).reduce((sum,a)=>sum+a,0))
            // setDataKey([
            //         ...data.monthwiseSaleList.map((item)=>{
            //             return {date:item.date,
            //                     amount:Number((toLocalePrice(item.amount)))}
            //         })
            //     ])
        })
    }

    return (
        <AdminLayout title="Dashboard">
            <>
                <div className="container-fluid align-self-center">
                    <div className="row px-5">
                        <h1 className="fs-large-regular my-3 fw-bold">Welcome back, {user?.fullName}</h1>
                        <div className="col-md-6 w-30 ">
                            <div className="card h-100 box-card py-3 px-4 d-flex flex-row justify-content-between">

                                <div className='col-md-4 d-flex align-items-center flex-column pt-4'>
                                    <p className="fs-small-heading-medium col-md-9 text-center mb-1">{stats.partnerCount}</p>
                                    <p className="fs-large-med mb-0">Number of Partners </p>
                                </div>
                                <div className='vertical-rule'></div>
                                <div className='col-md-7'>
                                <table className="table table-striped table-borderless mb-0 ">
                                    <tbody>
                                    {stats?.partnerCategoryList?.map((item) => (
                                        <tr >
                                            <td className='fs-large-semibold'>{item.totalCount}</td>
                                            <td className='fs-large-med'>-</td>
                                            <td className='fs-large-med'>{item.category}</td>
                                        </tr>
                                    ))
                                    }
                                    </tbody>
                                </table>
                                </div>

                            </div>
                        </div>
                        <div className="col-md-6 w-30">
                            <div className="card h-100 box-card p-4">
                                <p className="fs-small-heading-medium mb-5">{toPrefixedDashboardPrice(amount)}</p>
                                <div className="d-flex flex-row justify-content-between">
                                    <p className="fs-large-med  mb-0 mt-2">Total Sales</p>
                                    <p className="fs-large-med  mb-0 text-grey mt-2">{toDisplayDateMonth(stats.startMonth)} - {toDisplayDateMonth(stats.endMonth)}</p>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                    <div className="row px-5">
                        <p className="fs-large-regular mt-5 mb-2 fw-bold">Daily Sales Summary <span className='text-grey'>({toDisplayDateMonth(stats.startMonth)} - {toDisplayDateMonth(stats.endMonth)})</span></p>
                        <Rechart data = {stats.daywiseSaleList} />
                    </div>
                    <div className="row px-5">
                        <p className="fs-large-regular mt-5 mb-2 fw-bold">Monthly Sales Summary <span className='text-grey'>({toDisplayDateMonth(stats.startYear)} - {toDisplayDateMonth(stats.startMonth)})</span></p>
                        <Rechart data = {stats.monthwiseSaleList}/>
                    </div>
                </div>

            </>
        </AdminLayout >
    )
}
export default Dashboard