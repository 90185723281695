import { styled, createTheme, alpha } from '@mui/material/styles';
import Switch from '@mui/material/Switch';
import "../../scss/utils/colors.scss";
import PoppinsMedium from '../../fonts/Poppins/Poppins-Medium.ttf';
import { makeStyles } from '@material-ui/core';

import { pink } from '@mui/material/colors';

export const AntSwitch = styled(Switch)(({ theme }) => ({
    padding: 8,
    width: 52,
    height: 33,
    display: 'flex',
    '&:active': {
        '& .MuiSwitch-thumb': {
            width: 14,
            height: 14,
            margin: 0,

        },
        '& .MuiSwitch-switchBase.Mui-checked': {
            transform: 'translateX(9px)',
        },
    },
    '& .MuiSwitch-switchBase': {
        // padding: 0,
        '&.Mui-checked': {
            transform: 'translateX(12px)',
            color: '#fff',
            marginLeft: 8,
            '& + .MuiSwitch-track': {
                opacity: 1,
                backgroundColor: theme.palette.mode === 'dark' ? '#5ca772' : '#5ca772',
            },
        },
        '&.Mui-disabled': {
            '& + .MuiSwitch-track': {
                opacity: 1,
                backgroundColor: theme.palette.mode === 'dark' ? '#121212' : '#121212',
            },
        }
    },
    '& .MuiSwitch-thumb': {
        boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
        width: 14,
        height: 14,
        margin: 0,
        borderRadius: 6,
        transition: theme.transitions.create(['width'], {
            duration: 200,
        }),
    },
    '& .MuiSwitch-track': {
        borderRadius: 16 / 2,
        opacity: 1,
        backgroundColor:
            theme.palette.mode === 'dark' ? 'rgba(255,255,255,.35)' : 'rgba(0,0,0,.25)',
        boxSizing: 'border-box',
    },

}));

export const ToggleSwitch = styled(Switch)(({ theme }) => ({
    padding: 8,
    width: 52,
    height: 33,
    display: 'flex',
    '&:active': {
        '& .MuiSwitch-thumb': {
            width: 14,
            height: 14,
            margin: 0,

        },
        '& .MuiSwitch-switchBase.Mui-checked': {
            transform: 'translateX(9px)',
        },
    },
    '& .MuiSwitch-switchBase': {
        color: 'white',
        // padding: 0,
        '&.Mui-checked': {
            transform: 'translateX(12px)',
            color: '#fff',
            marginLeft: 8,
            '& + .MuiSwitch-track': {
                opacity: 1,
                backgroundColor: theme.palette.mode === 'dark' ? '#5ca772' : '#5ca772',
            },
        },

    },
    '& .MuiSwitch-thumb': {
        boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
        width: 14,
        height: 14,
        margin: 0,
        borderRadius: 6,
        transition: theme.transitions.create(['width'], {
            duration: 200,
        }),
    },
    '& .MuiSwitch-track': {
        borderRadius: 16 / 2,
        opacity: 1,
        backgroundColor:
            theme.palette.mode === 'dark' ? '#121212' : '#121212',
        boxSizing: 'border-box',
    },

}));

export const ActiveSwitch = styled(Switch)(({ theme }) => ({
    width: 50,
    height: 28,
    padding: 7,
    '& .MuiSwitch-switchBase': {
        margin: 1,
        padding: 0,
        transform: 'translateX(6px)',
        '&.Mui-checked': {
            color: '#5ca772',
            transform: 'translateX(22px)',
            marginTop: 1,

            '& + .MuiSwitch-track': {
                opacity: 1,
                backgroundColor: theme.palette.mode === 'dark' ? 'transparent' : 'transparent',
            },
        },
        '&.Mui-disabled': {
            '& + .MuiSwitch-track': {
                opacity: 1,
                backgroundColor: theme.palette.mode === 'dark' ? 'transparent' : 'transparent',
            },
        }
    },
    '& .MuiSwitch-thumb': {

        boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
        width: 20,
        height: 20,
        marginTop: 3,
        transition: theme.transitions.create(['width'], {
            duration: 200,
        }),
    },
    '& .MuiSwitch-track': {

        opacity: 1,
        backgroundColor:
            theme.palette.mode === 'dark' ? 'transparent' : 'transparent',
        boxSizing: 'border-box',
        borderRadius: 20 / 2,
    },
}));

const Successcolor = {
    color: '#5ca772',
}
// const useStyles = styled((theme) => ({
//     color: '#5ca772',
//     '&.Mui-checked': {
//         color: '#5ca772',
//     },
// }));

const useStyles = styled(() => ({
    label: {
        fontSize: 12,
        fontFamily: 'Poppins-Medium'
    }
}));




export const theme = createTheme({
    palette: {
        primary: {
            main: '#5ca772',
        },
    },
    typography: {
        "fontFamily": `"Poppins-Regular"`,
        "fontSize": 12,
    },
    components: {

        MuiCssBaseline: {
            styleOverrides: `
            @font-face {
            font-family: 'Poppins-Medium';
          
            src: url(${PoppinsMedium});
             }
    `,
        },
        MuiCheckbox: {

            success: {
                color: '#5ca772',
                '&$checked': {
                    color: '#5ca772',
                },
            },
        },

    },
});


export const paginationStyle = makeStyles((theme) => ({
    root: {

        "& .MuiPagination-ul": {

            justifyContent: "center",
            display: 'flex'

        }
    },
}));



export const MaterialUISwitch = styled(Switch)(({ theme }) => ({
    width: 50,
    height: 28,
    padding: 7,
    '& .MuiSwitch-switchBase': {
        margin: 1,
        padding: 0,
        transform: 'translateX(6px)',
        '&.Mui-checked': {
            color: '#fff',
            transform: 'translateX(22px)',
            '& .MuiSwitch-thumb': {
                backgroundColor: theme.palette.mode === 'dark' ? '#5CA772' : '#5CA772',
            },
            '& + .MuiSwitch-track': {
                opacity: 1,
                borderColor: '#5CA772',
                backgroundColor: theme.palette.mode === 'dark' ? 'transparent' : 'transparent',

            },
        },
    },
    '& .MuiSwitch-thumb': {
        backgroundColor: theme.palette.mode === 'dark' ? '#C6C6C6' : '#C6C6C6',
        width: 20,
        height: 20,
        marginTop: 3,

    },
    '& .MuiSwitch-track': {
        opacity: 1,
        borderColor: '#C6C6C6',
        backgroundColor: theme.palette.mode === 'dark' ? 'transparent' : 'transparent',
        borderRadius: 20 / 2,
    },
}));


export const GreenSwitch = styled(Switch)(({ theme }) => ({
    '& .MuiSwitch-switchBase.Mui-checked': {
        color: '#5CA772',
        '&:hover': {
            backgroundColor: alpha('#5CA772', theme.palette.action.hoverOpacity),
        },
    },
    '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
        backgroundColor: '#5CA772',
    },
    '& .MuiSwitch-track': {

        opacity: 1,
        backgroundColor:
            theme.palette.mode === 'dark' ? 'rgba(255,255,255,.35)' : 'rgba(0,0,0,.25)',

    },
}));