import React, { useState,useEffect } from 'react'
import OtpPassword from '../../../components/otp-password/Otp-Password'
import backGround from '../../../assets/banner1.png'
import { Link } from 'react-router-dom'
import { CustomOptions } from './Login'
import { Modal } from 'bootstrap'
import { useSnackbar } from 'react-simple-snackbar'
import LoadingButton from '../../../components/loading-button/LoadingButton'
import { useNavigate } from 'react-router'
import { checkPassword, sendOtp } from '../../../api/API'
import { USER_TYPE } from "../../../util/EnvHelper";
import { getUserName, setAuthToken } from '../../../util/LocalStorageUtil'
import './login.scss'
import PartnerSelectModal from './PartnerSelectModal'

const Password = () => {
    const [password, setPassword] = useState(null)
    const [isLoading, setIsLoading] = useState(false)
    const navigate = useNavigate();
    const [openSnackbar] = useSnackbar(CustomOptions)
    const [partnerList,setPartnerList] = useState([])
    const [showAIndex,setShowAIndex] = useState(-1)

    const sendPassword = () => {
        if (!password) {
            openSnackbar("Enter Valid Password")
            return
        }
        setIsLoading(true)
        checkPassword({ password: password, userType: USER_TYPE })
            .then((data) => {
                if(data.selectBranch === 0) {
                    setIsLoading(false)
                    navigate('/landing')
                }
                else{
                    setPartnerList(data.partnerList)
                    addVendorData(1)
                    console.log(data.partnerList)
                }
            })
            .catch(e => {
                setIsLoading(false)
                openSnackbar(e)
            })
    }

    useEffect(() => {
        if (showAIndex > -1) {
            const addVendor = new Modal(document.querySelector('#partnerModal'), {
                keyboard: false
            })
            addVendor.show()
        }
    }, [showAIndex])

    const addVendorData = (index) => {
        setShowAIndex(index)
    }
    
    const SelectBranchModal = () => {
        return showAIndex > -1 ? (<PartnerSelectModal branchList={partnerList} password={password} onModalClose={(partRid) => modalLogin(partRid)}
        />) : (<></>)
    }

    const modalLogin = (partRid) => {
        addVendorData(-1)
        if(partRid){
            checkPassword({ password: password, userType: USER_TYPE, partRid:partRid })
            .then(() => {
                navigate('/landing')
            })
            .catch(e => {
                setIsLoading(false)
                openSnackbar(e)
            })
        }    
        else{
            openSnackbar("Please Select Branch")
            setIsLoading(false)
        }
    }

    const forgotPassoword = () => {
        let phoneNum = getUserName()
        sendOtp({mobileOrEmail:phoneNum, userType: USER_TYPE })
        .then((data) => {
            // setIsLoading(false)
            if (data) {
                navigate('/forgot-password' , { state: data })
            }
            else {
                openSnackbar('Something went wrong!')
            }
        }).catch(e => {
            setIsLoading(false)
            openSnackbar(e)
        })
    }
    

    return (
        <>
            <SelectBranchModal/>
            <div className='loginBody'>
                <img className="banner" src={backGround} alt="banner" />
                <div className="container  mt-md-50">
                    <div className="row justify-content-center">
                        <div className="col-md-4 p-2rem my-auto login-container col-11">
                            <div className="flex-row d-flex mb-3">
                                <a className="a-nonlink my-auto" href="/"><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-arrow-left" viewBox="0 0 16 16">
                                    <path fillRule="evenodd" d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z" />
                                </svg></a>
                                <div className="ms-4 my-auto"><span className="text-center fs-l-large-regular">Enter password</span></div>
                            </div>
                            <form autoComplete='off' onSubmit = {(e) => e.preventDefault()}>
                                <input autoComplete='off' type="password"  onChange={(e) => setPassword(e.target.value)}
                                    className="form-control login-input p-07rem my-4 w-100 fs-x-large-regular px-4" placeholder="Enter password" autoFocus/>
                                <div>
                                    <LoadingButton
                                        buttonText="Login"
                                        loadingText="LOGGING IN..."
                                        width="w-100"
                                        onClick={sendPassword}
                                        onSubmit={sendPassword}
                                        isLoading={isLoading}
                                    />

                                </div>
                            </form>
                            {/* <Link to='/forgot-password' className='a-nonlink'> */}
                                    <button onClick={() => forgotPassoword()} className='btn btn-link fs-large-med px-0'>Forgot Password?</button>
                            {/* </Link> */}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default Password