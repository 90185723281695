import React, { useEffect, useState } from "react";

const ProductAttribute = ({ attributeDetailList, updateAttributeList }) => {
    const [attributeList, setAttributeList] = useState([attributeDetailList])

    useEffect(() => {
        setAttributeList(attributeDetailList);
    }, [])

    const inutChange = (index, key, e) => {
        setAttributeList(s => {
            const newAttributeList = s.slice();
            newAttributeList[index][key] = e.target.value;
            updateAttributeList(newAttributeList)
            return newAttributeList;
        });
    };

    const addAttribute = () => {
        setAttributeList(s => [...s, { attributeName: '', value: '', valueId: '', displayOrder: '' }
        ]);
    };


    const deleteAttribute = (attLength, index) => {
        if (attLength > 1) {
            setAttributeList(s => {
                const newAttributeList = s.slice();
                newAttributeList.splice(index, 1);
                updateAttributeList(newAttributeList)
                return newAttributeList;
            });
        }
    }

    return (
        attributeList ?
            <div className="container inventory-accordion">
                <div className="row ">

                    <div id={"attr-headingOne" + 1}
                        className={"accordion-button pointer   bg-transparent shadow-none collapsed fs-large-semibold w-95"}
                        data-bs-toggle="collapse" data-bs-target={"#attr-collapseOne" + 1}
                        aria-expanded="false" aria-controls={"attr-collapseOne" + 1}>
                        Product Description
                    </div>

                </div>
                <div id={"attr-collapseOne" + 1} className=" row accordion-collapse  collapse"
                    aria-labelledby={"attr-headingOne" + 1} data-bs-parent="#accordionFlushExample">
                         <div className="row"><div className="col-md-4 ">
                                    <label className="fs-normal-reg mb-2 ms-3">Label</label>
                                    </div>
                                    <div className="col-md-6 ">
                                    <label className="fs-normal-reg mb-2 ms-3">Description</label>
                                    </div></div>
                    {attributeList !== null ?
                        attributeList.map((item, index) => (

                            <>
                              
                                <div className="col-md-4 ">
                                  
                                    <input
                                        type="text"   data-rule="required"
                                        className="form-control login-input p-07rem mb-3 w-100 fs-large-med px-4  validate-me mb-4"
                                        onChange={(e) => inutChange(index, 'attributeName', e)}
                                        value={item.attributeName}
                                    />
                                     <p className="invalid-feedback fs-small-reg text-center"></p>
                                </div>
                                <div className="col-md-6 ">
                                 
                                    <input
                                        type="text"   data-rule="required"
                                        className="form-control login-input p-07rem mb-3 w-100 fs-large-med px-4  validate-me mb-4"
                                        onChange={(e) => inutChange(index, 'value', e)}
                                        value={item.value}
                                    />
                                     <p className="invalid-feedback fs-small-reg text-center"></p>
                                </div>
                                <div className="col-md-2  mt-1">
                                    <button type="button" className="btn rounded-close-btn"
                                        onClick={() => deleteAttribute((attributeList).length, index)}>
                                        <span aria-hidden="true">&times;</span></button>

                                </div>
                              
                            </>

                        )) : null
                    }

                    <div className="col-md-4 ">
                        <button type="button" className=" btn rounded-plus-btn  fs-large-med"
                            onClick={addAttribute}>+ Attribute</button>
                    </div>
                </div>
            </div>
            : null
    )
}
export default ProductAttribute