import React, { useEffect, useState } from 'react'
import './home.scss';
import { useLocation } from 'react-router'
import Index from '../../../components/index';
import { useNavigate } from 'react-router-dom';
import UserLayout from '../../../UserLayout';
import { ShimmerHome } from '../../../components/shimmer-effect/ShimmerEffect';
import { getProductList } from '../../../api/API';
import { CustomOptions } from '../login/Login';
import { useSnackbar } from 'react-simple-snackbar'
import { getCartCount } from '../../../api/API';
import FilterModal from '../order/FilterModal'


const Home = () => {

    const [openSuccessSnackbar, setOpenSnackbar] = React.useState(false);
    const [value, setValue] = React.useState(true);
    const [isLoading, setIsLoading] = React.useState(true);
    const [productList, setProductList] = useState([]);
    const [openSnackbar] = useSnackbar(CustomOptions)
    const [count, setCount] = useState(null);
    const { state } = useLocation();
    const navigate = useNavigate();


    useEffect(() => {
        getCartCountData();
        if(state){
            // console.log(state)
            setProductList(state)
        }
        else{
            getProdList()
        }
    }, [state])

    const getCartCountData = () => {
        getCartCount()
            .then(response => {
                // console.log(response.data.cartCount)
                setCount(response.data.cartCount)
            }).catch(e => {
            })
    }

    const getProdList = async () => {
        getProductList()
            .then(data => {
                setIsLoading(false)
                setProductList(data);

            }).catch(e => {
                setIsLoading(false)
                openSnackbar(e)
            })
    };

    return (
        <UserLayout>
            <FilterModal />
            {isLoading ? (<ShimmerHome />) :
                <>
                    <div className="container   align-self-center">
                        <div className="row justify-content-center">
                            <Index productList={productList} />
                        </div>
                    </div>

                </>}
        </UserLayout>
    )
}
export default Home