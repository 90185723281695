import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Checkbox, Radio, FormControlLabel, FormLabel, FormGroup } from '@mui/material';
import { CustomOptions } from "../../../user/login/Login";
import { useSnackbar } from 'react-simple-snackbar';
import { BASE_URL } from "../../../../util/EnvHelper";

const SkuImage = ({ imageListData, skuListData, indexVal }) => {

    const [skuList, setSkuList] = useState(skuListData)
    const [imageListInfo, setImageListInfo] = useState(imageListData)
    const [index, setIndex] = useState(indexVal)


    const removeImage = (parentIndex, index, key, img) => {
        if (img > 1) {
            setSkuList((s, i) => {
                const newVariantList = s.slice();
                newVariantList[parentIndex][key].splice(index, 1);
                return newVariantList;
            });
        }

    }

    const setStatus = (parentIndex, index, imgurl) => {
        setSkuList(s => {
            const newVariant = s.slice();
            newVariant[parentIndex].imageUrl = imgurl
            return newVariant;
            // for (const [ffIndex, value] of newVariant[parentIndex].imageList.entries()) {
            // }
            // const newVariantList = s.slice();
            // newVariantList[parentIndex][key].splice(index, 1);
        });
    };
console.log(imageListData)
    return (
        <>
            {imageListInfo !== null ?
                imageListInfo.imageList.map((img, i) => {
                    let imglength = (imageListInfo.imageList).length
                    return (
                        <div className="col-md-2 text-center wm-12" key={i}>

                            <div className="img-text">
                                <div className="top-left-text" onClick={() => { removeImage(index, i, 'imageList', imglength) }}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-x-circle-fill text-green" viewBox="0 0 16 16">
                                        <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z" />
                                    </svg></div>
                                <img src={BASE_URL + '/' + img} className="img-fluid box-img border-primary fit-contain validate-me" 
                                data-rule="required" />
                             <p className="invalid-feedback fs-small-reg text-center"></p>
                                
                            </div>
                            <FormControlLabel
                                onClick={(e) => setStatus(index, i, img)}
                                checked={imageListInfo.imageUrl == img}
                                control={<Radio />}
                                label={imageListInfo.imageUrl == img ? "Primary" : ""}
                                labelPlacement="bottom"
                            />
                        </div>)
                })
                : null}
        </>
    )
}
export default SkuImage