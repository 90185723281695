import React, { useEffect, useState } from 'react'
import OtpPassword from '../../../components/otp-password/Otp-Password'
import backGround from '../../../assets/banner1.png'
import { Link } from 'react-router-dom'
import { setAuthToken, setUserStatus } from '../../../util/LocalStorageUtil';
import { useSnackbar } from 'react-simple-snackbar'
import LoadingButton from '../../../components/loading-button/LoadingButton'
import { useNavigate,useLocation } from 'react-router'
import { CustomOptions } from './Login';
import { checkPassword, login, resendOtp, resetNewPassword,partnerRegister } from '../../../api/API'
import { USER_TYPE } from "../../../util/EnvHelper";
import {getUserName} from "../../../util/LocalStorageUtil"

const ForgotPassword = () => {
    const [otp, setOtp] = useState(null)
    const [newPassword, setNewPassword] = useState(null)
    const [reTypePassword, setReTypePassword] = useState(null)
    const [isLoading, setIsLoading] = useState(false)
    const [openSnackbar] = useSnackbar(CustomOptions)
    const navigate = useNavigate();
    const { state } = useLocation();

    const resetPassword = () => {
        if (!otp || !(otp.match('[0-9]'))) {
            openSnackbar("Enter valid OTP!")
            return
        }
        if (!newPassword) {
            openSnackbar("Newpassword cannot be empty!")
            return
        }
        if (!reTypePassword) {
            openSnackbar("Retype field cannot be empty!")
            return
        }
        if (newPassword !== reTypePassword) {
            openSnackbar("Password not matching!")
            return
        }
        

        if(state.userStatus === 0) {
            partnerRegister({password:newPassword, otp:otp, userType: USER_TYPE})
                .then((description) => {
                    openSnackbar(description)
                    makeLogin(newPassword)
                })
        }
        else{
            resetNewPassword({ password: newPassword, otp: otp, userType: USER_TYPE })
            .then((status) => {
                if (status === 0) {
                    makeLogin(newPassword)
                }
                else
                    openSnackbar('Something went wrong!')

            }).catch(e => {
                setIsLoading(false)
                openSnackbar(e)
            })
            }
    }   
        
    // const newLogin = () => {
    //     navigate('/login')
    // }

    const resendOTP = () => {
        resendOtp({ userType: USER_TYPE })
            .then((status) => {
                setIsLoading(false)
                if (status) {
                    openSnackbar(status)
                }
                else {
                    openSnackbar('Something went wrong!')
                }
            }).catch(e => {
                setIsLoading(false)
                openSnackbar(e)

            })
    }


    const makeLogin = (passwordVal) => {
        // console.log("coming login")
        checkPassword({ password: passwordVal, userType: USER_TYPE })
            .then(() => {
                setIsLoading(false)
                navigate('/landing')

            }).catch(e => {
                setIsLoading(false)
                openSnackbar(e)
            })
    }

    return (
        <>
            <img className="banner" src={backGround} alt="banner" />
            <div className="container  mt-md-50">
                <div className="row justify-content-center">
                    <div className="col-md-4 p-2rem my-auto forgot-password-container col-11">
                        <div className="flex-row d-flex mb-3">
                            <a className="a-nonlink my-auto" href="/login">
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-arrow-left" viewBox="0 0 16 16">                               
                                    <path fill-rule="evenodd" d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z" />
                                </svg>
                            </a>
                            <div className="ms-4 my-auto"><span className="text-center fs-l-large-regular">Create password</span></div>
                        </div>
                        <div>
                            <p className="fs-large-reg">An OTP has been sent to your entered Mobile number/ Email address. Enter the OTP below.</p>
                            <input type="password" onChange={(e) => setOtp(e.target.value)}
                                className="form-control login-input p-07rem my-4 w-100 fs-x-large-regular px-4" placeholder="Enter OTP" />
                        </div>
                        <hr></hr>
                        <div>
                            <p className='fs-large-reg'>Enter your new password below</p>
                            <input type="password" className="form-control login-input p-07rem my-1 w-100 fs-x-large-regular px-4" placeholder="New password"
                                onChange={(e) => setNewPassword(e.target.value)} />
                            <input type="password" className="form-control login-input p-07rem my-1 w-100 fs-x-large-regular px-4" placeholder="Re-type new password"
                                onChange={(e) => setReTypePassword(e.target.value)} /></div>
                        <div className="mt-3">
                            <LoadingButton
                                buttonText="Save changes"
                                loadingText="SENDING..."
                                width="w-100"
                                onClick={resetPassword}
                                isLoading={isLoading}
                            />
                        </div>
                        <div className="text-end my-1">
                            <button className="btn b-none text-primary fs-large-semibold"
                                onClick={resendOTP}>Resend OTP?</button>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}
export default ForgotPassword