import { getUserName, setAuthToken, setUser, setUserName, setUserPartner, getUser, setAnnoucementFlag, getAnnouncementStatus } from "../util/LocalStorageUtil"
import { getFile, getFileExport, httpGet, httpPost, httpFileUpload, httpGetTrack, getReport } from "./AxiosHelper"
import { BASE_URL } from "../util/EnvHelper"
const STATUS_SUCCESS = 0

export const login = (body) => {
    return new Promise((resolve, reject) => {
        httpPost({ path: '/init-login', body: body })
            .then(({ data, description }) => {

                if (data) {
                    setUserName(body.username) //storing contact number in local-storage
                    resolve(data.status)
                }
                else reject(description)
            })
            .catch(e => reject(e))
    })
}

export const checkPassword = (body) => {
    body.username = getUserName()
    return new Promise((resolve, reject) => {

        httpPost({ path: '/partner-login', body: body })
            .then(({ data, description, status }) => {
                if (status === STATUS_SUCCESS) {
                    if (data.selectBranch === 1) {
                        resolve(data)
                    }
                    else {
                        setAuthToken(data.jwt) //storing JWT Token in local-storage
                        setUser(data.user)
                        setUserPartner(data.partner)
                        resolve(data)
                    }
                }
                else {
                    reject(description)
                }
            })
            .catch(e => {
                reject(e)
            })
    })

}

export const sendOtp = (body) => {
    // body.mobile = getUserName()
    return new Promise((resolve, reject) => {
        httpPost({ path: '/send-otp', body: body })
            .then(({ data, description }) => {
                if (data) resolve(data)
                else reject(description)
            })
            .catch(e => reject(e))
    })
}
export const resetNewPassword = (body) => {

    body.username = getUserName()

    return new Promise((resolve, reject) => {

        httpPost({ path: '/reset-password', body: body })
            .then(({ status, description }) => {

                if (status == 0) {
                    // setAuthToken(data.jwt)             //storing JWT Token in local-storage
                    // setUserFullName(data.user['fullName'])   //storing userfull name in local-storage
                    resolve(status)
                }
                else
                    reject(description)
            })
            .catch(e => reject(e))
    })
}

export const partnerRegister = (body) => {
    body.username = getUserName()
    return new Promise((resolve, reject) => {
        httpPost({ path: '/partner-register', body: body })
            .then(({ status, description }) => {
                if (status === 0) {
                    // setAuthToken(data.jwt)             //storing JWT Token in local-storage
                    // setUserFullName(data.user['fullName'])   //storing userfull name in local-storage
                    resolve(description)
                }
                else
                    reject(description)
            })
            .catch(e => reject(e))
    })
}

export const resendOtp = (body) => {
    body.mobileOrEmail = getUserName()
    return new Promise((resolve, reject) => {
        httpPost({ path: '/resend-otp', body: body })
            .then(({ description }) => {
                resolve(description)

            })
            .catch(e => reject(e))
    })
}

export const displayRazorpay = (orderId, amountInPaise) => {
    const user = getUser()
    return new Promise((resolve, reject) => {
        const options = {
            key: process.env.RAZORPAY_KEY_ID,
            currency: 'INR',
            amount: amountInPaise / 100,
            name: "TVS Green",
            description: "Wallet Transaction",
            image: BASE_URL + "/images/tvs_green_logo.png",
            order_id: orderId,
            handler: function (response) {
                // console.log(response)
                // console.log("PAYMENT ID ::" + response.razorpay_payment_id);
                // console.log("ORDER ID :: " + response.razorpay_order_id);
                resolve({
                    paymentId: response.razorpay_payment_id,
                    orderId: response.razorpay_order_id,
                    paymentSg: response.razorpay_signature
                })
            },
            prefill: {
                name: user?.fullName,
                email: user?.email,
                contact: user?.mobile,
            },
            theme: {
                color: "#00bb58",
            },
        };

        const paymentObject = new window.Razorpay(options);
        paymentObject.open();
    })
}

export async function generateRazorPaymentId() {
    let order_id = null
    let amount_inpaise = 0
    let response = await httpGet({ path: '/partner/get-pg-order-id', queryParams: null })
    // console.log(response)
    if (response && response.data) {
        if (response.status === -1) {
            return (response)
        }
        else {
            order_id = response.data.pgOrderId
            amount_inpaise = response.data.amountPaise
        }
    }
    else {
        // console.log(response.description)
    }
    // console.log(order_id)

    if (!order_id) {
        return null
        // reject("Unable to generate Payment ID") 
    }
    return { orderId: order_id, amountInPaise: amount_inpaise }
}

export const getQueryList = () => {

    return new Promise((resolve, reject) => {
        httpGet({ path: '/partner/list-Complaints', queryParams: null })
            .then(({ data, status, description }) => {
                if (data) {
                    // console.log(data)
                    resolve(data ? data : [])
                }
                else reject(description)
            }).catch(e => reject(e))
    })
}

export const getQueryDetails = (chRid) => {
    return new Promise((resolve, reject) => {
        httpGet({ path: '/partner/complaint-detail/' + JSON.stringify(chRid), queryParams: null })
            .then(({ data, status, description }) => {
                if (data) {

                    resolve(data ? data : [])
                }
                else reject(description)
            }).catch(e => reject(e))
    })
}

export const getProductList = (body) => {

    return new Promise((resolve, reject) => {
        httpPost({ path: '/partner/product-list', body: body })
            .then(({ data, status, description }) => {
                // console.log(skuCode)
                if (data) resolve(data ? data : [])
                else reject(description)
            }).catch(e => reject(e))
    })
}

export const saveComplaint = (body) => {

    return new Promise((resolve, reject) => {
        httpPost({ path: '/partner/save-complaint', body: body })
            .then(({ data, description }) => {
                // console.log(skuCode)
                if (description) {
                    resolve(description ? description : [])
                }
                else reject(description)
            }).catch(e => reject(e))
    })
}

export const getFilterList = () => {
    return new Promise((resolve, reject) => {
        httpGet({ path: '/partner/get-search-filters/', queryParams: null })
            .then(({ data, status, description }) => {
                if (data) {
                    resolve(data ? data : [])
                }
                else reject(description)
            }).catch(e => reject(e))
    })
}

export const getTrackDetails = (lrNo) => {
    return new Promise((resolve, reject) => {
        httpGetTrack({ path: lrNo, queryParams: null })
            .then(({ data, status, description }) => {
                if (data) {
                    resolve(data ? data : [])
                }
                else reject(description)
            }).catch(e => reject(e))
    })
}

export const getProductDetails = (skuRid) => {
    return new Promise((resolve, reject) => {
        httpGet({ path: '/partner/product-detail/' + skuRid, queryParams: null })
            .then(({ data, status, description }) => {
                if (data) {
                    resolve(data ? data : [])

                }
                else reject(description)
            }).catch(e => reject(e))
    })
}

export const addToCart = (body) => {

    return new Promise((resolve, reject) => {
        httpPost({ path: '/partner/add-to-cart', body: body })
            .then((response) => {
                if (response.data) {
                    resolve(response.data.cartRid)
                }
            }).catch(e => {
                reject(e)
            })
    })
}
export const confirmOrder = (body) => {
    return new Promise((resolve, reject) => {
        httpPost({ path: '/partner/confirm-order', body: body })
            .then(({ data, status }) => {

                resolve(data)
            }).catch(e => reject(e))
    })
}
export const getCartCount = () => {
    return new Promise((resolve, reject) => {
        httpGet({ path: '/partner/cart-count', queryParams: null })
            .then((response) => {
                // console.log(response)
                if (response) {
                    // console.log(response.data.cartCount)
                    resolve(response ? response : [])
                }
                else reject()
            }).catch(e => reject(e))
    })
}

export const getMyOrders = (value) => {
    // if(value === undefined){
    //     value = 0
    // }
    return new Promise((resolve, reject) => {
        httpGet({ path: '/partner/my-orders/' + value, queryParams: null })
            .then(({ data, status, description }) => {

                if (data) resolve(data ? data : [])
                else reject(description)
            }).catch(e => reject(e))
    })
}

export const getPartnerAnnouncement = () => {
    const anFlag = getAnnouncementStatus()
    return new Promise((resolve, reject) => {
        httpGet({ path: '/partner/announcement', queryParams: null })
            .then(({ data, status, description }) => {
                if (data) {
                    if (anFlag?.flag && anFlag?.anId === data.anRid) {
                        resolve(data ? data : null)
                    }
                    else {
                        resolve(data ? data : null)
                        setAnnoucementFlag({ anId: data.anRid })
                    }
                }
                else reject(description)
            }).catch(e => reject(e))
    })
}

export const getCartlist = () => {
    return new Promise((resolve, reject) => {
        httpGet({ path: '/partner/list-cart', queryParams: null })
            .then(({ data, status, description }) => {

                if (data) {
                    resolve(data ? data : [])

                }
                else reject(description)
            }).catch(e => reject(e))
    })
}


export const removeCartItem = (body) => {

    return new Promise((resolve, reject) => {
        httpPost({ path: '/partner/remove-from-cart', body: body })
            .then((response) => {
                // console.log(JSON.stringify(response))
                resolve('Item deleted!')
            }).catch(e => {
                reject(e)
            })
    })
}

export const getSalesContact = () => {
    return new Promise((resolve, reject) => {
        httpGet({ path: '/partner/get-sales-contact', queryParams: null })
            .then(({ data, description }) => {
                if (data) {
                    resolve(data ? data : [])

                }
                else reject(description)
            }).catch(e => reject(e))
    })
}

export const getProformaInvoice = () => {
    return new Promise((resolve, reject) => {
        getFile({ path: '/generate-proforma-invoice', queryParams: null })
            .then((res) => {
                resolve(res)
            }).catch(e => reject(e))
    })
}

export const getTaxInvoice = (ohRid) => {
    // console.log(ohRid)
    return new Promise((resolve, reject) => {
        getFile({ path: '/generate-tax-invoice?ohRid=' + ohRid, queryParams: null })
            .then((res) => {
                resolve(res)
            }).catch(e => reject(e))
    })
}
// export const getProformaInvoice = {
//     uri: BASE_URL + '/generate-proforma-invoice',
//     method: "GET",
//     encoding: "null", // it also works with encoding: null
//     headers: {
//       "Content-type": "application/pdf"
//     }
//   };
//   request(getProformaInvoice)
//   .then(function(body, data) {
//     let writeStream = fs.createWriteStream(uuid+'_obj.pdf');
//     console.log(body)
//     writeStream.write(body, 'binary');
//     writeStream.on('finish', () => {
//       console.log('wrote all data to file');
//     });
//     writeStream.end();
//   })

export const submitFeedback = (body) => {

    return new Promise((resolve, reject) => {
        httpPost({ path: '/partner/submit-feedback', body: body })
            .then(({ data, description }) => {
                if (description) {
                    resolve(description ? description : [])
                }
                else reject(description)
            }).catch(e => reject(e))
    })
}


//TODO : seperate admin and customer api's - Wilson


export const salesReportExport = (body) => {
    console.log(body)
    return new Promise((resolve, reject) => {
        getReport({ path: '/sales-report-export', body: body })
            .then((res) => {
                resolve(res)
            }).catch(e => reject(e))
    })
}

export const vendorListExport = (type) => {
    return new Promise((resolve, reject) => {
        getFileExport({ path: '/dashboard/export-partner-list/' + type, queryParams: null })
            .then((res) => {
                resolve(res)
            }).catch(e => reject(e))
    })
}

export const getAdminProductList = (partRid) => {
    return new Promise((resolve, reject) => {
        httpGet({ path: '/dashboard/product-list-admin/' + partRid, queryParams: null })
            .then((res) => {
                resolve(res)
            }).catch(e => reject(e))
    })
}

export const confirmAdminOrder = (body) => {
    console.log(body)
    return new Promise((resolve, reject) => {
        httpPost({ path: '/admin/confirm-order', body: body, queryParams: null })
            .then((res) => {
                resolve(res)
            }).catch(e => reject(e))
    })
}

export const getOrderList = () => {
    return new Promise((resolve, reject) => {
        httpGet({ path: '/admin/orders', queryParams: null })
            .then(({ data, status, description }) => {

                if (data) resolve(data ? data : [])
                else reject(description)
            }).catch(e => reject(e))
    })
}

export const getPartnerList = () => {
    return new Promise((resolve, reject) => {
        httpGet({ path: '/dashboard/list-partner', queryParams: null })
            .then(({ data, status, description }) => {
                if (status === STATUS_SUCCESS && data) resolve(data ? data : [])
                else reject(description)
            }).catch(e => reject(e))
    })
}

export const getSearchResults = (searchQuery) => {
    if (!searchQuery) return
    return new Promise((resolve, reject) => {
        httpGet({ path: `/partner/search-product/${searchQuery}`, queryParams: null })
            .then(({ data, status, description }) => {
                if (status === STATUS_SUCCESS && data) resolve(data ? data : [])
                else reject(description)
            }).catch(e => reject(e))
    })
}

export const getAccountStatement = () => {
    return new Promise((resolve, reject) => {
        httpGet({ path: `/partner/account-statement`, queryParams: null })
            .then(({ data, status, description }) => {
                if (status === STATUS_SUCCESS) resolve(data)
                else reject(description)
            }).catch(e => reject(e))
    })
}

export const adminLogin = (body) => {
    return new Promise((reslove, reject) => {
        httpPost({ path: '/admin-login', body: body })
            .then(({ data, description, status }) => {

                if (status === STATUS_SUCCESS && data) {
                    setAuthToken(data.jwt) //storing JWT Token in local-storage
                    setUser(data.user)
                    reslove('success')
                }
                else {
                    reject(description)
                }

            })
            .catch(e => {
                reject(e)
            })
    })
}


export const getProductListdetails = () => {
    return new Promise((reslove, reject) => {
        httpGet({ path: '/dashboard/product-list', queryParams: null })
            .then(({ data, status, description }) => {
                if (data) reslove(data ? data : [])
                else reject(description)
            }).catch(e => reject(e))
    })
}

export const getSalesReportDetails = (body) => {
    // console.log(body)
    return new Promise((reslove, reject) => {
        httpPost({ path: '/sales-report', body: body })
            .then(({ data, status, description }) => {
                if (data) reslove(data ? data : [])
                else reject(description)
            }).catch(e => reject(e))
    })
}

export const getSingleProductDetails = (prodId) => {

    return new Promise((reslove, reject) => {
        httpGet({ path: '/dashboard/product-detail/' + prodId, queryParams: null })
            .then(({ data, status, description }) => {
                if (data) reslove(data ? data : [])
                else reject(description)
            }).catch(e => reject(e))
    })
}


export const uploadImage = (file, fName) => {
    return new Promise((reslove, reject) => {
        const fileData = new FormData();
        fileData.append('file', file, fName);

        httpFileUpload({ path: '/upload-image', file: fileData, })
            .then(({ data, description, status }) => {

                if (status === STATUS_SUCCESS && data) {
                    reslove(data.path)
                }
                else {
                    reject(description)
                }

            })
            .catch(e => {
                reject(e)
            })
    })
}


export const saveInventory = (body) => {
    // console.warn(JSON.stringify(body))
    return new Promise((reslove, reject) => {
        httpPost({ path: '/dashboard/save-product-detail', body: body })
            .then(({ description, status }) => {

                if (status === STATUS_SUCCESS) {
                    reslove(description)
                }
                else {
                    reject(description)
                }

            })
            .catch(e => {
                reject(e)
            })
    })
}


export const getFeedBackDetails = () => {
    return new Promise((reslove, reject) => {
        httpGet({ path: '/dashboard/list-feedback', queryParams: null })
            .then(({ data, status, description }) => {
                if (data) reslove(data ? data : [])
                else reject(description)
            }).catch(e => reject(e))
    })
}

export const getComplaintdetails = () => {
    return new Promise((reslove, reject) => {
        httpGet({ path: '/dashboard/list-complaints', queryParams: null })
            .then(({ data, status, description }) => {
                if (data) reslove(data ? data : [])
                else reject(description)
            }).catch(e => reject(e))
    })
}


export const saveAnnouncement = (body) => {
    return new Promise((reslove, reject) => {
        httpPost({ path: '/dashboard/save-announcement', body: body })
            .then(({ description, status }) => {

                if (status === STATUS_SUCCESS) {
                    reslove(description)
                }
                else {
                    reject(description)
                }

            })
            .catch(e => {
                reject(e)
            })
    })
}


export const getAnnouncement = () => {
    return new Promise((resolve, reject) => {
        httpGet({ path: '/dashboard/list-announcements', queryParams: null })
            .then(({ data, status, description }) => {
                if (data) resolve(data ? data : [])
                else reject(description)
            }).catch(e => reject(e))
    })
}

export const updateAnnouncement = (aId, state) => {
    return new Promise((reslove, reject) => {
        httpPost({ path: '/dashboard/update-announcement-status/' + aId + '/' + state, body: null })
            .then(({ description, status }) => {

                if (status === STATUS_SUCCESS) {
                    reslove(description)
                }
                else {
                    reject(description)
                }

            })
            .catch(e => {
                reject(e)
            })
    })
}


export const getComplaintListDetails = (compId) => {
    return new Promise((reslove, reject) => {
        httpGet({ path: '/dashboard/complaint-detail/' + compId, queryParams: null })
            .then(({ data, status, description }) => {
                if (data) reslove(data ? data : [])
                else reject(description)
            }).catch(e => reject(e))
    })
}

export const getCategoryList = async () => {
    return new Promise((reslove, reject) => {
        httpGet({ path: '/dashboard/partner-categories', queryParams: null, })
            .then(({ data, status, description }) => {
                if (data) reslove(data ? data : [])
                else reject(description)
            }).catch(e => reject(e))
    })
}

export const getStateList = async () => {
    return new Promise((reslove, reject) => {
        httpGet({ path: '/dashboard/state-list', queryParams: null, })
            .then(({ data, status, description }) => {
                if (data) reslove(data ? data : [])
                else reject(description)
            }).catch(e => reject(e))
    })
}


export const savePartnerData = (body) => {

    return new Promise((reslove, reject) => {
        httpPost({ path: '/dashboard/save-partner', body: body })
            .then(({ description, status }) => {

                if (status === STATUS_SUCCESS) {
                    reslove(description)
                }
                else {
                    reject(description)
                }

            })
            .catch(e => {
                reject(e)
            })
    })
}

export const saveHierarchyData = (body) => {
    return new Promise((reslove, reject) => {
        httpPost({ path: '/dashboard/save-hierarchy', body: body })
            .then(({ description, status }) => {

                if (status === STATUS_SUCCESS) {
                    reslove(description)
                }
                else {
                    reject(description)
                }

            })
            .catch(e => {
                reject(e)
            })
    })
}

export const getSalesHierarchy = (sH) => {
    return new Promise((resolve, reject) => {
        httpGet({ path: '/dashboard/list-hierarchy/' + sH, queryParams: null })
            .then(({ data, status, description }) => {
                if (status === STATUS_SUCCESS && data) resolve(data ? data : [])
                else reject(description)
            }).catch(e => reject(e))
    })
}

export const deleteSalesHierarchy = (sH) => {
    return new Promise((resolve, reject) => {
        httpPost({ path: '/dashboard/delete-hierarchy/' + sH, queryParams: null })
            .then(({ data, status, description }) => {
                if (status === STATUS_SUCCESS && data) resolve(data ? data : description)
                else reject(description)
            }).catch(e => reject(e))
    })
}

export const getDashboard = () => {
    return new Promise((resolve, reject) => {
        httpGet({ path: '/dashboard', queryParams: null })
            .then(({ data, status, description }) => {
                if (status === STATUS_SUCCESS && data) resolve(data ? data : [])
                else reject(description)
            }).catch(e => reject(e))
    })
}

export const adminChangePassword = (body) => {

    return new Promise((reslove, reject) => {
        httpPost({ path: '/dashboard/change-password', body: body })
            .then(({ description, status }) => {

                if (status === STATUS_SUCCESS) {
                    reslove(description)
                }
                else {
                    reject(description)
                }

            })
            .catch(e => {
                reject(e)
            })
    })
}