import React from 'react'

import './shimmer.scss'

const Shimmer = ({ height = 'inherit', width = '100%', className }) => {
    return (
        <div className={`shimmer ${className}`} style={{ height: height, width: width }}> </div>
    )
}

export default Shimmer