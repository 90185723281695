import React, { useEffect, useState } from "react";
import { Dropdown, } from 'bootstrap';
import { Link } from "react-router-dom";
import ProfileLogo from '../../../assets/profile.svg';
import { color } from "@mui/system";
import FormControlLabel from '@mui/material/FormControlLabel';
import { Switch, Checkbox } from '@mui/material';
import { CustomOptions } from '../../user/login/Login';
import { useSnackbar } from 'react-simple-snackbar';
import {saveHierarchyData,getSalesHierarchy } from "../../../api/API";
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import { ActiveSwitch, MaterialUISwitch, theme } from "../../../scss/admin/mui";
import Select from 'react-select';
import { getItem } from "../../../util/LocalStorageUtil";
import './salesheirarchy.scss'



const AddHeirarchy = ({ getVendorList, onModalClose }) => {


    const [openSnackbar] = useSnackbar(CustomOptions)
    const [hierarchyData, setHierarchyData] = useState({
        parentShRid: null, shLevel: null, fullName: '', email: '', mobile: ''
    });

 
    const [showAlert, setShowAlert] = React.useState(false);
    const [alertMsg, setAlertMsg] = React.useState('');
    const [reportingVal,setReportingVal] = useState()
    const [salesHierarchyList, setSalesHierarchyList] = useState([])
    const [isLoading, setIsLoading] = React.useState(true);

    const handleChange = (event) => {
        setHierarchyData({
            ...hierarchyData,
            [event.target.name]: event.target.value,
        });
    };

    const [isSubmit, setIsSubmit] = React.useState(false);

    useEffect(() => {
        window.onpopstate = e => {
            window.location.reload()
        }
    },[])


    const saveHierarchy = () => {
        console.log(hierarchyData)
        if (!hierarchyData.fullName) {
            setAlertMsg("Please enter Full Name")
            setShowAlert(true)
            return
        }
        if (!hierarchyData.email) {
            setAlertMsg("Please enter email id")
            setShowAlert(true)
            return
        }
        setIsSubmit(true)
        saveHierarchyData(hierarchyData)
            .then((desc) => {
                if (desc) {
                    openSnackbar("Officer Details have been Updated")
                }
                setIsSubmit(false)
                dismissAlert()
                onModalClose()
            }).catch(e => {
                setIsSubmit(false)
                openSnackbar(e)
                dismissAlert()
                onModalClose()
            })

    }
    const dismissAlert = () => {
        setAlertMsg(''); setShowAlert(false)
    }

    const options = [
        { value: 3, label: 'Sales Officer' },
        { value: 2, label: 'State Incharge' },
        { value: 1, label: 'Regional Manager' }
      ]

    const regionalManager = (value) => {

    }

    const stateManager = (value) => {

    }

    const selectManager = (value) => {
        setReportingVal(value)
        hierarchyData.shLevel = value
        getHeirarchy((value-1))
    }

    const getHeirarchy = (getSh) => {
        getSalesHierarchy(getSh)
            .then(data => {
                setIsLoading(false)
                setSalesHierarchyList(data)
            })
    }

    // const handleUpdate = () => {
    //     myModal.handleUpdate()
    // }

    const ReportingTo = (value) => {
        console.log(value.value)
        if(value.value === 3||value.value === 2)  {
            return(
                <>
                    <label className="fs-normal-reg mb-2">Reporting To</label>
                    <Select className='col-md-12 col-12 ps-3 '
                        aria-label="Default select example"
                        maxMenuHeight={150}
                        options={salesHierarchyList.map(item => ({
                            label: item.fullName , value: item.shRid,
                        }))}
                        onChange = {opt => (hierarchyData.parentShRid=opt.value)}
                    /> 
                </>
            )
        }
        else{
            return(
                <></>
            )
        }
    }

    return (
        <ThemeProvider theme={theme}>

            <div className="modal fade custom-modal m-backdrop" id="addNewSalesModal"
                data-bs-backdrop="false" tabIndex="-1" aria-labelledby="VendorAddModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-lg max-w-600 modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header" style={{ borderBottom: 0 }}>

                            <p className="fs-normal-semibold text-primary my-auto">Add</p>

                            <button type="button" className=" float-end px-0 my-auto custom-btn" data-bs-dismiss="modal" aria-label="Close" onClick={onModalClose}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-x-circle float-end text-primary" viewBox="0 0 16 16">
                                    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                                    <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                                </svg>
                            </button>

                        </div>
                        <div className="modal-body px-0">
                            <div className="container p-0 px-3 vendor-modal-body">
                                <label className="fs-normal-reg mb-2 ps-3">Designation</label>
                                <Select className='col-md-5 col-12 ps-3 mb-4'
                                        options={options}
                                        onChange = {opt => selectManager(opt.value)}
                                />
                                 <div className="row mx-0 mt-2">
                                    <div className="col-md-6">
                                        <label className="fs-normal-reg mb-2">Name</label>
                                        <input
                                            type="text"
                                            className="form-control login-input p-07rem mb-3 w-100 fs-large-med px-4"
                                            onChange={handleChange}

                                            name="fullName" value={hierarchyData.fullName}
                                        />
                                    </div>
                                    <div className="col-md-6">
                                        <label className="fs-normal-reg mb-2">Mobile</label>
                                        <input
                                            type="text"
                                            className="form-control login-input p-07rem mb-3 w-100 fs-large-med px-4"
                                            onChange={handleChange}

                                            name="mobile" value={hierarchyData.mobile}
                                        />
                                    </div>
                                    <div className="col-md-6">
                                        <label className="fs-normal-reg mb-2">Email</label>
                                        <input
                                            type="text"
                                            className="form-control login-input p-07rem mb-3 w-100 fs-large-med px-4"
                                            onChange={handleChange}

                                            name="email" value={hierarchyData.email}
                                        />
                                    </div>
                                    <div className="col-md-6">
                                        <ReportingTo className='' value={reportingVal}/> 
                                    </div>
                                </div> 
                            </div>
                        </div>

                        {showAlert ?
                            <div className="alert alert-dismissible  fade show text-primary text-center fs-normal-med bg-transparent" role="alert">
                                {alertMsg}
                                <button type="button" className="  ms-5 px-0 custom-btn my-auto" data-bs-dismiss="alert" aria-label="Close" onClick={() => { dismissAlert() }}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-x-circle  text-primary" viewBox="0 0 16 16">
                                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                                        <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                                    </svg>
                                </button>
                            </div>
                            : null
                        }

                        <div className="modal-footer pb-0">
                            <button type="button" className="btn btn-secondary fs-large-med me-3" data-bs-dismiss="modal" onClick={onModalClose}>Cancel</button>
                            {isSubmit ? 
                                <button className="btn btn-primary br-21 fs-large-semibold text-light px-5 py-2">
                                    <div className="spinner-border text-success spinner-border-sm" role="status"></div>Save changes
                                </button> :
                                <button onClick={() => { saveHierarchy() }} className="btn btn-primary br-21 fs-large-semibold text-light px-5 py-2">Save changes</button>}
                        </div>


                    </div>
                </div>
            </div>

        </ThemeProvider>
    )



}
export default AddHeirarchy