import { React, useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import TVSLOgo from '../../../assets/TVS_logo.svg';
import Badge from "@material-ui/core/Badge";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCartOutlined";
import SearchMenu from "../../../components/search-dropdown/search-menu";
import './navbar.scss';
import { Modal } from 'bootstrap'
import { useLocation } from "react-router";
import { CartContext } from "../../../CartContext";
import { getUser} from '../../../util/LocalStorageUtil';


const TopNavbar = () => {

    const [count, setCount] = useState(null);
    const [filter,setFilter] = useState(false)
    const location = useLocation();
    const { cartCount, getCount } = useContext(CartContext)
    const [user, setUser] = useState()
    const { state } = useLocation();


    const filterModal = async (e) => {
        const openFilterModal = new Modal(document.querySelector('#filterModal'), {
            keyboard: false
        })
        // console.log(e)
        openFilterModal.show()
        // console.log(openFilterModal)
        // console.log(state)
    }


    const SearchFilter = () => {
        return(
            <>
                <div className="nav-item d-md-flex flex-row justify-content-between col-md-8 my-auto d-none">
                    <SearchMenu />
                    {state !== null?
                        <button className="btn btn-success ms-3 px-3 py-0" onClick={(e) => {filterModal(e)}}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-funnel text-white" viewBox="0 0 16 16">
                                <path d="M1.5 1.5A.5.5 0 0 1 2 1h12a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-.128.334L10 8.692V13.5a.5.5 0 0 1-.342.474l-3 1A.5.5 0 0 1 6 14.5V8.692L1.628 3.834A.5.5 0 0 1 1.5 3.5v-2zm1 .5v1.308l4.372 4.858A.5.5 0 0 1 7 8.5v5.306l2-.666V8.5a.5.5 0 0 1 .128-.334L13.5 3.308V2h-11z"/>
                            </svg>
                        </button>
                    :
                        <button className="btn btn-box btn-outline-success ms-3 px-3 py-0" onClick={(e) => {filterModal(e)}}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-funnel text-green" viewBox="0 0 16 16">
                                <path d="M1.5 1.5A.5.5 0 0 1 2 1h12a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-.128.334L10 8.692V13.5a.5.5 0 0 1-.342.474l-3 1A.5.5 0 0 1 6 14.5V8.692L1.628 3.834A.5.5 0 0 1 1.5 3.5v-2zm1 .5v1.308l4.372 4.858A.5.5 0 0 1 7 8.5v5.306l2-.666V8.5a.5.5 0 0 1 .128-.334L13.5 3.308V2h-11z"/>
                            </svg>
                        </button>
                    }
                </div>
            </>
        )
    }

    useEffect(() => {
        // console.log({cartCount})
        getCount();
        setCount(cartCount)
        if(location.pathname === '/place-order'){           // location.pathname === '/' ||  
            setFilter(true)
        }
        else{
            setFilter(false)
        }
    }, [cartCount],[location.pathname]);

    useEffect(() => {
        setUser(getUser())
        // console.log(state)
    }, [state])

    return (
        
        <nav className="z-ind-1 navbar navbar-expand-lg navbar-light bg-secondary top-navbar" fixed="bottom">
            <div className="container">
                <div className=" navbar-collapse justify-content-between row" id="topNavbar">

                    <div className="nav-item col-md-2 col-auto ">
                        <Link to="/landing" className="a-nonlink">
                            <img height="65px" src={TVSLOgo} alt="TVS Logo"/>
                        </Link>
                    </div>
                    {filter?<SearchFilter />:<div className="col-md-8 my-auto d-none d-md-block d-lg-block"></div>}
                    
                    <div className="nav-item  col-md-1 my-auto text-center col-auto cart-badge">
                        <Link to="/cart" className="nav-link fs-normal-reg">
                            <Badge color="secondary" badgeContent={cartCount} className="shopping-cart ">
                                <ShoppingCartIcon />
                                {" "}
                            </Badge>
                        </Link>
                    </div>

                    <div className=" nav-item  col-md-1 d-none d-md-block">
                        <Link to="/profile" className="nav-link">
                            <div className="circle-profile  justify-content-center">
                                <h2 className="fs-small-title-reg  my-auto text-center" > {user?.fullName.charAt(0)}</h2>
                            </div>
                        </Link>
                    </div>

                </div>
            </div>
        </nav>
    )
}
export default TopNavbar