import React, { useEffect, useState } from 'react'
import './payment.scss';
import { Link } from 'react-router-dom';
import UserLayout from '../../../UserLayout';
const Payment = () => {



    return (
        <UserLayout>
            <div className="container-fluid   align-self-center">
                <div className="row justify-content-center">
                </div>
                <div className="container my-md-100">
                    <div className="row justify-content-center ">
                        <div className="col-md-8">
                            <div className="d-none d-md-block d-lg-block">
                                <div className="mb-4  d-flex flex-row ">
                                    <a className="a-nonlink" href="#"><svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" fill="currentColor" className="bi bi-chevron-left" viewBox="0 0 16 16">
                                        <path fillRule="evenodd" d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z" />
                                    </svg></a>
                                    <a className="a-nonlink" href="#">
                                        <span className="fs-small-reg text-dark ms-2">Your Cart</span></a>
                                    <span className=" ms-2">/</span>
                                    <a className="a-nonlink" href="#">
                                        <span className="fs-small-reg text-dark ms-2">Payment methods</span></a>
                                </div>
                            </div>
                            <div className=" mb-4">
                                <p className="fs-normal-reg">Total payable amount</p>
                                <h1 className="fs-l-large-semibold">Rs. 40,000 /-</h1>
                                <p className="fs-large-regular">+FREE Delivery</p>
                            </div>
                            <hr></hr>

                            <div className="form-check pay-rad-btn ps-5">
                                <input className="form-check-input" checked type="radio" name="exampleRadios" id="bebit"
                                    value="option1" />
                                <label className="form-check-label d-flex flex-row" for="bebit">
                                    <div className="ms-3"><svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" className="bi bi-credit-card" viewBox="0 0 16 16">
                                        <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4zm2-1a1 1 0 0 0-1 1v1h14V4a1 1 0 0 0-1-1H2zm13 4H1v5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V7z" />
                                        <path d="M2 10a1 1 0 0 1 1-1h1a1 1 0 0 1 1 1v1a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1v-1z" />
                                    </svg></div>
                                    <div className="ms-3"> <span className="fs-l-large-regular ">Debit Card</span></div>
                                </label>
                            </div>
                            <hr></hr>
                            <div className="form-check pay-rad-btn ps-5">
                                <input className="form-check-input" type="radio" name="exampleRadios" id="credit"
                                    value="option2" />
                                <label className="form-check-label d-flex flex-row" for="credit">
                                    <div className="ms-3"><svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" className="bi bi-credit-card" viewBox="0 0 16 16">
                                        <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4zm2-1a1 1 0 0 0-1 1v1h14V4a1 1 0 0 0-1-1H2zm13 4H1v5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V7z" />
                                        <path d="M2 10a1 1 0 0 1 1-1h1a1 1 0 0 1 1 1v1a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1v-1z" />
                                    </svg></div>
                                    <div className="ms-3"> <span className="fs-l-large-regular ">Credit Card</span></div>
                                </label>

                            </div>
                            <hr></hr>
                            <div className="form-check pay-rad-btn ps-5">
                                <input className="form-check-input" type="radio" name="exampleRadios"
                                    id="bhim" value="option3" />

                                <label className="form-check-label d-flex flex-row" for="bhim">
                                    <div className="ms-3"><svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" className="bi bi-cash" viewBox="0 0 16 16">
                                        <path d="M8 10a2 2 0 1 0 0-4 2 2 0 0 0 0 4z" />
                                        <path d="M0 4a1 1 0 0 1 1-1h14a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1H1a1 1 0 0 1-1-1V4zm3 0a2 2 0 0 1-2 2v4a2 2 0 0 1 2 2h10a2 2 0 0 1 2-2V6a2 2 0 0 1-2-2H3z" />
                                    </svg></div>
                                    <div className="ms-3"> <span className="fs-l-large-regular ">BHIM UPI</span></div>
                                </label>
                            </div>
                            <hr></hr>
                            <div className="form-check pay-rad-btn ps-5">
                                <input className="form-check-input" type="radio" name="exampleRadios"
                                    id="paytm" value="option4" />
                                <label className="form-check-label d-flex flex-row" for="paytm">
                                    <div className="ms-3"><svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" className="bi bi-cash" viewBox="0 0 16 16">
                                        <path d="M8 10a2 2 0 1 0 0-4 2 2 0 0 0 0 4z" />
                                        <path d="M0 4a1 1 0 0 1 1-1h14a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1H1a1 1 0 0 1-1-1V4zm3 0a2 2 0 0 1-2 2v4a2 2 0 0 1 2 2h10a2 2 0 0 1 2-2V6a2 2 0 0 1-2-2H3z" />
                                    </svg></div>
                                    <div className="ms-3"> <span className="fs-l-large-regular ">Paytm</span></div>
                                </label>

                            </div>
                            <hr></hr>
                            <div className="col-md-6 mt-4 d-none d-md-block d-lg-block">

                                <Link className=" w-100 a-non-hover" to="/pay-history">
                                    <button className="btn w-100 btn-box  bg-green">

                                        <span className=" fs-normal-reg text-light" >Proceed to pay</span>

                                    </button>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="d-sm-block d-md-none d-lg-none">
                    <div className="row justify-content-center py-2 w-100 sticky-div"  >
                        <div className=" col-10">
                            <Link className=" w-100 a-non-hover" to="/pay-history">
                                <button className="btn w-100 btn-box  bg-green">
                                    <span className=" fs-normal-reg text-light" >Proceed to pay</span>
                                </button>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </UserLayout>
    )
}
export default Payment