import React, { useEffect, useState } from 'react'
import UserLayout from '../../../UserLayout';
import { useSearchParams } from 'react-router-dom';
import { getProductList, saveComplaint } from '../../../api/API';
import { useSnackbar } from 'react-simple-snackbar'
import { CustomOptions } from '../login/Login';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router';
import Select from 'react-select';
import { Link } from 'react-router-dom';

const SearchQueryResult = () => {

    const [searchParams] = useSearchParams()
    const params = Object.fromEntries([...searchParams]);
    const navigate = useNavigate();
    const [openSnackbar] = useSnackbar(CustomOptions)
    const [searchQuery, setSearchQuery] = useState(null)
    const [productList, setProductList] = useState([])
    const [prodList, setProdList] = useState([])
    let validator = null
    const { state } = useLocation(); 

    const onInputChange = (user,index) => {
        setProductList(oldState => oldState.map((oUser, oIndex) => oIndex===index ? user : oUser))
        // console.log(productList)
    }

    const returnItems = (productList) => {

        // console.log(productList)
       
        let complaintList = productList.map((item) => {
            return{skuRid:item.skuRid,serialNos:item.serialNos,remarks:item.remarks,qty:item.returnQty}
        })
        console.log(complaintList)
        validator = complaintList.map((item,index) => {
            if(typeof(item.serialNos) ==='undefined' || typeof(item.qty) ==='undefined'){
                // openSnackbar("Serial No's / Qty can't be empty")
                return false
            }
            else{
                return true
            }
        })
        sendComplaint(complaintList)
    }

    const sendComplaint = (complaintList) => {
        console.log(complaintList)
        const complaintValidator = validator.find(element => element === false)
        if(complaintValidator !== false){
            saveComplaint(complaintList)
                .then((description) => {
                    openSnackbar(description)
                    navigate("/query-list")
                })
        }
        else{
            openSnackbar("Serial No's / Qty can't be empty")
        }
    }
    const deleteQuery = (skuRid) => {
        setProductList( state => state.filter(item => item.skuRid !== skuRid))
    }

    const addProduct = (skuCode) => {
        if(skuCode!=="" && skuCode!==null){
            const result = productList.find(sku => (skuCode === sku.skuCode))
            // console.log(result)
            if(result){
                openSnackbar("Item is already added") 
            }
            else{
                getProductList({skuCode: skuCode})
                .then(data => {
                    // console.log(data)
                    data.map((item) => (
                        setProductList([...productList,item])
                    ))  
            })
            // console.log(productList)
            }
        }
        else{
            openSnackbar("Please select an Item")
        }
            
    }

    const getProdList = () =>  {
        getProductList()
        .then(data => {
            setProdList(data)
        })
    }

    useEffect(() => {
        if(state){
            getProdList()
            getProductList({skuCode: state})
            .then(data => {
                // console.log(data)
                setProductList(data)
            })
            // console.log(productList)
        }
        else{
            openSnackbar("Invalid Part No.")
        }
            
    }, [])

    return (
        <UserLayout>
            <div className="container-fluid   align-self-center">
                <div className="row justify-content-center">

                    <div className="container my-md-200 ">
                        <div className="row justify-content-center">
                            <div className="col-md-8 col-11 my-auto d-none d-md-block d-lg-block">
                                <div className="mb-3 d-lg-block d-md-block d-none">
                                        <div className="d-flex flex-row ">
                                            <Link className="d-flex flex-row  a-nonlink" to="/landing">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="#5CA772" className="bi bi-chevron-left mt-1" viewBox="0 0 16 16">
                                                    <path fill-rule="evenodd" d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z" />
                                                </svg>
                                                <p className="fs-large-med btn-link text-green ms-2">Home</p>
                                            </Link>
                                            <Link className="d-flex flex-row  a-nonlink" to="/query-list">
                                                <p className="fs-large-light text-dark ms-2">/ </p>
                                                <p className="fs-large-med btn-link text-green ms-2">Query List</p>
                                            </Link>
                                            <p className="fs-large-light text-dark ms-2">/ New Query</p>
                                        </div>
                                </div>
                            </div>
                            <div className="col-md-8 col-11 my-auto mt-4 search-query d-md-flex flex-md-row">
                                        
                                        <Select className='col-md-7 col-11 me-3'
                                            options={prodList.map(item => ({
                                                label: item.title, value: item.skuCode,
                                            }))}
                                            onChange = {opt => setSearchQuery(opt.value)}
                                        />
                                        <div className="col-md-2 col-4 mt-2 my-lg-auto">
                                            <button onClick={() => {addProduct(searchQuery)}} className="btn w-100 btn-box  bg-green">
                                                <span className=" fs-normal-reg text-light" >Add</span>
                                            </button>
                                        </div>
                                
                            </div>

                            <div className="col-md-8 col-11 my-auto mt-3">
                                <div className="container px-0 my-2 my-md-3">
                                    <div className="row justify-content-center">
                                    {
                                        productList!==null? productList.map((item,index) => (
                                            <>

                                                <div className="col-md-5 col-12">
                                                    <div className="d-flex flex-row justify-content-between">
                                                        <p className="fs-large-regular mb-1">
                                                            {item.title}
                                                        </p>
                                                        <div onClick={() =>{deleteQuery(item.skuRid)}} className="col-md-2 text-end col-sm-1 pointer">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-trash text-danger ms-2" viewBox="0 0 16 16">
                                                                <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                                                                <path fill-rule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z" />
                                                            </svg>
                                                        </div>
                                                    </div>
                                                    <p className='fs-normal-reg me-5 mb-4'>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24">
                                                            <path fill="#5CA772" d="M19 19H5V5H19M19 3H5A2 2 0 0 0 3 5V19A2 2 0 0 0 5 21H19A2 2 0 0 0 21 19V5A2 2 0 0 0 19 3M7 9H9.31L9.63 6H11.63L11.31 9H13.31L13.63 6H15.63L15.31 9H17V11H15.1L14.9 13H17V15H14.69L14.37 18H12.37L12.69 15H10.69L10.37 18H8.37L8.69 15H7V13H8.9L9.1 11H7V9M11.1 11L10.9 13H12.9L13.1 11Z" />
                                                        </svg>
                                                        {item.skuCode}
                                                    </p>
                                                </div>
                                                

                                                <div className="col-md-5 col-12">
                                                    <p className="fs-normal-med mb-0">Qty</p>
                                                    <div className="d-flex py-2 px-2 mb-3 searchbar">
                                                        <input type="text" pattern="[0-9]*" value={item.returnQty} onChange={(e) => (e.target.validity.valid) ? onInputChange({...item, returnQty: e.target.value},index) : openSnackbar("Enter Valid Quantity")} className="fs-x-large-regular search-input" placeholder="Enter Quantity"/>
                                                    </div>
                                                </div>
                                                <div className="col-md-5 col-12">
                                                    <p className="fs-normal-med mb-0">Serial Numbers</p>
                                                    <div className="d-flex py-2 px-2 mb-3 searchbar">
                                                        <input type="text"  value={item.serialNo} onChange={(e) => onInputChange({...item, serialNos: e.target.value},index)} className="fs-x-large-regular search-input" placeholder="Enter Serial Numbers"/>
                                                    </div>
                                                </div>

                                                <div className="col-md-5 col-12">
                                                    <p className="fs-normal-med mb-0">Remarks</p>
                                                    <div className="d-flex py-2 px-2 mb-3 searchbar">
                                                        <input type="text" value={item.remarks} onChange={(e) => onInputChange({...item, remarks: e.target.value},index)}  className="fs-x-large-regular search-input" placeholder="Enter Remarks"/>
                                                    </div>
                                                </div>
                                                
                                                <hr></hr>

                                            </>
                                        )):null 
                                    }
                                    <div className='col-md-8 justify-content-center'>
                                    
                                        <button onClick={()=>{returnItems(productList)}} className="col btn w-100 btn-success py-2 px-5 mx-auto my-2">
                                            <span className="fs-normal-reg text-light" >Return Items</span>
                                        </button>
                                   
                                    </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </UserLayout>
    )
}
export default SearchQueryResult