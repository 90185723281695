import React, { useEffect, useState } from "react";
import AdminLayout from "../../../AdminLayout";
import './announcement.scss';
import { useNavigate } from 'react-router'
import { adminLogin, getAnnouncement, login, saveAnnouncement, updateAnnouncement } from '../../../api/API'
import { useSnackbar } from 'react-simple-snackbar'
import { Checkbox, Radio, FormControlLabel, FormLabel, FormGroup } from '@mui/material';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import { AntSwitch, theme, ToggleSwitch } from "../../../scss/admin/mui";
import { ShimmerAnnouncement } from "../../../components/shimmer-effect/ShimmerEffect";


const Announcement = () => {
    const [state, setState] = useState({
        announcementList: [], refTitle: 'New Product Launch', description: '',
        latestAnnouncement: '', status: '', announcementId: '', newDescription: ''
    })
    const [isLoading, setIsLoading] = useState(true)
    const navigate = useNavigate()
    const [openSnackbar] = useSnackbar()


    const handleChange = (e, key) => {
        setState({
            ...state, [key]: e.target.value
        })
    }

    useEffect(() => {
        getAnnouncementList()
    }, [])

    const getAnnouncementList = async () => {
        getAnnouncement()
            .then((data) => {

                if (data) {
                    // const filteredAnnouncement = data.filter(item => item.status == 1)[0];
                    const newData = data[0];


                    if (newData) {
                        setState(
                            {
                                ...state,
                                announcementList: newData,
                                status: newData.status,
                                description: newData.description,
                                announcementId: newData.anRid,
                                newDescription: ''
                            })
                    }
                }
                setIsLoading(false)
            }).catch(e => {
                setIsLoading(false)
                openSnackbar(e)
            })
        setState({ ...state, newDescription: '' })
    };


    const publishAnnouncement = () => {

        // if (!state.refTitle) {
        //     openSnackbar("Please enter valid title")
        //     return
        // }
        if (!state.newDescription) {
            openSnackbar("Please enter valid announcement")
            return
        }
        setIsLoading(true)
        saveAnnouncement({ refTitle: state.refTitle, description: state.newDescription })
            .then((desc) => {
                if (desc) {
                    openSnackbar(desc)
                }
                getAnnouncementList()
                setIsLoading(false)

            }).catch(e => {
                getAnnouncementList()
                setIsLoading(false)
                openSnackbar(e)

            })

        setState({ ...state, newDescription: '' })

    }

    const handleToggle = (e, aId) => {
        let status = (state.status == 1 ? 0 : 1)
        setState({ ...state, status: status });
        disableAnnouncement(status, aId)
    };

    const disableAnnouncement = (status, aId) => {
        updateAnnouncement(aId, status)
            .then((desc) => {
                if (desc) {
                    openSnackbar(desc)
                }
                setState({ ...state, status: status });
            }).catch(e => {

                setIsLoading(false)

            })

    }


    return (
        <AdminLayout title="Announcements">
            <div className="container">
                {isLoading ?
                    <>
                        <ShimmerAnnouncement />
                    </>
                    : <div className="row">
                        {state.announcementList ?
                            <div className="col-md-8 d-flex flex-row">
                                <FormGroup className={"custom-switch-btn my-auto"}>
                                    <FormControlLabel
                                        control={<ToggleSwitch
                                            checked={state.status}

                                            name={'switch' + 1} onChange={(e) => { handleToggle(e, state.announcementId) }} />}
                                    />
                                </FormGroup>
                                <label className="fs-x-large-semibold my-auto">Announcement - {state.status ? 'Active' : " Disabled"}

                                    {state.status ? <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check2-circle ms-2 text-green" viewBox="0 0 16 16">
                                        <path d="M2.5 8a5.5 5.5 0 0 1 8.25-4.764.5.5 0 0 0 .5-.866A6.5 6.5 0 1 0 14.5 8a.5.5 0 0 0-1 0 5.5 5.5 0 1 1-11 0z" />
                                        <path d="M15.354 3.354a.5.5 0 0 0-.708-.708L8 9.293 5.354 6.646a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0l7-7z" />
                                    </svg>
                                        : <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-circle ms-2 text-grey" viewBox="0 0 16 16">
                                            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                                            <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                                        </svg>}
                                </label>
                            </div> : null}
                        <div className="col-md-8 mt-3">
                            <textarea
                                className="form-control login-input p-07rem mb-3 w-100 fs-large-med px-4"
                                disabled

                                rows={4}
                                value={state.description}
                            >
                            </textarea>
                        </div>
                    </div>
                }
                <br></br>
                <br></br>
                <div className="row">
                    <div className="col-md-8">
                        <label className="fs-normal-reg mb-2">Update announcement</label>
                        {/* <input type="email" className="form-control login-input p-07rem mb-3 w-100 fs-x-large-regular px-4"
                            value={state.refTitle}
                            onChange={(e) => { handleChange(e, 'refTitle') }}
                            placeholder="Title" /> */}
                        <textarea
                            className="form-control login-input p-07rem mb-3 w-100 fs-large-med px-4"
                            onChange={(e) => { handleChange(e, 'newDescription') }}
                            placeholder="Enter announcement text here...."
                            rows={4}
                            value={state.newDescription}
                        >

                        </textarea>
                        <div className="text-end">
                            <button onClick={() => { publishAnnouncement() }}
                                className="btn btn-primary br-21 fs-large-semibold text-light px-5  py-2">Publish changes</button>
                        </div>
                    </div>
                </div>
            </div>
        </AdminLayout>

    )




}
export default Announcement