import React, { useEffect, useState } from 'react'
import Index from '../index/index';
import KentBanner from '../../assets/kent-fan-banner.svg';
import fanImg from '../../assets/fan_img.svg';
import { BASE_URL } from '../../util/EnvHelper';
import { useNavigate } from 'react-router';
import { toPrefixedLocalePrice } from '../../util/Util';
const Result = ({ productList }) => {

    const navigate = useNavigate()

    const viewMoreDetails = (skuRid) => {
        navigate("/product", { state: skuRid })
    }
    
    return (
        <div className="col-md-8 my-auto">
            <div className="row  justify-content-left mt-3 mt-md-5">
                {productList && productList?.length > 0 ?
                    productList.map((item, index) => (
                        <div className="col-md-3 mb-3 col-6 pointer" key={index} onClick={() => { viewMoreDetails(item.skuRid) }}>
                            <div className="card h-100 rounded-card">
                                <div className="text-center mt-4 mt-md-5 mx-3">
                                    <img src={BASE_URL + "/" + item.imageUrl}
                                        // onError={e => { e.currentTarget.src = "error.png"; }}
                                        className="img-fluid w-100" />
                                </div>
                                <div className="card-body p-md-3 p-2 mt-2">
                                    <a>
                                        <button className="btn py-1 rounded-btn fs-x-small-reg">
                                            {item.prodName}
                                        </button></a>
                                    <p className="fs-normal-reg mt-1 mt-md-2 mb-0">
                                        {item.title}
                                    </p>
                                    <h1 className="fs-large-semibold  mt-1 mt-md-2">{toPrefixedLocalePrice(item.price)}</h1>
                                </div>

                            </div>
                        </div>
                    )) :
                    <div className="col-md-12 text-center">
                        <h1 className="fs-large-regular"><i>No records</i></h1>
                    </div>}
            </div>
            {/* <div className="row   justify-content-between mt-3">



                <div className="col-md-3  mb-3 col-6">
                    <a href="#" className="a-nonlink"><div className="card h-100 rounded-card">
                        <div className="text-center mt-4 mt-md-5">
                            <img src={fanImg} className="img-fluid w-100" />
                        </div>
                        <div className="card-body p-md-3 p-2 mt-2">
                            <a>
                                <button className="btn  rounded-btn fs-x-small-reg">
                                    High speed ceiling fan
                                </button></a>
                            <p className="fs-normal-reg mt-1 mt-md-2 mb-0">Ceiling fan black with
                                golden accents
                            </p>
                            <h1 className="fs-large-semibold  mt-1 mt-md-2">Rs. 10,000/-</h1>
                        </div>

                    </div></a>
                </div>
                <div className="col-md-3  col-6  mb-3">
                    <a href="#" className="a-nonlink"><div className="card h-100 rounded-card">
                        <div className="text-center mt-4 mt-md-5">
                            <img src={fanImg} className="img-fluid w-100" />
                        </div>
                        <div className="card-body p-md-3 p-2 mt-2">
                            <a>
                                <button className="btn  rounded-btn fs-x-small-reg">
                                    High speed ceiling fan
                                </button></a>
                            <p className="fs-normal-reg mt-1 mt-md-2 mb-0">Ceiling fan black with
                                golden accents
                            </p>
                            <h1 className="fs-large-semibold  mt-1 mt-md-2">Rs. 10,000/-</h1>
                        </div>
                    </div></a>
                </div>
                <div className="col-md-3  col-6  mb-3">
                    <a href="#" className="a-nonlink"><div className="card h-100 rounded-card">
                        <div className="text-center mt-4 mt-md-5">
                            <img src={fanImg} className="img-fluid w-100" />
                        </div>
                        <div className="card-body p-md-3 p-2 mt-2">
                            <a>
                                <button className="btn  rounded-btn fs-x-small-reg">
                                    High speed ceiling fan
                                </button></a>
                            <p className="fs-normal-reg mt-1 mt-md-2 mb-0">Ceiling fan black with
                                golden accents
                            </p>
                            <h1 className="fs-large-semibold  mt-1 mt-md-2">Rs. 10,000/-</h1>
                        </div>
                    </div></a>
                </div>
                <div className="col-md-3  col-6  mb-3">
                    <a href="#" className="a-nonlink"><div className="card h-100 rounded-card">
                        <div className="text-center mt-4 mt-md-5">
                            <img src={fanImg} className="img-fluid w-100" />
                        </div>
                        <div className="card-body p-md-3 p-2 mt-2">
                            <a>
                                <button className="btn  rounded-btn fs-x-small-reg">
                                    High speed ceiling fan
                                </button></a>
                            <p className="fs-normal-reg mt-1 mt-md-2 mb-0">Ceiling fan black with
                                golden accents
                            </p>
                            <h1 className="fs-large-semibold  mt-1 mt-md-2">Rs. 10,000/-</h1>
                        </div>
                    </div></a>
                </div>
                <div className="col-md-3  mb-3 col-6">
                    <a href="#" className="a-nonlink"><div className="card h-100 rounded-card">
                        <div className="text-center mt-4 mt-md-5">
                            <img src={fanImg} className="img-fluid w-100" />
                        </div>
                        <div className="card-body p-md-3 p-2 mt-2">
                            <a>
                                <button className="btn  rounded-btn fs-x-small-reg">
                                    High speed ceiling fan
                                </button></a>
                            <p className="fs-normal-reg mt-1 mt-md-2 mb-0">Ceiling fan black with
                                golden accents
                            </p>
                            <h1 className="fs-large-semibold  mt-1 mt-md-2">Rs. 10,000/-</h1>
                        </div>

                    </div></a>
                </div>
                <div className="col-md-3  col-6  mb-3">
                    <a href="#" className="a-nonlink"><div className="card h-100 rounded-card">
                        <div className="text-center mt-4 mt-md-5">
                            <img src={fanImg} className="img-fluid w-100" />
                        </div>
                        <div className="card-body p-md-3 p-2 mt-2">
                            <a>
                                <button className="btn  rounded-btn fs-x-small-reg">
                                    High speed ceiling fan
                                </button></a>
                            <p className="fs-normal-reg mt-1 mt-md-2 mb-0">Ceiling fan black with
                                golden accents
                            </p>
                            <h1 className="fs-large-semibold  mt-1 mt-md-2">Rs. 10,000/-</h1>
                        </div>
                    </div></a>
                </div>
                <div className="col-md-3  col-6  mb-3">
                    <a href="#" className="a-nonlink"><div className="card h-100 rounded-card">
                        <div className="text-center mt-4 mt-md-5">
                            <img src={fanImg} className="img-fluid w-100" />
                        </div>
                        <div className="card-body p-md-3 p-2 mt-2">
                            <a>
                                <button className="btn  rounded-btn fs-x-small-reg">
                                    High speed ceiling fan
                                </button></a>
                            <p className="fs-normal-reg mt-1 mt-md-2 mb-0">Ceiling fan black with
                                golden accents
                            </p>
                            <h1 className="fs-large-semibold  mt-1 mt-md-2">Rs. 10,000/-</h1>
                        </div>
                    </div></a>
                </div>
                <div className="col-md-3  col-6  mb-3">
                    <a href="#" className="a-nonlink"><div className="card h-100 rounded-card">
                        <div className="text-center mt-4 mt-md-5">
                            <img src={fanImg} className="img-fluid w-100" />
                        </div>
                        <div className="card-body p-md-3 p-2 mt-2">
                            <a>
                                <button className="btn  rounded-btn fs-x-small-reg">
                                    High speed ceiling fan
                                </button></a>
                            <p className="fs-normal-reg mt-1 mt-md-2 mb-0">Ceiling fan black with
                                golden accents
                            </p>
                            <h1 className="fs-large-semibold  mt-1 mt-md-2">Rs. 10,000/-</h1>
                        </div>
                    </div></a>
                </div>
            </div> */}
        </div>
    )
}
export default Result
