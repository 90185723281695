import React, { useEffect, useState,useRef } from "react";
import { Link } from "react-router-dom";
import { Checkbox, Radio, FormControlLabel, FormLabel, FormGroup } from '@mui/material';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import { AntSwitch, theme } from "../../../../scss/admin/mui";
import { getSingleProductDetails, saveInventory, uploadImage } from "../../../../api/API";
import { ShimmerCart, ShimmerInventory } from "../../../../components/shimmer-effect/ShimmerEffect";
import { CustomOptions } from "../../../user/login/Login";
import { useSnackbar } from 'react-simple-snackbar';
import { BASE_URL } from "../../../../util/EnvHelper";
import SkuFeature from "./SkuFeature";
import ProductAttribute from "./ProductAttribute";
import { validateForm } from "../../../../util/Validator";



const EditInventory = ({ prodId, onModalClose, getProdList }) => {
    const [openSnackbar] = useSnackbar(CustomOptions)
    const [isLoading, setIsLoading] = React.useState(true);
    const [isSubmit, setIsSubmit] = React.useState(false);
    const [showAlert, setShowAlert] = React.useState(false);
    const [alertMsg, setAlertMsg] = React.useState('');


    const [skuList, setSkuList] = useState([{
        skuCode: '', skuName: '', imageList: [], color: '', skuPriceList: [], imageUrl: '', status: ''
    }]);
    const [productList, setProductList] = useState([{
        prodRid: '', prodName: '', prodCode: ''
    }])
    const [attributeList, setAttributeList] = useState([{
        attributeName: '', value: '', valueId: '', displayOrder: ''
    }]);

    useEffect(() => {
        window.onpopstate = e => {
            window.location.reload()
        }
    })

    useEffect(() => {
        getSingleProduct(prodId)
    }, [])

    const getSingleProduct = async (prodId) => {

        setIsLoading(true)
        getSingleProductDetails(prodId)
            .then((data) => {
                setProductList({
                    ...productList,
                    prodRid: data.prodRid,
                    prodName: data.prodName,
                    prodCode: data.prodCode
                })
                setSkuList(data.skuList);
                setAttributeList(data.attributeList)
                setIsLoading(false)
            }).catch(e => {
                setIsLoading(false)
                openSnackbar(e)
            })
    };


    const form = useRef()

    const [state, setState] = React.useState({
        status: 0
    });

    const [color, setColor] = React.useState({
        ivory: true,
        pearl: true,
        baker: false,
        brown: false,
        white: false,
    });

    const [type, setType] = React.useState({
        regulator: true,
        remote: false,
    });

    const { ivory, pearl, baker, brown, white } = color;
    const { regulator, remote } = type;

    const handleChange = (event) => {
        setColor({
            ...color,
            [event.target.name]: event.target.checked,
        });
    };

    const selectType = (event) => {
        setType({
            ...type,
            [event.target.name]: event.target.checked,
        });
    };


    const setStatus = (parentIndex, index, imgurl) => {
        setSkuList(s => {
            const newVariant = s.slice();
            newVariant[parentIndex].imageUrl = imgurl
            return newVariant;
            // for (const [ffIndex, value] of newVariant[parentIndex].imageList.entries()) {
            // }
            // const newVariantList = s.slice();
            // newVariantList[parentIndex][key].splice(index, 1);
        });
    };




    const saveInventoryData = () => {
        // if (attributeList == null) {
        //     setAlertMsg("Please enter valid attributes")
        //     setShowAlert(true)
        //     return
        // }
        // if (!productList.prodName) {
        //     setAlertMsg("Please enter product name")
        //     setShowAlert(true)
        //     return
        // }
        // if (!skuList) {
        //     setAlertMsg("Please enter sku name")
        //     setShowAlert(true)
        //     return
        // }
        if (!validateForm(document.querySelector('form'))) return

        // const formData = new FormData(document.querySelector('#addInventoryForm'))
        // const noteData = Object.fromEntries(formData)

        setIsSubmit(true)
        saveInventory({
            prodRid: productList.prodRid, prodName: productList.prodName,
            prodCode: productList.prodCode, skuList: skuList, attributeList: attributeList
        })
            .then((desc) => {
                if (desc) {
                    openSnackbar("Product details been updated")
                }
                onModalClose()
                setIsSubmit(false)
            }).catch(e => {
                setIsSubmit(false)
                openSnackbar(e)
                onModalClose()
            })

        dismissAlert()
        getProdList()
    }
    const dismissAlert = () => {
        setAlertMsg(''); setShowAlert(false)
    }


    const addAttribute = () => {
        setAttributeList(s => [...s, { attributeName: '', value: '', valueId: '', displayOrder: '' }]);
    };

    return (
        productList ?
            <ThemeProvider theme={theme}>
                <div className="modal fade custom-modal  m-backdrop"  id="inventoryEditModal" data-bs-backdrop="false" tabIndex="-1" aria-labelledby="inventoryModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-lg  modal-dialog-centered modal-dialog-scrollable mw-1000">
                        <div className="modal-content">
                            <div className="modal-header" style={{ borderBottom: 0 }}>

                                <h1 className="fs-large-semibold text-primary my-auto ms-3">Edit</h1>

                                <button type="button" className=" my-auto custom-btn" data-bs-dismiss="modal" aria-label="Close" onClick={onModalClose}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-x-circle float-end text-primary" viewBox="0 0 16 16">
                                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                                        <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                                    </svg>
                                </button>

                            </div>
                            {isLoading ? (<ShimmerInventory />) :
                                (<div className="modal-body px-0" style={{ overflowX: 'hidden' }}>
                                    <div className="container pt-0 px-0" >
                                    <form ref={form} id="editInventoryForm">    
                                        <div className=" row  mt-2">
                                            <div className="col-md-3 ms-4">
                                                <label className="fs-normal-reg mb-2  ms-3">Model name</label>
                                                <input
                                                    type="text"
                                                    className="form-control login-input p-07rem mb-3 w-100 fs-large-med px-4"
                                                    readOnly value={productList.prodName}
                                                />
                                            </div>
                                            <div className="col-md-6"></div>
                                            {/* <div className="col-md-12  mt-2 custom-check-box px-5">
                                        <h1 className="fs-normal-reg">Colors</h1>
                                        <FormGroup row >
                                            <FormControlLabel className="col-md-2 " control={<Checkbox checked={ivory}
                                                onChange={handleChange} name="ivory" />} label={"Ivory"} />
                                            <FormControlLabel className="col-md-2" control={<Checkbox checked={pearl} onChange={handleChange} name="pearl" />} label={"Pearl White"} />
                                            <FormControlLabel className="col-md-2" control={<Checkbox checked={baker} onChange={handleChange} name="baker" />} label={"Berk brown"} />
                                            <FormControlLabel className="col-md-2" control={<Checkbox checked={brown} onChange={handleChange} name="brown" />} label={"Brown"} />
                                            <FormControlLabel className="col-md-2" control={<Checkbox checked={white} onChange={handleChange} name="white" />} label={"White"} />


                                        </FormGroup>
                                    </div> */}

                                            {/* <div className="col-md-12  my-2 custom-check-box px-5">
                                        <h1 className="fs-normal-reg">Type</h1>
                                        <FormGroup row>
                                            <FormControlLabel className="col-md-2" control={<Checkbox checked={regulator} onChange={selectType} name="regulator" />} label={"Regulator"} />
                                            <FormControlLabel className="col-md-2" control={<Checkbox checked={remote} onChange={selectType} name="remote" />} label={"Remote"} />
                                        </FormGroup>
                                    </div> */}

                                        </div>

                                        {/* <div className="container mb-5">
                                            <div className="row">
                                                {attributeList !== null ?
                                                    attributeList.map((item, index) => (

                                                        <>
                                                            <div className="col-md-4 ">
                                                                <label className="fs-normal-reg mb-2 ms-3">Attribute Name</label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control login-input p-07rem mb-3 w-100 fs-large-med px-4"
                                                                    onChange={(e) => inutChange(index, 'attributeName', e)}
                                                                    value={item.attributeName}
                                                                />
                                                            </div>
                                                            <div className="col-md-4 ms-4">
                                                                <label className="fs-normal-reg mb-2  ms-3">Attribute Value</label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control login-input p-07rem mb-3 w-100 fs-large-med px-4"
                                                                    onChange={(e) => inutChange(index, 'value', e)}
                                                                    value={item.value}
                                                                />
                                                            </div>
                                                            <div className="col-md-2 my-auto">
                                                                <button type="button" className="btn rounded-close-btn"
                                                                    onClick={() => deleteAttribute((attributeList).length, index)}>
                                                                    <span aria-hidden="true">&times;</span></button>

                                                            </div>
                                                        </>

                                                    )) : null
                                                }
                                                <div className="col-md-4 "></div>
                                                <div className="col-md-4  text-end">
                                                    <button type="button" className=" btn rounded-plus-btn  fs-large-med"
                                                        onClick={addAttribute}>+ Attribute</button>
                                                </div>
                                            </div>
                                        </div> */}

                                        <hr></hr>
                                        <ProductAttribute
                                            attributeDetailList={attributeList}
                                            updateAttributeList={(data) => setAttributeList(data)}
                                        />

                                        <hr></hr>

                                        <SkuFeature
                                            skuFeatureList={skuList}

                                        />
                                        {/* <div className="ms-4 my-3">
                                <button to="/dashboard" className="border-primary btn text-primary bg-transparent br-21 fs-large-semibold  px-5 py-2"
                                    onClick={addVariant} >+ Add new variant</button>

                            </div> */}
                                           </form>

                                    </div>


                                </div>)}
                            {showAlert ?
                                <div className="alert alert-dismissible  fade show text-primary text-center fs-normal-med bg-transparent" role="alert">
                                    {alertMsg}
                                    <button type="button" className="  ms-5 px-0 custom-btn my-auto" data-bs-dismiss="alert" aria-label="Close" onClick={() => { dismissAlert() }}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-x-circle  text-primary" viewBox="0 0 16 16">
                                            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                                            <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                                        </svg>
                                    </button>
                                </div>
                                : null}
                            <div className="modal-footer " style={{ borderTop: 0 }}>
                                <Link to="/product-list" type="button" className="btn btn-secondary fs-large-med me-3" data-bs-dismiss="modal" onClick={onModalClose}>Cancel</Link>
                                {isSubmit ? <button className="btn btn-primary br-21 fs-large-semibold text-light px-5 py-2">
                                    <div className="spinner-border text-success spinner-border-sm" role="status"></div>Save changes</button> :
                                    <button onClick={() => { saveInventoryData() }} className="btn btn-primary br-21 fs-large-semibold text-light px-5 py-2" >Save changes</button>}
                            </div>
                        </div>
                    </div >

                </div >

            </ThemeProvider> : null
    )



}
export default EditInventory