import React, { useEffect, useState } from "react";
import { Dropdown, } from 'bootstrap';
import { Link } from "react-router-dom";
import ProfileLogo from '../../../assets/profile.svg';
import { color } from "@mui/system";
import FormControlLabel from '@mui/material/FormControlLabel';
import { Switch, Checkbox } from '@mui/material';
import { CustomOptions } from '../../user/login/Login';
import { useSnackbar } from 'react-simple-snackbar';
import { getCategoryList, getStateList, savePartnerData,getSalesHierarchy } from "../../../api/API";
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import { ActiveSwitch, MaterialUISwitch, theme } from "../../../scss/admin/mui";
import Select from 'react-select';

const VendorAddModal = ({ getVendorList, onModalClose }) => {


    const [openSnackbar] = useSnackbar(CustomOptions)
    const [partnerData, setPartnerData] = useState({
        partRid: 0, venRid: 1, partShortName: '', partName: '', partCode: '', contactPerson: '', email: '', mobile1: '',
        mobile2: '', gst: '', pan: '', address1: '', address2: '', address3: '', billToAddress1: '', billToAddress2: '', billToAddress3: '', stateName: '', smRid: '',billToPincode:'',billToStateName:'',billToSmRid:'', currency: 'INR', pincode: '',
        country: '', status: '', paymentTerm: "100% Advance", creditDays: 0, partCategory:null, shRid: null
    });
    const [categoryList, setCategoryList] = useState([]);
    const [stateList, setStateList] = useState([]);
    const [showAlert, setShowAlert] = React.useState(false);
    const [alertMsg, setAlertMsg] = React.useState('');
    const [salesHierarchyList, setSalesHierarchyList] = useState([])
    const [isLoading, setIsLoading] = React.useState(true);
    const [billAddress , setBillAddress] = useState(false)


    useEffect(() => {
        getCategory()
        getState()
        getHeirarchy(3)
    }, [])


    const handleChange = (event) => {
        setPartnerData({
            ...partnerData,
            [event.target.name]: event.target.value,
        });
    };

    const sameAddress = () => {
        if(!billAddress){
            setBillAddress(true)
            partnerData.billToAddress1= partnerData.address1
            partnerData.billToAddress2= partnerData.address2
            partnerData.billToAddress3= partnerData.address3
            partnerData.billToPincode= partnerData.pincode
            partnerData.billToSmRid= partnerData.smRid
            partnerData.billToStateName= partnerData.stateName
        }
        else{
            setBillAddress(false)
            partnerData.billToaddress1= ''
            partnerData.billToaddress2= ''
            partnerData.billToaddress3= ''
            partnerData.billToPincode= ''
            partnerData.billToSmRid= ''
            partnerData.billToStateName= ''
        }
    }

    const getHeirarchy = (getSh) => {
        getSalesHierarchy(getSh)
            .then(data => {
                setIsLoading(false)
                setSalesHierarchyList(data)
            })
    }

    const handleStates = (event, key) => {
        let stateVal = event.value.split('/');
        let stateId = stateVal[0];
        let stateName = stateVal[1]

        setPartnerData({
            ...partnerData,
            ['smRid']: stateId,
            ['stateName']: stateName,

        });
    };
    const handleBillingStates = (event, key) => {
        let stateVal = event.value.split('/');
        let stateId = stateVal[0];
        let stateName = stateVal[1]

        setPartnerData({
            ...partnerData,
            ['billToSmRid']: stateId,
            ['billToStateName']: stateName,

        });
    };
    const [isSubmit, setIsSubmit] = React.useState(false);

    const handleToggle = (statusVal, event) => {
        let status = (statusVal == 1 ? 0 : 1)
        setPartnerData({
            ...partnerData,
            [event.target.name]: status,
        });
    }

    useEffect(() => {
        window.onpopstate = e => {
            window.location.reload()
        }
    })

    const getCategory = async () => {
        getCategoryList()
            .then(data => {
                if (data) {
                    setCategoryList(data)
                }
            }).catch(e => {
                openSnackbar(e)
            })
    };

    const getState = async () => {
        getStateList()
            .then(data => {
                if (data) {
                    setStateList(data)
                }
            }).catch(e => {
                openSnackbar(e)
            })
    };

    const savePartner = () => {
        // console.log(partnerData)

        if (!partnerData.partShortName) {
            setAlertMsg("Please enter patner short name")
            setShowAlert(true)
            return
        }
        if (!partnerData.partName) {
            setAlertMsg("Please enter patner name")
            setShowAlert(true)
            return
        }
        if (!partnerData.partCode) {
            setAlertMsg("Please enter patner code")
            setShowAlert(true)
            return
        } if (!partnerData.contactPerson) {
            setAlertMsg("Please enter contact person name")
            setShowAlert(true)
            return
        }
        if (!partnerData.email) {
            setAlertMsg("Please enter valid email")
            setShowAlert(true)
            return
        }
        if (!partnerData.mobile1) {
            setAlertMsg("Please enter mobile number")
            setShowAlert(true)
            return
        }
        if (!partnerData.gst) {
            setAlertMsg("Please enter GST ")
            setShowAlert(true)
            return
        } if (!partnerData.pan) {
            setAlertMsg("Please enter PAN number")
            setShowAlert(true)
            return
        }
        if (!partnerData.address1) {
            setAlertMsg("Please enter address")
            setShowAlert(true)
            return
        }
        if (!partnerData.pincode) {
            setAlertMsg("Please enter pincode")
            setShowAlert(true)
            return
        }
        if (!partnerData.country) {
            setAlertMsg("Please enter country name")
            setShowAlert(true)
            return
        }
        setIsSubmit(true)
        savePartnerData(partnerData)
            .then((desc) => {
                if (desc) {
                    openSnackbar("Partner details been updated")

                }
                setIsSubmit(false)
                getVendorList()
                dismissAlert()
                onModalClose()
            }).catch(e => {
                setIsSubmit(false)
                openSnackbar(e)
                dismissAlert()
                onModalClose()
            })

    }
    const dismissAlert = () => {
        setAlertMsg(''); setShowAlert(false)
    }


    return (
        <ThemeProvider theme={theme}>
            <div className="modal fade custom-modal m-backdrop" id="addNewVendorModal"
                data-bs-backdrop="false" tabIndex="-1" aria-labelledby="VendorAddModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-lg max-w-600 modal-dialog-centered modal-dialog-scrollable">
                    <div className="modal-content">
                        <div className="modal-header" style={{ borderBottom: 0 }}>

                            <h1 className="fs-normal-semibold text-primary my-auto">Add</h1>

                            <button type="button" className=" float-end px-0 my-auto custom-btn" data-bs-dismiss="modal" aria-label="Close" onClick={onModalClose}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-x-circle float-end text-primary" viewBox="0 0 16 16">
                                    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                                    <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                                </svg>
                            </button>

                        </div>
                        <div className="modal-body p-0">
                            <div className="container px-2rem pt-0 vendor-modal-body">

                                <div className=" row mx-0 mt-2">
                                    <div className="col-md-6">
                                        <label className="fs-normal-reg mb-2">Partner short name</label>
                                        <input
                                            type="text"
                                            className="form-control login-input p-07rem mb-3 w-100 fs-large-med px-4"
                                            onChange={handleChange}
                                            name="partShortName" value={partnerData.partShortName}
                                        />
                                    </div>
                                    <div className="col-md-6">
                                        <label className="fs-normal-reg mb-2">Partner name</label>
                                        <input
                                            type="text"
                                            className="form-control login-input p-07rem mb-3 w-100 fs-large-med px-4"
                                            onChange={handleChange}
                                            name="partName" value={partnerData.partName}
                                        />
                                    </div>
                                    <div className="col-md-6">
                                        <label className="fs-normal-reg mb-2">Partner code</label>
                                        <input
                                            type="text"
                                            className="form-control login-input p-07rem mb-3 w-100 fs-large-med px-4"
                                            onChange={handleChange}
                                            name="partCode" value={partnerData.partCode}
                                        />
                                    </div>
                                    <div className="col-md-6">
                                        <label className="fs-normal-reg mb-2">Partner category</label>
                                        <Select className='mb-3 fs-large-med'
                                            aria-label="Default select example"
                                            defaultValue={categoryList[0]}
                                            options={categoryList?.map(item => ({
                                                label: item.category , value: item.pcRid,
                                            }))}
                                            onChange = {opt => (partnerData.partCategory=opt.value)}
                                        /> 
                                    </div>
                                    <div className="col-md-6">
                                        <label className="fs-normal-reg mb-2">Contact person</label>
                                        <input
                                            type="text"
                                            className="form-control login-input p-07rem mb-3 w-100 fs-large-med px-4"
                                            onChange={handleChange}

                                            name="contactPerson" value={partnerData.contactPerson}
                                        />
                                    </div>
                                    <div className="col-md-6">
                                        <label className="fs-normal-reg mb-2">Email</label>
                                        <input
                                            type="text"
                                            className="form-control login-input p-07rem mb-3 w-100 fs-large-med px-4"
                                            onChange={handleChange}

                                            name="email" value={partnerData.email}
                                        />
                                    </div>
                                    <div className="col-md-6">
                                        <label className="fs-normal-reg mb-2">Mobile1</label>
                                        <input
                                            type="text"
                                            className="form-control login-input p-07rem mb-3 w-100 fs-large-med px-4"
                                            onChange={handleChange}
                                            maxLength={10}
                                            name="mobile1" value={partnerData.mobile1}
                                        />
                                    </div>
                                    <div className="col-md-6">
                                        <label className="fs-normal-reg mb-2">Mobile2</label>
                                        <input
                                            type="text"
                                            className="form-control login-input p-07rem mb-3 w-100 fs-large-med px-4"
                                            onChange={handleChange}
                                            maxLength={10}
                                            name="mobile2" value={partnerData.mobile2}
                                        />
                                    </div>
                                    {/* <div className="col-md-6">
                                    <label className="fs-normal-reg mb-2">Location</label>
                                    <input
                                        type="text"
                                        className="form-control login-input p-07rem mb-3 w-100 fs-large-med px-4"
                                        onChange={handleChange}
                                        name="partName" value={partnerData.partName}
                                    />
                                </div> */}
                                    <div className="col-md-6">
                                        <label className="fs-normal-reg mb-2">GST</label>
                                        <input type="text"
                                            className="form-control login-input p-07rem mb-3 w-100 fs-large-med px-4"
                                            onChange={handleChange}
                                            name="gst" value={partnerData.gst}
                                        />
                                    </div>
                                    <div className="col-md-6">
                                        <label className="fs-normal-reg mb-2">PAN</label>
                                        <input
                                            type="text"
                                            className="form-control login-input p-07rem mb-3 w-100 fs-large-med px-4"
                                            onChange={handleChange}
                                            name="pan" value={partnerData.pan}
                                        />
                                    </div>
                                    <hr></hr>
                                    <div classNamne="col-md-12">
                                        <p className="fs-large-reg">Shipping Address</p>
                                    </div>
                                    <div className="col-md-6">
                                        <label className="fs-normal-reg mb-2">Address 1</label>
                                        <textarea
                                            className="form-control login-input p-07rem mb-3 w-100 fs-large-med px-4"
                                            onChange={handleChange}
                                            name="address1" value={partnerData.address1}
                                        >
                                            {partnerData.address1}
                                        </textarea>
                                    </div>

                                    <div className="col-md-6">
                                        <label className="fs-normal-reg mb-2">Address 2</label>
                                        <textarea
                                            className="form-control login-input p-07rem mb-3 w-100 fs-large-med px-4"
                                            onChange={handleChange}

                                            name="address2" value={partnerData.address2}
                                        >
                                            {partnerData.address1}
                                        </textarea>
                                    </div>

                                    <div className="col-md-6">
                                        <label className="fs-normal-reg mb-2">Address 3</label>
                                        <textarea
                                            className="form-control login-input p-07rem mb-3 w-100 fs-large-med px-4"
                                            onChange={handleChange}

                                            name="address3" value={partnerData.address3}
                                        >
                                            {partnerData.address1}
                                        </textarea>
                                    </div>
                                    <div className="col-md-6 d-flex justify-content-center flex-column">
                                        <label className="fs-normal-reg mb-2">State</label>
                                        <Select className='mb-3 fs-large-med'
                                            aria-label="Default select example"
                                            options={stateList?.map(item => ({
                                                label: item.stateName , value: item.smRid+"/"+item.stateName,
                                            }))}
                                            onChange = {e => handleStates(e)}
                                        /> 
                                    </div>
                                    <div className="col-md-6">
                                        <label className="fs-normal-reg mb-2">Pincode</label>
                                        <input
                                            type="text"
                                            className="form-control login-input p-07rem mb-3 w-100 fs-large-med px-4"
                                            onChange={handleChange}

                                            name="pincode" value={partnerData.pincode}
                                        />
                                    </div>
                                    <hr></hr>
                                    <div className="col-md-6">
                                        <p className="fs-large-reg">Billing Address</p>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-check">
                                            <input className="form-check-input" type="checkbox" onChange={sameAddress} id="address"/>
                                            <label className="form-check-label fs-normal-reg" htmlFor="address">
                                                Same as Shipping Address
                                            </label>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <label className="fs-normal-reg mb-2">Address 1</label>
                                        <textarea
                                            className="form-control login-input p-07rem mb-3 w-100 fs-large-med px-4"
                                            onChange={handleChange}
                                            name="billToAddress1" value={partnerData.billToAddress1}
                                        >
                                            {partnerData.billToAddress1}
                                        </textarea>
                                    </div>

                                    <div className="col-md-6">
                                        <label className="fs-normal-reg mb-2">Address 2</label>
                                        <textarea
                                            className="form-control login-input p-07rem mb-3 w-100 fs-large-med px-4"
                                            onChange={handleChange}

                                            name="billToAddress2" value={partnerData.billToAddress2}
                                        >
                                            {partnerData.billToAddress2}
                                        </textarea>
                                    </div>

                                    <div className="col-md-6">
                                        <label className="fs-normal-reg mb-2">Address 3</label>
                                        <textarea
                                            className="form-control login-input p-07rem mb-3 w-100 fs-large-med px-4"
                                            onChange={handleChange}

                                            name="billToAddress3" value={partnerData.billToAddress3}
                                        >
                                            {partnerData.billToAddress3}
                                        </textarea>
                                    </div>
                                    <div className="col-md-6 d-flex justify-content-center flex-column">
                                        <label className="fs-normal-reg mb-2">State</label>
                                        <Select className='mb-3 fs-large-med'
                                            defaultValue={partnerData.billToStateName}
                                            placeholder={partnerData.billToStateName}
                                            aria-label="Default select example"
                                            options={stateList?.map(item => ({
                                                label: item.stateName , value: item.smRid+"/"+item.stateName,
                                            }))}
                                            onChange = {e => handleBillingStates(e)}
                                        /> 
                                    </div>
                                    <div className="col-md-6">
                                        <label className="fs-normal-reg mb-2">Pincode</label>
                                        <input
                                            type="text"
                                            className="form-control login-input p-07rem mb-3 w-100 fs-large-med px-4"
                                            onChange={handleChange}

                                            name="billToPincode" value={partnerData.billToPincode}
                                        />
                                    </div>
                                    <hr></hr>
                                    <div className="col-md-6">
                                        <label className="fs-normal-reg mb-2">Country</label>
                                        <input
                                            type="text"
                                            className="form-control login-input p-07rem mb-3 w-100 fs-large-med px-4"
                                            onChange={handleChange}

                                            name="country" value={partnerData.country}
                                        />
                                    </div>

                                    <div className="col-md-6">
                                        <label className="fs-normal-reg mb-2">Sales Officer</label>
                                        <Select className='col-md-12 col-12 mb-4'
                                            aria-label="Default select example"
                                            maxMenuHeight={100}
                                            options={salesHierarchyList.map(item => ({
                                                label: item.fullName , value: item.shRid,
                                            }))}
                                            onChange = {opt => (partnerData.shRid=opt.value)}
                                        /> 
                                    </div>

                                    <div className="col-md-6  ">
                                        <label className="fs-normal-reg mb-2">Status</label>
                                        <div className="d-flex flex-row  custom-checkbox">
                                            <FormControlLabel
                                                control={<MaterialUISwitch
                                                    checked={+partnerData?.status === 1} onChange={(e) => { handleToggle(partnerData.status, e) }} name="status" />}
                                                label={partnerData.status == 1 ? "Active" : 'In Active'}
                                                className="fs-small-reg" />

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {showAlert ?
                            <div className="alert alert-dismissible  fade show text-primary text-center fs-normal-med bg-transparent" role="alert">
                                {alertMsg}
                                <button type="button" className="  ms-5 px-0 custom-btn my-auto" data-bs-dismiss="alert" aria-label="Close" onClick={() => { dismissAlert() }}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-x-circle  text-primary" viewBox="0 0 16 16">
                                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                                        <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                                    </svg>
                                </button>
                            </div>
                            : null}


                        <div className="modal-footer pb-0">
                            <button type="button" className="btn btn-secondary fs-large-med me-3" data-bs-dismiss="modal" onClick={onModalClose}>Cancel</button>
                            {isSubmit ? <button className="btn btn-primary br-21 fs-large-semibold text-light px-5 py-2">
                                <div className="spinner-border text-success spinner-border-sm" role="status"></div>Save changes</button> :
                                <button onClick={() => { savePartner() }} className="btn btn-primary br-21 fs-large-semibold text-light px-5 py-2">Save changes</button>}
                        </div>


                    </div>
                </div>
            </div>
        </ThemeProvider>
    )



}
export default VendorAddModal