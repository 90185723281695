import React, { useEffect, useState } from 'react'
import Select from 'react-select';
import { getPartnerList, getAdminProductList, confirmAdminOrder } from '../../../api/API';
import { toPrefixedLocalePrice } from '../../../util/Util';
import { CustomOptions } from '../../user/login/Login';
import { useSnackbar } from 'react-simple-snackbar';
import './order.scss'

const AddOrderModal = () => {
    const [partnerList, setPartnerList] = useState(null)
    const [openSnackbar] = useSnackbar(CustomOptions)
    const [partner, setPartner] = useState()
    const [partnerCode, setPartnerCode] = useState()
    const [prodList, setProdList] = useState()
    const [itemCode, setItemCode] = useState()
    const [itemName, setItemName] = useState()
    const [paymentRef, setPaymentRef] = useState('')
    const [paymentNote, setPaymentNote] = useState('')
    const [orderQty, setOrderQty] = useState()
    const [cartList, setCartList] = useState([])
    const [dispalyOrderList, setDisplayOrderList] = useState([])
    const [totalPrice, setTotalPrice] = useState(0)
    const [basePrice, setBasePrice] = useState(0)
    const [gstPrice, setGstPrice] = useState(0)
    const [error, setError] = useState('')
    const [avaialbleQty, setAvailableQty] = useState(0)

    useEffect(() => {
        console.log(cartList, dispalyOrderList)
        getPartner()
    }, [])

    useEffect(() => {
    }, [partner], [cartList])


    const getProductList = () => {
        getAdminProductList(partnerCode)
            .then((response) => {
                setProdList(response.data)
            }).catch((e) => {
                openSnackbar(e)
            })
    }

    const getPartner = () => {
        getPartnerList()
            .then((data) => {
                setPartnerList(data)
            })
            .catch((e) => {
                openSnackbar(e)
            })
    }

    const addItem = (itemCode, itemName) => {
        prodList.filter((item) => item.skuRid !== itemCode)
        const result = prodList.find((item) => item.skuRid === itemCode)
        if (result.stock < orderQty) {
            openSnackbar('Not Enough quantity in Stock')
            setError('Not Enough quantity in stock')
            setTimeout(forceRefresh, 2000)
        }
        else if (orderQty % 4 !== 0) {
            openSnackbar('Invalid Qty')
            setError('Invalid Qty')
            setTimeout(forceRefresh, 2000)
        }
        else if (!cartList.find((item) => item.skuRid === itemCode)) {
            setCartList([...cartList, { skuRid: itemCode, qty: orderQty }])
            setOrderQty('')
            setDisplayOrderList([...dispalyOrderList, { result, qty: orderQty }])
            setTotalPrice(totalPrice + result.price * orderQty)
            setBasePrice(basePrice + result.basePrice * orderQty)
            setGstPrice(gstPrice + result.gst * orderQty)
        }
        else {
            openSnackbar('Item Already in List')
            setError('Item Already in List')
            setTimeout(forceRefresh, 2000)
        }
    }

    const deleteItem = (skuRid) => {
        const result = dispalyOrderList.filter((item) => item.result.skuRid === skuRid)
        // console.log(result[0]);
        setTotalPrice(totalPrice - result[0].result.price * result[0].qty)
        setBasePrice(basePrice - result[0].result.basePrice * result[0].qty)
        setGstPrice(gstPrice - result[0].result.gst * result[0].qty)
        setDisplayOrderList(dispalyOrderList.filter((item) => item.result.skuRid !== skuRid))
        setCartList(cartList.filter((item) => item.skuRid !== skuRid))
    }

    const saveAdminOrder = () => {
        // console.log(cartList, dispalyOrderList, paymentNote, paymentRef)
        confirmAdminOrder({ paymentRefNo: paymentRef, paymentNotes: paymentNote, partRid: partnerCode, adminCartList: cartList })
            .then((response) => {
                openSnackbar('Order ' + response.data.orderId + ' Saved Successfully')
            }).catch(e => openSnackbar(e))
        setTimeout(forceRefresh, 3000)
    }

    const forceRefresh = () => {
        setError('')
    }

    const addPartner = () => {
        getProductList()
    }

    const getQtyPrice = (skuRid) => {
        // const result = prodList.filter((item) => item.skuRid === skuRid)
        setAvailableQty(prodList.filter((item) => item.skuRid === skuRid))
    }

    const clearValues = () => {
        setDisplayOrderList([])
        setCartList([])
        setPaymentNote('')
        setPaymentRef('')
        setTotalPrice(0)
        setBasePrice(0)
        setGstPrice(0)
        document.getElementById('inputNote').value = ''
        document.getElementById('inputRef').value = ''
    }

    return (
        <>
            <div className="modal fade" data-bs-backdrop="static" id="addOrder" tabindex="-1" aria-labelledby="addOrder" aria-hidden="true">
                <div data-bs-backdrop="static" className="modal-dialog modal-lg modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Add Order</h5>
                        </div>
                        <div className="modal-body">
                            <div className='col-md-12 d-flex justify-content-around align-items-center'>
                                <div className='col-md-6'>
                                    <label className="fs-normal-reg mb-2 ">Partner</label>
                                    <Select id='partnerSelect' className='mb-3 fs-large-med'
                                        aria-label="Default select example"
                                        defaultValue=''
                                        placeholder="Search"
                                        isClearable={true}
                                        isSearchable={true}
                                        options={partnerList?.map(item => ({
                                            label: (item.partName + ' - ' + item.partCode), value: item.partRid,
                                        }))}
                                        onChange={(e) => { setPartnerCode(e.value); setPartner(e.label); }}
                                    />
                                </div>
                                <div className='col-md-4'>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                            <button data-bs-backdrop="static" data-bs-keyboard="false" data-bs-target="#addModal2" data-bs-toggle="modal" onClick={() => { addPartner() }} className="btn btn-success rounded-bg fs-normal-semibold text-white px-5 py-2">Add</button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="modal fade" id="addModal2" data-bs-keyboard="false" data-bs-backdrop="static" aria-hidden="true" aria-labelledby="exampleModalToggleLabel2" tabindex="-1">
                <div data-bs-keyboard="false" data-bs-backdrop="static" className="modal-dialog modal-lg modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalToggleLabel2">Add Order</h5>
                        </div>
                        <div className="modal-body">
                            <div className='col-md-12 d-flex justify-content-around align-items-center'>
                                <div className='col-md-5'>
                                    <label className="fs-normal-reg mb-2 ">Partner</label>
                                    <Select className='mb-3 fs-large-med'
                                        aria-label="Default select example"
                                        placeholder={partner}
                                        options={partner}
                                        isDisabled={true}
                                    />
                                </div>
                                <div className='col-md-5'>
                                </div>
                            </div>
                            <div className='col-md-12 d-flex justify-content-around align-items-center'>
                                <div className='col-md-6 mb-3 ms-2'>
                                    <label className="fs-normal-reg">Payment Reference No:</label>
                                    <input id='inputRef' type="text" onChange={(e) => { setPaymentRef(e.target.value) }} className="fs-x-large-regular  custom-add-border mt-2 rounded py-1 px-2 w-100" placeholder="Enter Ref. No." />
                                </div>
                                <div className='col-md-5 mb-3'>
                                    <label className="fs-normal-reg">Payment Notes</label>
                                    <input id='inputNote' type="text" onChange={(e) => { setPaymentNote(e.target.value) }} className="fs-x-large-regular  custom-add-border mt-2 rounded py-1 px-2 w-100" placeholder="Enter Notes" />
                                </div>
                            </div>
                            <div className='col-md-12 d-flex align-items-center mb-3'>
                                <div className='col-md-6 mx-4 d-flex flex-column mt-2'>
                                    <label className="fs-normal-reg mb-2 ">Item</label>
                                    <Select id='itemSelect' className='fs-large-med'
                                        aria-label="Default select example"
                                        defaultValue=''
                                        placeholder="Select Items"
                                        options={prodList?.map(item => ({
                                            label: (item?.title + ' - ' + item.skuCode), value: item?.skuRid
                                        }))}
                                        onChange={(e) => { setItemCode(e.value); setItemName(e.label); getQtyPrice(e.value) }}
                                    />
                                    {avaialbleQty.length > 0 ?
                                        <p className='text-danger fs-normal-reg m-0 ms-2'>
                                            Avl. Qty: <span className='fs-normal-semibold'>{avaialbleQty[0].stock}</span> - Price: <span className='fs-normal-semibold'>{avaialbleQty[0].basePrice}</span>
                                        </p>
                                        : null}
                                </div>
                                <div className='col-md-3 mb-3 me-3'>
                                    <label className="fs-normal-reg">Quantity</label>
                                    <input type="text" value={orderQty} onChange={(e) => { setOrderQty(e.target.value) }} pattern="[0-9]*" className="fs-x-large-regular  custom-add-border mt-2 rounded py-1 px-2 w-100" placeholder="Enter Quantity" />
                                </div>
                                <div className='col-md-1 mt-3 '>
                                    <button onClick={() => { addItem(itemCode, itemName) }} className="text-white btn btn-primary">+</button>
                                </div>
                                <hr></hr>
                            </div>
                            {
                                dispalyOrderList.length > 0 ?
                                    <>
                                        <div className="col-md-12 col-12 mb-3">
                                            <div className="d-flex ms-4 ps-2 flex-row ">
                                                <p className="col-md-5 fs-large-semibold mb-1">
                                                    Product Name
                                                </p>
                                                <p className="col-md-1 fs-large-semibold mb-1">
                                                    Qty
                                                </p>
                                                <p className="col-md-2 fs-large-semibold mb-1">
                                                    Price
                                                </p>
                                            </div>
                                        </div>
                                    </> : null
                            }

                            {
                                dispalyOrderList !== null ?
                                    dispalyOrderList.map((item) => (
                                        <>
                                            <div className="col-md-12 col-12">
                                                <div className="d-flex ms-4 ps-2 flex-row ">
                                                    <p className="col-md-5 fs-large-regular mb-1">
                                                        {item.result.title}
                                                    </p>
                                                    <p className="col-md-1 fs-large-regular mb-1">
                                                        {item.qty}
                                                    </p>
                                                    <p className="col-md-2 fs-large-regular mb-1">
                                                        {toPrefixedLocalePrice(item.result.basePrice * item.qty)}
                                                    </p>
                                                    <div onClick={() => { deleteItem(item.result.skuRid) }} className="col-md-1 text-end col-sm-1 pointer">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-trash text-danger ms-2" viewBox="0 0 16 16">
                                                            <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                                                            <path fill-rule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z" />
                                                        </svg>
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    )) : null
                            }
                            {
                                dispalyOrderList.length > 0 ?
                                    <>
                                        <div className="col-md-12 col-12 mt-4">
                                            <div className="d-flex ms-4 ps-2 flex-column ">
                                                <p className="col-md-5 fs-large-semibold mb-1">
                                                    Base Price: {toPrefixedLocalePrice(basePrice)}
                                                </p>
                                                <p className="col-md-5 fs-large-semibold mb-1">
                                                    Gst Price: {toPrefixedLocalePrice(gstPrice)}
                                                </p>
                                                <p className="col-md-5 fs-l-large-semibold mb-1">
                                                    Total Price: {toPrefixedLocalePrice(totalPrice)}
                                                </p>
                                            </div>
                                        </div>
                                    </> : null
                            }

                        </div>
                        <div className="modal-footer">
                            {
                                error !== '' ?
                                    <p className='fs-large-regular text-danger text-center col-md-8 m-0'>
                                        {error}
                                    </p> : null
                            }
                            <button type="button" onClick={() => window.location.reload()} className="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
                            <button onClick={() => saveAdminOrder()} data-bs-dismiss="modal" className="text-white btn btn-primary">Save Changes</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AddOrderModal