import React, { useEffect } from "react";
import { Dropdown, } from 'bootstrap';
import { Link } from "react-router-dom";
import ProfileLogo from '../../../assets/profile.svg';
import { color } from "@mui/system";
import FormControlLabel from '@mui/material/FormControlLabel';
import { Switch, Checkbox } from '@mui/material';
import { ShimmerOrders, ShimmerOrdersDetailTable } from "../../../components/shimmer-effect/ShimmerEffect";
import { displayOrderId, toPrefixedLocalePrice } from "../../../util/Util";
;
const OrderDetailsModal = ({ orderDetailsData, item }) => {

    useEffect(() => {
        window.onpopstate = e => {
            window.location.reload()
        }
    })
    return (
        orderDetailsData ? (
            <div className="modal fade custom-modal" id="modalOrderDetails" tabIndex="-1" aria-labelledby="vendorEditAddModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-lg max-w-600 modal-dialog-centered modal-dialog-scrollable">
                    <div className="modal-content">
                        <div className="modal-body px-0">
                            <div className="container  p-2rem">
                                <div className="row">
                                    <div className="col-md-12 my-auto mb-2">
                                        <p className="my-auto  float-start fs-large-med">
                                            {displayOrderId(item)}
                                        </p>

                                        <button type="button" className=" float-end px-0 my-auto custom-btn" data-bs-dismiss="modal" aria-label="Close">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-x-circle float-end text-primary" viewBox="0 0 16 16">
                                                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                                                <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                                            </svg>
                                        </button>
                                    </div>
                                    <table className="table table-borderless">
                                        <thead>
                                            <tr className="text-grey fs-large-med">
                                                <td scope="col">Item name</td>
                                                <td scope="col">Quantity</td>
                                                <td scope="col">Price</td>
                                                <td scope="col">Total</td>
                                            </tr>
                                        </thead>

                                        <tbody>
                                            {orderDetailsData.length !== null ?
                                                orderDetailsData.map((item, index) => (
                                                    <tr className="text-dark fs-large-med">
                                                        <td scope="row">{item.title}</td>
                                                        <td className="text-center">{item.qty}</td>
                                                        <td>{toPrefixedLocalePrice(item.price)}</td>
                                                        <td>{toPrefixedLocalePrice(item.netAmount)}</td>
                                                    </tr>
                                                )) : <tr className="align-middle fs-large-med align-self-center justify-content-center text-center" >
                                                    <td>No records</td>
                                                </tr>}
                                        </tbody>
                                    </table>
                                </div>
                            </div>


                        </div>
                    </div>
                </div>
            </div>
        ) : (<ShimmerOrdersDetailTable />)
    )



}
export default OrderDetailsModal