import React, { useEffect, useState } from 'react'
import './search.scss'
import SearchMenu from '../../../components/search-dropdown/search-menu';
import UserLayout from '../../../UserLayout';
import { Modal } from 'bootstrap'
import FilterModal from '../order/FilterModal'

const Search = () => {

    const filterModal = async () => {
        const openFilterModal = new Modal(document.querySelector('#filterModal'), {
            keyboard: false
        })
        openFilterModal.show()
    }

    return (
        <UserLayout>
            <FilterModal />
            <div className="container-fluid   align-self-center">
                <div className="row justify-content-center">

                    <div className="container my-md-200 d-block d-md-none d-lg-none">
                        <div className="row justify-content-center">

                            <div className="search-bar d-flex flex-row justify-content-evenly">
                                <SearchMenu />
                                <button className="btn btn-box btn-outline-success ms-3 px-3 py-0" onClick={() => {filterModal()}}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-funnel text-green" viewBox="0 0 16 16">
                                        <path d="M1.5 1.5A.5.5 0 0 1 2 1h12a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-.128.334L10 8.692V13.5a.5.5 0 0 1-.342.474l-3 1A.5.5 0 0 1 6 14.5V8.692L1.628 3.834A.5.5 0 0 1 1.5 3.5v-2zm1 .5v1.308l4.372 4.858A.5.5 0 0 1 7 8.5v5.306l2-.666V8.5a.5.5 0 0 1 .128-.334L13.5 3.308V2h-11z"/>
                                    </svg>
                                </button>
                            </div>

                        </div>

                    </div>

                </div>
            </div>

        </UserLayout>

    )
}
export default Search