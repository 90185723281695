import React, { useState } from 'react'
import backGround from '../../../assets/banner1.svg'
import { Link } from 'react-router-dom'
import { setAuthToken } from '../../../util/LocalStorageUtil';
import { useNavigate } from 'react-router'

const ForgotPassword = () => {
    const navigate = useNavigate()

    const goToHomePage = () => {
        setAuthToken("abcddjgdsdj");
        navigate('/dashboard');
    }
    return (
        <>
            <img className="banner" src={backGround} alt="banner" />
            <div className="container  mt-md-50">
                <div className="row justify-content-center">
                    <div className="col-md-4 p-2rem my-auto login-container col-11">
                        <div className="flex-row d-flex mb-3">

                            <a className="a-nonlink" href="/otp">
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-arrow-left my-auto" viewBox="0 0 16 16">
                                    <path fillRule="evenodd" d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z" />
                                </svg>
                            </a>
                            
                            <div className="ms-4 my-auto"><span className="text-center fs-l-large-regular">Create password</span></div>
                        </div>

                        <input type="password" className="form-control login-input p-07rem my-4 w-100 fs-x-large-regular px-4" placeholder="New password" />
                        <input type="password" className="form-control login-input p-07rem my-4 w-100 fs-x-large-regular px-4" placeholder="Re-type new password" />
                        <div >
                            <button type="btn" onClick={() => { goToHomePage() }} className="btn bg-green btn-focus br-21 p-07rem  w-100 text-light fs-large-semibold">Save changes</button>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}
export default ForgotPassword