import Shimmer from "../shimmer/Shimmer"

export const ShimmerHome = () => {
    return (
        <div className="container-fluid">
            <div className="row justify-content-center">
                {  /*<div className="w-100  rounded px-0" style={{ top: 0, position: 'fixed' }}>
                    <Shimmer className="rounded" height={65} />
    </div> */}
                <div className="my-md-100 z-ind-0   col-md-8">
                    <div className="row justify-content-between ">
                        <div className=" rounded  text-center  mb-5">
                            <Shimmer className="rounded" height={200} />
                        </div>
                        <div className="col-md-3 mb-3 col-6">

                            <Shimmer className="rounded" height={200} />
                        </div>
                        <div className="col-md-3 mb-3 col-6">

                            <Shimmer className="rounded" height={200} />
                        </div>
                        <div className="col-md-3 mb-3 col-6 d-none d-md-block">
                            <Shimmer className="rounded" height={200} />
                        </div>

                    </div>
                </div>

                {  /*  <div className="w-100  rounded px-0" style={{ bottom: 0, position: 'fixed' }}>
                    <Shimmer className="rounded" height={65} />
                </div> */}
            </div>
        </div>
    )
}
export const ShimmerProduct = () => {
    return (
        <div className="container-fluid">
            <div className="row justify-content-center">
                <div className="my-md-100 z-ind-0   col-md-10">
                    <div className="row justify-content-md-between justify-content-center">
                        <div className="col-md-2 rounded  text-center  mb-5  d-none d-md-block d-lg-block">
                            <Shimmer className="rounded" height={100} />
                            <Shimmer className="rounded mt-3" height={100} />
                            <Shimmer className="rounded mt-3" height={100} />

                        </div>
                        <div className="col-md-3 mb-3 col-11 my-auto">

                            <Shimmer className="rounded" height={200} />
                        </div>
                        <div className="col-md-7 mb-3 col-11">
                            <Shimmer className="rounded " height={30} />
                            <Shimmer className="rounded mt-3" height={30} />
                            <Shimmer className="rounded mt-3" height={30} />
                            <div className="col-md-5">
                                <Shimmer className="rounded mt-3" height={30} />
                            </div>
                            <div className="col-md-7">
                                <Shimmer className="rounded mt-3" height={30} />
                            </div>
                            <Shimmer className="rounded mt-3" height={30} />
                            <Shimmer className="rounded mt-3" height={30} />
                        </div>

                        <div className="row justify-content-between mt-5">
                            <div className="col-md-3 mb-3 col-6 ">

                                <Shimmer className="rounded" height={200} />
                            </div>
                            <div className="col-md-3 mb-3 col-6">

                                <Shimmer className="rounded" height={200} />
                            </div>
                            <div className="col-md-3 mb-3 col-6">

                                <Shimmer className="rounded" height={200} />
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}
export const ShimmerCart = () => {
    return <div className="container-fluid">
        <div className="row justify-content-center">
            <div className="my-md-100 z-ind-0   col-md-7 mt-5" >
                {Array.from(Array(5), (e, i) => {
                    return <div className="row justify-content-md-between justify-content-center mb-5" key={i}>
                        <div className="col-md-2 rounded  text-center  mb-5 col-3">
                            <Shimmer className="rounded" height={100} />
                        </div>

                        <div className="col-md-6 mb-3 col-7">
                            <Shimmer className="rounded " height={30} />

                            <div className="col-md-5">
                                <Shimmer className="rounded mt-3" height={30} />
                            </div>
                            <div className="col-md-7">
                                <Shimmer className="rounded mt-3" height={30} />
                            </div>
                            <Shimmer className="rounded mt-3" height={30} />

                        </div>
                        <div className="col-md-1 mb-3 col-2 ">

                            <Shimmer className="rounded mt-3" height={30} />
                        </div>
                        <div className="col-md-3 mb-3 col-11 d-none d-md-block ">
                            <Shimmer className="rounded " height={30} />
                            <Shimmer className="rounded mt-3" height={30} />
                        </div>

                    </div>
                })} </div>
        </div>
    </div>
}
export const ShimmerOrders = () => {
    return <div className="container-fluid">
        <div className="row justify-content-center">
            <div className="my-md-100 z-ind-0   col-md-7 mt-5" >
                {Array.from(Array(5), (e, i) => {
                    return <div className="row justify-content-md-between justify-content-center mb-5">
                        <div className="col-md-2 rounded  text-center  mb-5 col-3">
                            <Shimmer className="rounded" height={100} />
                        </div>

                        <div className="col-md-6 mb-3 col-7">
                            <Shimmer className="rounded " height={30} />

                            <div className="col-md-5">
                                <Shimmer className="rounded mt-3" height={30} />
                            </div>
                            <div className="col-md-7">
                                <Shimmer className="rounded mt-3" height={30} />
                            </div>
                            <Shimmer className="rounded mt-3" height={30} />

                        </div>
                        <div className="col-md-1 mb-3 col-2 ">

                            <Shimmer className="rounded mt-3" height={30} />
                        </div>
                        <div className="col-md-3 mb-3 col-11 d-none d-md-block ">
                            <Shimmer className="rounded " height={30} />
                            <Shimmer className="rounded mt-3" height={30} />
                        </div>

                    </div>
                })} </div>
        </div>
    </div>
}


export const ShimmerOrdersTable = () => {
    return <tbody>
        {Array.from(Array(5), (e, i) => {
            return <tr className="align-middle" key={i}>
                <td scope="row " ><Shimmer className="rounded " height={25} /></td>
                <td scope="row " ><Shimmer className="rounded " height={25} /></td>
                <td scope="row " ><Shimmer className="rounded " height={25} /></td>
                <td scope="row " ><Shimmer className="rounded " height={25} /></td>
                <td scope="row " ><Shimmer className="rounded " height={25} /></td>
                <td scope="row " ><Shimmer className="rounded " height={25} /></td>
                <td scope="row " ><Shimmer className="rounded " height={25} /></td>

            </tr>
        })}
    </tbody>
}

export const ShimmerOrdersDetailTable = () => {
    return <tbody>
        {Array.from(Array(3), (e, i) => {
            return <tr className="align-middle" >
                <td scope="row " ><Shimmer className="rounded " height={10} /></td>
                <td scope="row " ><Shimmer className="rounded " height={10} /></td>
                <td scope="row " ><Shimmer className="rounded " height={10} /></td>
                <td scope="row " ><Shimmer className="rounded " height={10} /></td>
            </tr>
        })}
    </tbody>
}



export const ShimmerInventory = () => {
    return <div className="container-fluid">
        <div className="row justify-content-center">
            <div className=" z-ind-0   col-md-10 mt-5" >
                <div className="col-md-3 rounded  text-center  mb-5 col-3">
                    <Shimmer className="rounded" height={40} />
                </div>
                {Array.from(Array(5), (e, i) => {
                    return <div className="row  mb-5" key={i}>

                        <div className="col-md-2 mb-3">
                            <Shimmer className="rounded mt-3" height={40} />
                        </div>
                        <div className="col-md-5">
                            <Shimmer className="rounded mt-3" height={40} />
                        </div>
                        <div className="col-md-5">
                            <Shimmer className="rounded mt-3" height={40} />
                        </div>


                    </div>

                })} </div>
        </div>
    </div >
}



export const ShimmerComp = () => {
    return <tbody>
        {Array.from(Array(5), (e, i) => {
            return <tr className="align-middle" key={i}>
                <td scope="row " ><Shimmer className="rounded " height={25} /></td>
                <td scope="row " ><Shimmer className="rounded " height={25} /></td>
                <td scope="row " ><Shimmer className="rounded " height={25} /></td>
            </tr>
        })}
    </tbody>
}



export const ShimmerFeed = () => {
    const numRows = [1, 2, 3];
    return <div>
        {numRows.map((user, index) =>
            <div className=" col-md-7 p-3 table-container mt-3 " key={index}>
                <div className="row justify-content-start" >
                    <div className="col-md-10">
                        <Shimmer className="rounded " height={35} />
                    </div>
                    <div className="col-md-2 text-end">
                        <Shimmer className="rounded " height={35} />
                    </div>
                    <div className="col-md-3 mt-4">
                        <Shimmer className="rounded " height={25} />
                    </div>
                    <div className="col-md-3 text-end mt-4">
                        <Shimmer className="rounded " height={25} />
                    </div>
                </div>
            </div>
        )}
    </div>
}


export const ShimmerInvent = () => {
    return <tbody>
        {Array.from(Array(5), (e, i) => {
            return <tr className="align-middle" key={i}>
                <td scope="row" className="col-"></td>
                <td scope="row " className="col-md-8 wm-63"><Shimmer className="rounded " height={25} /></td>
                <td scope="row " className="col-md-4"><Shimmer className="rounded " height={25} /></td>

            </tr>
        })}
    </tbody>
}


export const ShimmerPartner = () => {
    return <tbody>
        {Array.from(Array(5), (e, i) => {
            return <tr className="align-middle" key={i}>
                <td scope="row" className="col-"></td>
                <td scope="row " ><Shimmer className="rounded " height={25} /></td>
                <td scope="row " ><Shimmer className="rounded " height={25} /></td>
                <td scope="row " ><Shimmer className="rounded " height={25} /></td>
                <td scope="row " ><Shimmer className="rounded " height={25} /></td>
                <td scope="row " ><Shimmer className="rounded " height={25} /></td>
            </tr>
        })}
    </tbody>
}


export const ShimmerAnnouncement = () => {
    return (
        <div className="row">
            <div className="col-md-8 d-flex flex-row">
                <div className="col-md-1 me-3">
                    <Shimmer className="rounded " height={25} />
                </div>
                <div className="col-md-7 me-3">
                    <Shimmer className="rounded " height={25} />
                </div>
                <div className="col-md-1">
                    <Shimmer className="rounded " height={25} />
                </div>
            </div>
            <div className="col-md-8 mt-3">
                <textarea
                    className="form-control login-input p-07rem mb-3 w-100 fs-large-med px-4"
                    disabled
                    rows={4} value=""
                >
                </textarea>
            </div>
        </div>
    )
}