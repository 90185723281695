import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Header from "../../../components/header";
import { getActiveID, setActiveID } from "../../../util/LocalStorageUtil";

const BottomNavbar = () => {
    const [isActive, setIsActive] = useState(1)
    const setActive = (value) => {
        setIsActive(value)
        setActiveID(value)
    }
    useEffect(() => {
        let stateVal = getActiveID();
        if (stateVal !== null) {
            setIsActive(stateVal)
        }
    }, [])
    return (
        <nav className="navbar navbar-expand-lg z-ind-1 navbar-light bg-secondary py-0 bottom-navbar d-block d-md-none d-lg-none" fixed="bottom">
            <div className="container">
                <div className=" navbar-collapse justify-content-between row" id="bottomNavbar">

                    <div
                        className={"   col-4 my-auto " + (isActive == 1 ? "active" : "")}
                        id={1} onClick={() => { setActive(1) }}>
                        <Link to="/landing" className=" w-100 nav-link  text-center">
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-house-door" viewBox="0 0 16 16">
                                <path d="M8.354 1.146a.5.5 0 0 0-.708 0l-6 6A.5.5 0 0 0 1.5 7.5v7a.5.5 0 0 0 .5.5h4.5a.5.5 0 0 0 .5-.5v-4h2v4a.5.5 0 0 0 .5.5H14a.5.5 0 0 0 .5-.5v-7a.5.5 0 0 0-.146-.354L13 5.793V2.5a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5v1.293L8.354 1.146zM2.5 14V7.707l5.5-5.5 5.5 5.5V14H10v-4a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5v4H2.5z" />
                            </svg>
                            <h1 className="fs-normal-med">Home</h1>
                        </Link>
                    </div>
                    <div className={"   col-4 my-auto " + (isActive == 2 ? "active" : "")} id={2} onClick={() => { setActive(2) }}>
                        {/* <Link to="/search" className=" w-100 nav-link  text-center">
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-search" viewBox="0 0 16 16">
                                <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                            </svg>
                            <h1 className="fs-normal-med">Search</h1>
                        </Link> */}
                    </div>
                    <div className={"   col-4 my-auto " + (isActive == 3 ? "active" : "")} id={3} onClick={() => { setActive(3) }}>
                        <Link to="/profile" className=" w-100 nav-link  text-center"> <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-person" viewBox="0 0 16 16">
                            <path d="M8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0zm4 8c0 1-1 1-1 1H3s-1 0-1-1 1-4 6-4 6 3 6 4zm-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10c-2.29 0-3.516.68-4.168 1.332-.678.678-.83 1.418-.832 1.664h10z" />
                        </svg>
                            <h1 className="fs-normal-med">Profile</h1></Link>
                    </div>
                </div>
            </div>
        </nav>
    )

}
export default BottomNavbar