import React, { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom';
import { getProductList } from '../../../api/API';
import Result from '../../../components/result/result';
import SearchMenu from '../../../components/search-dropdown/search-menu';
import UserLayout from '../../../UserLayout';
import FilterModal from '../order/FilterModal';
import { useLocation } from 'react-router'

const SearchResult = () => {

    const [searchParams] = useSearchParams()
    const params = Object.fromEntries([...searchParams]);
    const { state } = useLocation();
    const [searchQuery, setSearchQuery] = useState(params?.q ?? '')

    const [productList, setProductList] = useState([])

    useEffect(() => {
        if(state){
            setProductList(state)
        }
        else
        {   
            console.log(searchQuery)
            getProductList({skuCode: searchQuery})
            .then(data => {
                console.log(data)
                setProductList(data)
            })
        }
        
    }, [state])

    return (
        <UserLayout>
            <FilterModal />
            <div className="container-fluid   align-self-center">
                <div className="row justify-content-center">

                    <div className="container my-md-200 ">
                        <div className="row justify-content-center">

                            <div className="col-md-8 my-auto d-block d-md-none d-lg-none search-bar">
                                <SearchMenu />
                            </div>
                            
                            <Result productList={productList} />
                        </div>

                    </div>


                </div>
            </div>
        </UserLayout>
    )
}
export default SearchResult