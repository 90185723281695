import React, { useEffect, useState } from 'react'
import { Modal } from 'bootstrap'
import Pagination from '@mui/material/Pagination';
import AdminLayout from '../../../AdminLayout';
import SearchIcon from "@mui/icons-material/SearchOutlined";
import DeleteHierarchy from './DeleteHierarchy'
// import VendorEditAddModal from './VendorEditModal';
// import VendorDeleteModal from './VendorDeleteModal';
import { getPartnerList,getSalesHierarchy } from '../../../api/API';
import { ShimmerPartner } from '../../../components/shimmer-effect/ShimmerEffect';
import { paginationStyle } from '../../../scss/admin/mui';
import { Stack } from '@mui/material';
// import VendorAddModal from './AddVendorModal';
import { PAGINATION_ITEM_PER_PAGE } from '../../../util/EnvHelper';
import AddHeirarchy from './AddHeirarchy'
import EditHeirarchy from './EditHeirarchy'

const SalesHeirarchy = () => {
    const [showPIndex, setShowPIndex] = useState(-1)
    const [deletePIndex, setDeletePIndex] = useState(-1)
    const [showAIndex, setShowAIndex] = useState(-1)

    const [isViewOnly, setIsViewOnly] = useState(false)
    const [isLoading, setIsLoading] = React.useState(true);
    const [state, setState] = React.useState({
        heirarchyList: [], noOfPages: 0, 
    });
    const [filterList, setFilterList] = useState([state.heirarchyList]);
    const itemsPerPage = PAGINATION_ITEM_PER_PAGE;
    const [page, setPage] = React.useState(1);
    const [getSh,setGetSh] = useState(' ')
    let editOfficer = null
    let parentOfficer = null

  useEffect(() => {
    getHeirarchy(getSh)
  }, []);

  const getHeirarchy = (getSh) => {
    console.log("here")
    getSalesHierarchy(getSh)
        .then(data => {
            setIsLoading(false)
            setState(
                {
                    ...state,
                    heirarchyList: data,
                    noOfPages: Math.ceil(data.length / itemsPerPage)

                })
                setFilterList(data)
        })
}
 
useEffect(() => {
    if (showPIndex > -1) {
        const modalVendorDetails = new Modal(document.querySelector('#editSalesModal'), {
            keyboard: false
        })
        modalVendorDetails.show()

    }
}, [showPIndex])

useEffect(() => {
    if (showAIndex > -1) {
        const addVendor = new Modal(document.querySelector('#addNewSalesModal'), {
            keyboard: false
        })
        addVendor.show()

    }
}, [showAIndex])

useEffect(() => {
    if (deletePIndex > -1) {
        const modalDelVendor = new Modal(document.querySelector('#deleteHierarchy'), {
            keyboard: false
        })
        modalDelVendor.show()
    }
}, [deletePIndex])
    
  const setVendorModalData = (index, value) => {
    setShowPIndex(index)
    setIsViewOnly(value)
    editOfficer = state.heirarchyList[showPIndex]
}

const deleteVendorData = (index) => {
    setDeletePIndex(index)
}

const AddModal = () => {
    return showAIndex > -1 ? (<AddHeirarchy getVendorList={() => getHeirarchy()} onModalClose={() => {addSalesData(-1);getHeirarchy('')}}
    />) : (<></>)
}

const DeleteModal = () => {
    return deletePIndex > -1 ? (<DeleteHierarchy partner={state.heirarchyList[deletePIndex]}
        onModalClose={() => {deleteVendorData(-1);getHeirarchy('')}} />) : (<></>)
}

const EditModal = () => {
    editOfficer = state.heirarchyList[showPIndex]
    if(editOfficer){
        parentOfficer = filterList.find(officer => editOfficer.parentShRid === officer.shRid)
    }
    return showPIndex > -1 ? (<EditHeirarchy hierarchy={state.heirarchyList[showPIndex]}
        reportingOfficer={parentOfficer} isViewOnly={isViewOnly} onModalClose={() => {setVendorModalData(-1);getHeirarchy('')}}
    />) : (<></>)
}

const addSalesData = (index) => {
    setShowAIndex(index)
    // if(index===-1){
    //     getHeirarchy(0)
    // }
}

const onPageChange = (event, value) => {
    setPage(value);
};

const ReturnDesignation = (shLevel) => {
    // console.log(shLevel);
    if(shLevel.shLevel === 1){
        return(
            <td>
                Regional Manager
            </td>
        )
    }
    else if(shLevel.shLevel === 2){
        return(
            <td>
                State Incharge
            </td>
        )
    }
    else{
        return(
            <td>
                Sales Officer
            </td>
        )
    }
}


  return (
  
        <AdminLayout title="Sales Heirarchy">
            <EditModal />
            <AddModal />
            <DeleteModal/>
            <div className="container-fluid align-self-center">
                <div className="row justify-content-center">

                    <div className="col-md-12  d-flex flex-row justify-content-end">
                        {/* <div className="search-input-btn d-flex flex-row justify-content-between border-0 rounded-bg px-3 col-md-4">

                            <div className="my-auto">
                                <SearchIcon />
                            </div>
                            <input type="text" className="form-control border-0 bg-transparent fs-large-regular "
                                placeholder="Search for anything " onChange={(e) => { searchFliter(e) }} />
                        </div> */}

                        <div className="my-auto">
                            <button className="px-4 py-2 b-none w-100 rounded-bg fs-normal-semibold text-green "
                                onClick={() => { addSalesData(1) }}>
                                + Add new
                            </button>
                        </div>
                    </div>

                    <div className=" col-md-12  p-3 table-container mt-3">
                        <table className="table table-striped table-borderless  ">
                            <thead>
                                <tr className="fs-large-light">
                                    <td scope="col" >
                                        <div className="d-flex flex-row my-auto">
                                            <div className="my-auto ">
                                                <p className="me-2 mb-0 ws-no-wrap">Name</p>
                                            </div>

                                            <div className="d-flex flex-column my-auto">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" fill="currentColor" className="bi bi-caret-up text-grey " viewBox="0 0 16 16" >
                                                    <path d="M3.204 11h9.592L8 5.519 3.204 11zm-.753-.659 4.796-5.48a1 1 0 0 1 1.506 0l4.796 5.48c.566.647.106 1.659-.753 1.659H3.204a1 1 0 0 1-.753-1.659z" />
                                                </svg>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" fill="currentColor" className="bi bi-caret-down text-grey" viewBox="0 0 16 16">
                                                    <path d="M3.204 5h9.592L8 10.481 3.204 5zm-.753.659 4.796 5.48a1 1 0 0 0 1.506 0l4.796-5.48c.566-.647.106-1.659-.753-1.659H3.204a1 1 0 0 0-.753 1.659z" />
                                                </svg>
                                            </div>
                                        </div>
                                    </td>
                                    <td scope="col" >
                                        <div className="d-flex flex-row my-auto">
                                            <div className="my-auto">
                                                <p className="me-2 mb-0">Reporting To</p>
                                            </div>

                                            <div className="d-flex flex-column">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" fill="currentColor" className="bi bi-caret-up text-grey " viewBox="0 0 16 16" >
                                                    <path d="M3.204 11h9.592L8 5.519 3.204 11zm-.753-.659 4.796-5.48a1 1 0 0 1 1.506 0l4.796 5.48c.566.647.106 1.659-.753 1.659H3.204a1 1 0 0 1-.753-1.659z" />
                                                </svg>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" fill="currentColor" className="bi bi-caret-down text-grey" viewBox="0 0 16 16">
                                                    <path d="M3.204 5h9.592L8 10.481 3.204 5zm-.753.659 4.796 5.48a1 1 0 0 0 1.506 0l4.796-5.48c.566-.647.106-1.659-.753-1.659H3.204a1 1 0 0 0-.753 1.659z" />
                                                </svg>
                                            </div>
                                        </div>
                                    </td>
                                    <td scope="col">
                                        <div className="d-flex flex-row my-auto">
                                            <div className="my-auto">
                                                <p className="me-2 mb-0 ws-no-wrap">Email</p>
                                            </div>
                                            <div className="d-flex flex-column">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" fill="currentColor" className="bi bi-caret-up text-grey " viewBox="0 0 16 16" >
                                                    <path d="M3.204 11h9.592L8 5.519 3.204 11zm-.753-.659 4.796-5.48a1 1 0 0 1 1.506 0l4.796 5.48c.566.647.106 1.659-.753 1.659H3.204a1 1 0 0 1-.753-1.659z" />
                                                </svg>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" fill="currentColor" className="bi bi-caret-down text-grey" viewBox="0 0 16 16">
                                                    <path d="M3.204 5h9.592L8 10.481 3.204 5zm-.753.659 4.796 5.48a1 1 0 0 0 1.506 0l4.796-5.48c.566-.647.106-1.659-.753-1.659H3.204a1 1 0 0 0-.753 1.659z" />
                                                </svg>
                                            </div>
                                        </div>
                                    </td>
                                    {/* <td scope="col" >
                                        <div className=" d-flex flex-row my-auto">
                                            <div className="my-auto">
                                                <p className="me-2 mb-0">Location</p>
                                            </div>
                                            <div className="d-flex flex-column ">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" fill="currentColor" className="bi bi-caret-up text-grey " viewBox="0 0 16 16" >
                                                    <path d="M3.204 11h9.592L8 5.519 3.204 11zm-.753-.659 4.796-5.48a1 1 0 0 1 1.506 0l4.796 5.48c.566.647.106 1.659-.753 1.659H3.204a1 1 0 0 1-.753-1.659z" />
                                                </svg>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" fill="currentColor" className="bi bi-caret-down text-grey" viewBox="0 0 16 16">
                                                    <path d="M3.204 5h9.592L8 10.481 3.204 5zm-.753.659 4.796 5.48a1 1 0 0 0 1.506 0l4.796-5.48c.566-.647.106-1.659-.753-1.659H3.204a1 1 0 0 0-.753 1.659z" />
                                                </svg>
                                            </div>
                                        </div>
                                    </td> */}
                                    
                                    <td scope="col" >
                                        <div className=" d-flex flex-row my-auto">
                                            <div className="my-auto">
                                                <p className="me-2 mb-0">Designation</p>
                                            </div>
                                            <div className="d-flex flex-column">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" fill="currentColor" className="bi bi-caret-up text-grey " viewBox="0 0 16 16" >
                                                    <path d="M3.204 11h9.592L8 5.519 3.204 11zm-.753-.659 4.796-5.48a1 1 0 0 1 1.506 0l4.796 5.48c.566.647.106 1.659-.753 1.659H3.204a1 1 0 0 1-.753-1.659z" />
                                                </svg>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" fill="currentColor" className="bi bi-caret-down text-grey" viewBox="0 0 16 16">
                                                    <path d="M3.204 5h9.592L8 10.481 3.204 5zm-.753.659 4.796 5.48a1 1 0 0 0 1.506 0l4.796-5.48c.566-.647.106-1.659-.753-1.659H3.204a1 1 0 0 0-.753 1.659z" />
                                                </svg>
                                            </div>
                                        </div>
                                    </td>
                                    <td scope="col " >
                                        <div className=" d-flex flex-row my-auto">
                                            <div className="my-auto">
                                                <p className="me-2 mb-0">Actions</p>
                                            </div>

                                        </div>
                                    </td>
                                </tr>
                            </thead>
                            <tbody>
                            {isLoading ? (<ShimmerPartner />) :
                               filterList !== null ?
                               filterList
                                        .slice((page - 1) * itemsPerPage, page * itemsPerPage)
                                        .map((partner, index) => {
                                            return (
                                                
                                                    <tr key={filterList.indexOf(partner)} className="align-middle fs-large-med" >

                                                        <td scope="row">{partner.fullName}</td>
                                                        <td>
                                                            {partner.reportingTo}
                                                        </td>
                                                        <td>{partner.email}</td>
                                                        {/* <td>Lansing, Illinois</td> */}
                                                        <ReturnDesignation shLevel = {partner.shLevel} />
                                                        <td>
                                                            <div className="d-flex flex-row">
                                                                {/* <button className="action-circle me-2" onClick={() => setVendorModalData(state.heirarchyList.indexOf(partner), true)}>
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-eye text-green" viewBox="0 0 16 16">
                                                                        <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z" />
                                                                        <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z" />
                                                                    </svg>
                                                                </button> */}
                                                                <button className="action-circle me-2" onClick={() => setVendorModalData(state.heirarchyList.indexOf(partner), false)} >
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-pencil text-green" viewBox="0 0 16 16">
                                                                        <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z" />
                                                                    </svg>
                                                                </button>
                                                                <button className="action-circle me-2" onClick={() => deleteVendorData(state.heirarchyList.indexOf(partner))}>
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-trash text-danger" viewBox="0 0 16 16"
                                                                    >
                                                                        <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                                                                        <path fillRule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z" />
                                                                    </svg>
                                                                </button>
                                                            </div>
                                                        </td>
                                                    </tr>

                                                
                                            );
                                        }) :
                                        <>
                                            <td className=" w-3"></td>
                                            <td scope="row"></td>
                                            <td scope="row">
                                                <div className="w-100 mt-3 text-center justify-content-center">
                                                    <p className="fs-large-regular"><i>No records</i></p>
                                                </div>
                                            </td>
                                        </>
                            }
                            </tbody>
                        </table>

                        <div className=" mt-5 justify-content-center text-center">
                            <Stack spacing={2} alignItems="center">
                                <Pagination
                                    count={Math.ceil((filterList).length / itemsPerPage)}
                                    page={page}
                                    onChange={onPageChange}
                                    defaultPage={1}
                                    size="large"
                                    variant="outlined" color="success"
                                    showFirstButton
                                    showLastButton
                                  />
                                  
                            </Stack>
                        </div>
                    </div>
                </div>
            </div>

            {/* Vendor view and edit modal */}

            {/* Vendor view and edit modal */}

        </AdminLayout>

  );
}
export default SalesHeirarchy