import React, { useEffect, useState } from 'react'
import fanImg from '../../../assets/fan_img.svg';
import './query.scss';
import UserLayout from '../../../UserLayout';
import { useLocation } from 'react-router'
import {getQueryDetails} from '../../../api/API'
import { Link } from 'react-router-dom';
import {toDisplayDateTime} from '../../../util/Util';

const QueryDetails = () => {

    const { state } = useLocation();
    const [chRid,setChRid] = useState([])
    const [complaintDetails, setComplaintDetails] = useState([])

    const getDetails = (chRid) => {
        getQueryDetails(chRid)
        .then(data =>{
            setComplaintDetails(data)
            console.log(data)
        })
    }

    useEffect(() => {
        if (state) {
            getDetails(state.chRid)   //GET QUERY DETAILS HERE
            setChRid(state.chRid)
        }
    }, [])

    return (
        <UserLayout>
            <div className="container-fluid   align-self-center">
                <div className="row justify-content-center">

                    <div className="container my-md-200">
                        <div className="row justify-content-center">
                            <div className="col-md-8 col-11">

                                <div className="d-flex flex-row justify-content-between mb-4">
                                    <div className="d-none d-lg-block d-md-block mb-3">
                                        <div className="d-flex flex-row ">
                                            <Link className="d-flex flex-row  a-nonlink" to="/landing">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="#5CA772" className="bi bi-chevron-left mt-1" viewBox="0 0 16 16">
                                                    <path fill-rule="evenodd" d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z" />
                                                </svg>
                                                <p className="fs-large-med btn-link text-green ms-2">Home</p>
                                            </Link>
                                            <p className="fs-large-light text-dark ms-2">/</p>
                                            <Link className='a-nonlink' to='/query-list'>
                                                <p className="fs-large-med btn-link text-green ms-2">Query List</p>
                                            </Link>
                                            <p className="fs-large-light text-dark ms-2">/ Query Details</p>
                                        </div>
                                    </div>

                                    <a href="/search-query">
                                        <button className="btn  btn-box">
                                            <span className="me-md-2 fs-large-regular text-green my-auto">+</span>
                                            <span className="me-md-3 fs-normal-reg text-green my-auto" >New query</span>
                                        </button>
                                    </a>


                                </div>

                                 <div>
                                    <div className="d-flex flex-row  a-nonlink">
                                        <div className="text-dark">
                                            <p className="fs-large-regular "><span>Complaint number: </span>
                                                <span className="ms-1"><strong>{chRid}</strong></span></p>
                                            <p className="fs-large-regular "><span>Status: </span>
                                                <span className="ms-1"><strong>Complaint received</strong></span></p>
                                            <p className="fs-large-regular "><span>Complaint raised on: </span>
                                                <span className="ms-1"><strong>{toDisplayDateTime(state.dateTime)}</strong></span></p>
                                        </div>

                                    </div>
                                </div>

                                {complaintDetails!==null ? complaintDetails.map((item,index) => (
                                <div className="container px-0 my-3 my-md-5">
                                   
                                        {/* <div className="col-md-2 col-small-3 d-none d-lg-block">
                                            <img src={item.imageUrl} alt="" className="img-fluid w-100 my-4 " />
                                        </div> */}
                                        <div className="col-md-5 col-9">
                                            <p className="fs-large-semibold mb-3">{item.title}</p>
                                        </div>
                                        <div className="row justify-content-between">
                                            {item.remarks === null || item.remarks === "" ? 
                                            <div className="col-md-5 col-9">
                                                <p className="fs-small-light mb-0">Remarks</p>
                                                <div className="mb-2 d-flex py-2 px-2 mb-0 justify-content-between align-items-center searchbar">
                                                    <p className="mb-0 fs-large-reg">-</p>
                                                </div>
                                            </div> :
                                            <div className='col-md-5 col-9'> 
                                                <p className="fs-small-light mb-0">Remarks</p>
                                                <div className="mb-2 d-flex py-2 px-2 mb-0 justify-content-between align-items-center searchbar">
                                                    <p className="mb-0 fs-large-reg">{item.remarks}</p>
                                                </div> 
                                            </div>
                                            }
                                            <div className="col-md-5 col-12">
                                                <p className="fs-small-light mb-0">Serial Numbers</p>
                                                <div className="mb-2 d-flex py-2 px-2 mb-0 justify-content-between align-items-center searchbar">
                                                    <p className="mb-0">{item.serialNos}</p>
                                                </div>
                                            </div>
                                            <p className="mt-3 fs-normal-med">Qty: {item.qty}</p>
                                    </div>
                                    <hr></hr>
                                </div>
                                )):null}
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </UserLayout>
    )
}
export default QueryDetails