import React, { useEffect, useState } from 'react'
import './inventory.scss';
import { Modal } from 'bootstrap'
import Pagination from '@mui/material/Pagination';
import AdminLayout from '../../../AdminLayout';
import SearchIcon from "@mui/icons-material/SearchOutlined";

import { CustomOptions } from '../../user/login/Login';
import { useSnackbar } from 'react-simple-snackbar';
import { getProductListdetails, } from '../../../api/API';
import { ShimmerInvent, ShimmerInventory} from '../../../components/shimmer-effect/ShimmerEffect';
import AddInventory from './add-inventory/AddInventory';
import EditInventory from './edit-inventory/EditInventory';
import { Stack } from '@mui/material';
import { PAGINATION_ITEM_PER_PAGE } from '../../../util/EnvHelper';


const InventoryList = () => {

    const [isLoading, setIsLoading] = React.useState(true);
    const [openSnackbar] = useSnackbar(CustomOptions)
    const [state, setState] = React.useState({
        productId: -1, productList: [], noOfPages: 0,
    });
    const itemsPerPage = PAGINATION_ITEM_PER_PAGE;
    const [page, setPage] = React.useState(1);
    const [showPIndex, setShowPIndex] = useState(-1)
    const [filterList, setFilterList] = useState([]);


    useEffect(() => {
        getProductDetails()
    }, [])

    useEffect(() => {
        if (state.productId > -1) {
            const modalEditInventory = new Modal(document.querySelector('#inventoryEditModal'), {
                keyboard: false
            })
            modalEditInventory.show()
        }
    }, [state.productId])

    useEffect(() => {
        if (showPIndex > -1) {
            const modalAddProd = new Modal(document.querySelector('#inventoryAddModal'), {
                keyboard: false
            })
            modalAddProd.show()

        }
    }, [showPIndex])

    const getProductDetails = async () => {
        setIsLoading(true)
        getProductListdetails()
            .then((data) => {
                setState(
                    {
                        ...state,
                        productList: data,
                       
                        noOfPages: Math.ceil(data.length / itemsPerPage)

                    });
                    setFilterList(data)
                   
                setIsLoading(false)
            }).catch(e => {
                setIsLoading(false)
                openSnackbar(e)
            })
    };

    const editInventoryModal = (prodId) => {
        setState({
            ...state,
            productId: prodId
        });
    }

    const setNoneProdId = () => {
        setState({
            ...state,
            productId: -1
        });
    }

    const EditModal = () => {
        return state.productId > -1 ? (<EditInventory prodId={state.productId}
            getProdList={() => getProductDetails()} onModalClose={() => { setNoneProdId() }} />) : (<></>)
    }

    const AddModal = () => {
        return showPIndex > -1 ? (<AddInventory getProdList={() => getProductDetails()} onModalClose={() => addProdData(-1)}
        />) : (<></>)
    }

    const addProdData = (index) => {
        setShowPIndex(index)
    }
    const onPageChange = (event, value) => {
        setPage(value);
    };

    const searchFliter = (e) => {
        setPage(1);
        let searchText = e.target.value.toUpperCase();
        let searchResult = [];
        searchResult = (state.productList).filter((item) => {
            // console.log(item.skuList[i].skuName)
            const prodName = item.prodName ? item.prodName.toUpperCase() : ''.toUpperCase();
            // const skuVal = item.skuList[i] ? item.skuList[i].skuName.toUpperCase() : ''.toUpperCase();

            return (prodName.search(searchText) != -1);
        
        });
        setFilterList(searchResult);
        state.productList.map((item)=>{
            item.skuList.map((item) => {
                console.log(item.skuRid);
            })
        })

    }
    return (
        <AdminLayout title="Product">
            <AddModal />
            <EditModal />
            <div className="container-fluid   align-self-center">
                <div className="row ">

                    <div className="col-md-7  d-flex flex-row justify-content-between">
                        <div className="search-input-btn d-flex flex-row justify-content-between border-0 rounded-bg px-3 col-md-7">

                            <div className="my-auto">
                                <SearchIcon />
                            </div>
                            <input type="text" className="form-control border-0 bg-transparent fs-large-regular "
                                onChange={(e) => { searchFliter(e) }} placeholder="Search by Model " />
                        </div>

                        <div className="my-auto">
                            <button className="px-4 py-2 b-none w-100 rounded-bg fs-normal-semibold text-green "
                                onClick={() => { addProdData(1) }}>
                                + Add new
                            </button>
                        </div>
                    </div>

                    <div className=" col-md-7  p-3 table-container mt-3 table-invent">
                        <table className="table table-borderless  ">
                            <thead>
                                <tr className=" fs-large-light ">
                                    <td scope="row"></td>
                                    <td scope="col" className="col-md-7 ">
                                        Model
                                    </td>
                                    <td scope="col"  className="col-md-4  wm-35">
                                        Number of variants
                                    </td>

                                    <td scope="col" >
                                        <div className=" d-flex flex-row my-auto">
                                            <div className="my-auto">
                                                <p className="me-2 mb-0"></p>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            </thead>
                            <br></br>
                            <tbody>
                            {isLoading ? (<ShimmerInvent />) :
                               filterList !== null ?
                               filterList
                                        .slice((page - 1) * itemsPerPage, page * itemsPerPage)
                                        .map((product, index) => {
                                            let skuLen = (product.skuList).length
                                            return (
                                                <>
                                                    <tr className="fs-large-med custom-flush-accrodion" >
                                                        <td scope="row" className=" col-">
                                                            <button id={"prod-headingOne" + index} className="accordion-button mt-2 collapsed  bg-transparent shadow-none h-1rem" data-bs-toggle="collapse" data-bs-target={"#prod-collapseOne" + index}
                                                                aria-expanded="false" aria-controls={"prod-collapseOne" + index}>
                                                            </button>
                                                        </td>
                                                        <td className="col-md-7">
                                                            <div className="mt-2">{product.prodName}</div>
                                                        </td>
                                                        <td className=" col-md-4">
                                                            <div className="ms-4 mt-2">{skuLen}</div>
                                                        </td>
                                                        <td className="my-auto">
                                                            <div className="d-flex flex-row  justify-content-center ">
                                                                <button className="action-circle me-2" onClick={() => { editInventoryModal(product.prodRid) }}>
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-pencil text-primary" viewBox="0 0 16 16">
                                                                        <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z" />
                                                                    </svg>
                                                                </button>
                                                            </div>
                                                        </td>
                                                       
                                                    </tr>
                                                    <tr className="fs-large-light">
                                                    {/* <td className='col-md-12'> */}
                                                        <div id={"prod-collapseOne" + index} className="accordion-collapse collapse" aria-labelledby={"prod-headingOne" + index} data-bs-parent="#accordionFlushExample">
                                                            <div className="accordion-body px-0 row justify-content-between">
                                                                    {product?.skuList == null || product?.length < 1 ?  null:product.skuList.map((sku, i) => {
                                                                        return (
                                                                            <>
                                                                                <div className="col-md-7">
                                                                                    <ul className="text-primary">
                                                                                        <li key={i}> 
                                                                                            <p className="text-dark">{sku.skuName}({sku.skuCode})</p>
                                                                                        </li>
                                                                                    </ul>
                                                                                </div>
                                                                                <div className="col-md-5 fs-normal-reg ">stock <span className="badge bg-success fs-large-semibold ms-2">{sku.stock}</span></div>
                                                                            </>
                                                                        )
                                                                    })}
                                                            </div>
                                                        </div>
                                                    {/* </td> */}
                                                    </tr>
                                               </>
                                            );
                                        }) :
                                    <>
                                        <td className=" w-3"></td>
                                        <td scope="row"></td>
                                        <td scope="row">
                                            <div className="w-100 mt-3 text-center justify-content-center">
                                                <p className="fs-large-regular"><i>No records</i></p>
                                            </div>
                                        </td>
                                    </>
                                }
                                </tbody>
                        </table>

                        <div className=" mt-5 justify-content-center text-center">
                            <Stack spacing={2} alignItems="center">
                                <Pagination
                                   count={Math.ceil((filterList).length / itemsPerPage)}
                                   page={page}
                                   onChange={onPageChange}
                                   defaultPage={1}
                                   size="large"
                                   variant="outlined" color="success"
                                   showFirstButton
                                   showLastButton
                                />
                            </Stack>
                        </div>
                    </div>
                </div>
            </div>


        </AdminLayout >
    )

}
export default InventoryList