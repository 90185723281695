import axios from "axios";
import { throwEmptyQueryParam, throwPathError } from "../error/ApiError";
import { BASE_URL, TRACK_URL } from "../util/EnvHelper";
import { getAuthToken } from "../util/LocalStorageUtil";
import "../util/StringUtil";
import "./AxiosInterceptor";
// converts object to query param
const toQueryParams = (queryParams) => {
    // throw error if query param is empty
    if (Object.keys(queryParams).length === 0) throwEmptyQueryParam()
    return new URLSearchParams(queryParams).toString()
}
export const httpGet = ({ path, queryParams = null }) => {
    // if (path.hasForwardSlash()) throwPathError()
    // append query param if not empty or null
    if (queryParams) {
        path = `${path}?${toQueryParams(queryParams)}`
    }
    return new Promise((resolve, reject) => {
        axios.get(`${BASE_URL}${path}`)
            .then((res) => {
                return resolve(res)
            })
            .catch(e => {
                console.log(e.message)
                reject(e.message)
            }
            ) //TODO : send formatted message
    })
}

export const httpGetTrack = ({ path, queryParams = null }) => {
    // if (path.hasForwardSlash()) throwPathError()
    // append query param if not empty or null
    console.log(`${TRACK_URL}${path}`)
    if (queryParams) {
        path = `${path}?${toQueryParams(queryParams)}`
    }
    return new Promise((resolve, reject) => {

        axios.get(`${TRACK_URL}${path}`,{
            headers: {
                'Access-Control-Allow-Origin': 'http://wp.vrllogistics.com'
            }
        })
            .then((res) => {
                return resolve(res)
            })
            .catch(e => {
                console.log(e.message)
                reject(e.message)
            }
            ) //TODO : send formatted message
    })
}

export const httpPost = ({ path, queryParams = null, body = null }) => {
    // if (path.hasForwardSlash()) throwPathError()
    // append query param if not empty
    if (queryParams) {
        path = `${path}?${toQueryParams(queryParams)}`
    }
    // console.log(BASE_URL + path)
    return new Promise((resolve, reject) => {
        axios
            .post(
                `${BASE_URL}${path}`,
                body
            )
            .then((res) => {
                return resolve(res)
            })
            .catch(e => { 
                console.log(e); 
                reject(e.message); 
            }) //TODO : send formatted message
    })
}


export const httpFileUpload = ({ path, queryParams = null, file = null }) => {
    // if (path.hasForwardSlash()) throwPathError()
    // append query param if not empty
    if (queryParams) {
        path = `${path}?${toQueryParams(queryParams)}`
    }

    return new Promise((resolve, reject) => {

        axios
            .post(
                `${BASE_URL}${path}`,
                file,
                // { headers: headers }
            )
            .then((res) => {

                return resolve(res)
            })
            .catch(e => {
                 console.log(e); 
                 reject(e.message); 
            }) //TODO : send formatted message

    })
}

export const getFile = ({ path, queryParams = null }) => {
    // if (path.hasForwardSlash()) throwPathError()
    // append query param if not empty or null
    if (queryParams) {
        path = `${path}?${toQueryParams(queryParams)}`
    }

    const instance = axios.create({
        baseURL: BASE_URL,
    });


    return new Promise((resolve, reject) => {

        let token = getAuthToken()

        instance.get(`${BASE_URL}${path}`,
            {
                responseType: 'arraybuffer',
                responseEncoding: '',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/pdf',
                    'Authorization': `Bearer ${token}`
                }
            }
        )
            .then((res) => {
                return resolve(res)
            })
            .catch(e => {
                console.log(e.message)
                reject(e.message)
            }
            ) //TODO : send formatted message
    })
}

export const getFileExport = ({ path, queryParams = null }) => {
    // if (path.hasForwardSlash()) throwPathError()
    // append query param if not empty or null
    if (queryParams) {
        path = `${path}?${toQueryParams(queryParams)}`
    }

    const instance = axios.create({
        baseURL: BASE_URL,
    });


    return new Promise((resolve, reject) => {

        let token = getAuthToken()

        instance.get(`${BASE_URL}${path}`,
            {
                responseType: 'arraybuffer',
                responseEncoding: '',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': '*/*',
                    'Authorization': `Bearer ${token}`
                }
            }
        )
            .then((res) => {
                return resolve(res)
            })
            .catch(e => {
                console.log(e.message)
                reject(e.message)
            }
            ) //TODO : send formatted message
    })
}


export const getReport = ({ path, body = null, queryParams = null }) => {
    // if (path.hasForwardSlash()) throwPathError()
    // append query param if not empty or null
    if (queryParams) {
        path = `${path}?${toQueryParams(queryParams)}`
    }

    const instance = axios.create({
        baseURL: BASE_URL,
    });


    return new Promise((resolve, reject) => {

        let token = getAuthToken()

        instance.post()

        instance.post(
            `${BASE_URL}${path}`,
            body,
            {
                responseType: 'arraybuffer',
                responseEncoding: '',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': '*/*',
                    'Authorization': `Bearer ${token}`
                },
            }
        )
            .then((res) => {
                return resolve(res)
            })
            .catch(e => {
                console.log(e.message)
                reject(e.message)
            }
            ) //TODO : send formatted message
    })
}