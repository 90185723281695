import React, { useEffect, useState } from 'react'
import fanImg from '../../assets/fan_img.svg';
import { useNavigate } from 'react-router-dom';
import UserLayout from '../../UserLayout';
import ReactImageZoom from 'react-image-zoom';
import { getIncrement, removeIncrement, setIncrement } from '../../util/LocalStorageUtil';
import ReactImageMagnify from 'react-image-magnify';


const Magnify = () => {
    const imageBaseUrl =
        "https://s3-us-west-1.amazonaws.com/react-package-assets/images/";
    const navigate = useNavigate();
    const [incValue, setIncValue] = React.useState(4);
    const [state, setState] = React.useState({});
    const props = {
        height: 100, zoomWidth: 10, zoomLensStyle: { opacity: 0.7, backgroundColor: 'green', },
        offset: { "vertical": 0, "horizontal": 0, width: '100%' },
        img: "http://malaman.github.io/react-image-zoom/example/1.jpg"
    };

    const openSnackBar = () => {
        navigate(`/`, { state: true })
    };


    const setValueIncrement = async (event) => {
        let incVal = getIncrement();
        if (incVal !== null) {
            setIncrement(event.target.name, Number(incVal) + Number(4))
            setState({ ...state, [event.target.name]: Number(event.target.value) + Number(4) });

        }
        else {
            setIncrement(event.target.name, 8)
            setState({ ...state, [event.target.name]: Number(8) });

        }

    };
    const setValueDecrement = async (event) => {
        let incVal = getIncrement();
        if (incVal !== null && event.target.value != 4) {
            setIncrement(event.target.name, Number(incVal) - Number(4))
            setState({ ...state, [event.target.name]: Number(event.target.value) - Number(4) });

        }
    };

    // useEffect(() => {
    //     let incVal = getIncrement();
    //     if (incVal !== null) {
    //         setIncValue(incVal)
    //     }
    // }, [])
    return (

        <UserLayout>
            <div className="container-fluid   align-self-center">
                <div className="container my-md-100">
                    <div className="row justify-content-center">
                        <div className="col-md-2  d-none d-md-block d-lg-block">
                            <img src={fanImg} className="img-fluid w-100 my-4 " />
                            <img src={fanImg} className="img-fluid w-100 my-4 " />
                            <img src={fanImg} className="img-fluid w-100 my-4 " />
                            <img src={fanImg} className="img-fluid w-100 my-4 " />
                        </div>
                        <div className="col-md-4">
                            <div id="nonRespCarousel" className="carousel slide" data-bs-interval="false">
                                <div className="carousel-inner" data-aos="fade-up">

                                    <div className="carousel-item active h-auto">
                                        <div className="card border-0 card-non-b-radius my-4" key={1}>
                                            <div className="img-wrapper">
                                                <ReactImageMagnify
                                                    {...{
                                                        smallImage: {
                                                            alt: "Product Image",
                                                            isFluidWidth: true,
                                                            src: "https://helpx.adobe.com/content/dam/help/en/photoshop/using/convert-color-image-black-white/jcr_content/main-pars/before_and_after/image-before/Landscape-Color.jpg",


                                                        },
                                                        largeImage: {
                                                            alt: "Product Image",
                                                            src: "https://helpx.adobe.com/content/dam/help/en/photoshop/using/convert-color-image-black-white/jcr_content/main-pars/before_and_after/image-before/Landscape-Color.jpg",
                                                            width: 1000,
                                                            height: 1000
                                                        },
                                                        isHintEnabled: true,
                                                        shouldUsePositiveSpaceLens: false,

                                                        enlargedImagePortalId: "myPortal"
                                                    }}

                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="carousel-item  h-auto">
                                        <div className="card border-0 card-non-b-radius  my-4" key={2}>
                                            <ReactImageMagnify
                                                {...{
                                                    smallImage: {
                                                        alt: "Product Image",
                                                        isFluidWidth: true,
                                                        src: "https://helpx.adobe.com/content/dam/help/en/photoshop/using/convert-color-image-black-white/jcr_content/main-pars/before_and_after/image-before/Landscape-Color.jpg",


                                                    },
                                                    largeImage: {
                                                        alt: "Product Image",
                                                        src: "https://helpx.adobe.com/content/dam/help/en/photoshop/using/convert-color-image-black-white/jcr_content/main-pars/before_and_after/image-before/Landscape-Color.jpg",
                                                        width: 1000,
                                                        height: 1000
                                                    },
                                                    isHintEnabled: true,
                                                    shouldUsePositiveSpaceLens: false,
                                                    enlargedImagePortalId: "myPortal2"
                                                }}

                                            />
                                        </div>
                                    </div>
                                    <div className="carousel-item  h-auto">
                                        <div className="card border-0 card-non-b-radius my-4" key={3}>
                                            <ReactImageMagnify
                                                {...{
                                                    smallImage: {
                                                        alt: "Product Image",
                                                        isFluidWidth: true,
                                                        src: "https://helpx.adobe.com/content/dam/help/en/photoshop/using/convert-color-image-black-white/jcr_content/main-pars/before_and_after/image-before/Landscape-Color.jpg",


                                                    },
                                                    largeImage: {
                                                        alt: "Product Image",
                                                        src: "https://helpx.adobe.com/content/dam/help/en/photoshop/using/convert-color-image-black-white/jcr_content/main-pars/before_and_after/image-before/Landscape-Color.jpg",
                                                        width: 1000,
                                                        height: 1000
                                                    },
                                                    isHintEnabled: true,
                                                    shouldUsePositiveSpaceLens: false,
                                                    enlargedImagePosition: 'over'
                                                }}

                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className="carousel-indicators insight-carousel-indicators">

                                    <button type="button" data-bs-target="#nonRespCarousel" data-bs-slide-to="0"
                                        className="active" aria-current="true" ></button>
                                    <button type="button" data-bs-target="#nonRespCarousel" data-bs-slide-to="1"
                                        aria-current="true" ></button>
                                    <button type="button" data-bs-target="#nonRespCarousel" data-bs-slide-to="2"
                                        aria-current="true"></button>

                                </div>
                            </div>

                            <div id="myPortal" style={{ height: 200, width: 200 }} />
                            <div id="myPortal2" style={{ height: 200, width: 200 }} />
                        </div>

                        <div className="col-md-6">
                            <div className="row justify-content-center ">
                                <div className="col-md-10">
                                    <h1 className="fs-large-regular mt-3 mb-4">Ceiling fan black with golden accents - TVS
                                        Green Fan</h1>
                                    <a>
                                        <button className="btn  rounded-btn fs-small-reg">
                                            Product category/ sub-category
                                        </button></a>
                                    <div className=" mb-4  d-block d-md-none d-lg-none w-100">
                                        <div id="respCarousel" className="carousel slide" data-bs-ride="carousel">
                                            <div className="carousel-inner" data-aos="fade-up">

                                                <div className="carousel-item active h-auto">
                                                    <div className="card border-0 card-non-b-radius">
                                                        <img src={fanImg} className="img-fluid w-100 my-4 " />
                                                    </div>
                                                </div>
                                                <div className="carousel-item  h-auto">
                                                    <div className="card border-0 card-non-b-radius">
                                                        <img src={fanImg} className="img-fluid w-100 my-4 " />
                                                    </div>
                                                </div>
                                                <div className="carousel-item  h-auto">
                                                    <div className="card border-0 card-non-b-radius">
                                                        <img src={fanImg} className="img-fluid w-100 my-4 " />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="carousel-indicators insight-carousel-indicators">

                                                <button type="button" data-bs-target="#respCarousel" data-bs-slide-to="0"
                                                    className="active" aria-current="true" ></button>
                                                <button type="button" data-bs-target="#respCarousel" data-bs-slide-to="1"
                                                    aria-current="true" ></button>
                                                <button type="button" data-bs-target="#respCarousel" data-bs-slide-to="2"
                                                    aria-current="true"></button>

                                            </div>
                                        </div>
                                    </div>

                                    <p className="fs-x-large-light mt-4 mb-1 mb-md-2">In-stock</p>
                                    <p><span className="fs-x-large-semibold me-2 mb-1">Rs. 10,000/-</span>
                                        <span className="fs-small-light  me-2">Rs. 12,000/-</span><span className="fs-small-reg  me-2">17% Off</span></p>
                                    <div className="d-flex flex-row mb-3 ">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-truck me-3" viewBox="0 0 16 16">
                                            <path d="M0 3.5A1.5 1.5 0 0 1 1.5 2h9A1.5 1.5 0 0 1 12 3.5V5h1.02a1.5 1.5 0 0 1 1.17.563l1.481 1.85a1.5 1.5 0 0 1 .329.938V10.5a1.5 1.5 0 0 1-1.5 1.5H14a2 2 0 1 1-4 0H5a2 2 0 1 1-3.998-.085A1.5 1.5 0 0 1 0 10.5v-7zm1.294 7.456A1.999 1.999 0 0 1 4.732 11h5.536a2.01 2.01 0 0 1 .732-.732V3.5a.5.5 0 0 0-.5-.5h-9a.5.5 0 0 0-.5.5v7a.5.5 0 0 0 .294.456zM12 10a2 2 0 0 1 1.732 1h.768a.5.5 0 0 0 .5-.5V8.35a.5.5 0 0 0-.11-.312l-1.48-1.85A.5.5 0 0 0 13.02 6H12v4zm-9 1a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm9 0a1 1 0 1 0 0 2 1 1 0 0 0 0-2z" />
                                        </svg>
                                        <p className="fs-large-regular mb-1">Delivery by 8th Feb 2022</p></div>
                                    <a className="mt-md-5 mt-3 d-block d-md-none d-lg-none">
                                        <button className="btn  btn-box w-100">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-file-earmark-text text-green" viewBox="0 0 16 16">
                                                <path d="M5.5 7a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1h-5zM5 9.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 0 1h-2a.5.5 0 0 1-.5-.5z" />
                                                <path d="M9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V4.5L9.5 0zm0 1v2A1.5 1.5 0 0 0 11 4.5h2V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h5.5z" />
                                            </svg>
                                            <span className="mx-3 fs-normal-reg text-green" >Product catalogue</span>

                                        </button>
                                    </a>

                                </div>

                                <div className="col-md-10 d-none d-md-block d-lg-block">
                                    <hr></hr>
                                    <div className=" d-flex flex-row justify-content-left">
                                        <div className="col-md-4 me-3">
                                            <a className="mt-md-5 mt-3">
                                                <button className="btn  btn-box w-100">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-file-earmark-text text-green" viewBox="0 0 16 16">
                                                        <path d="M5.5 7a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1h-5zM5 9.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 0 1h-2a.5.5 0 0 1-.5-.5z" />
                                                        <path d="M9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V4.5L9.5 0zm0 1v2A1.5 1.5 0 0 0 11 4.5h2V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h5.5z" />
                                                    </svg>
                                                    <span className="mx-3 fs-normal-reg text-green" >Price List</span>

                                                </button>
                                            </a>
                                        </div>
                                        <div className="col-md-4">
                                            <a className="mt-md-5 mt-3">
                                                <button className="btn  btn-box w-100">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-file-earmark-text text-green" viewBox="0 0 16 16">
                                                        <path d="M5.5 7a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1h-5zM5 9.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 0 1h-2a.5.5 0 0 1-.5-.5z" />
                                                        <path d="M9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V4.5L9.5 0zm0 1v2A1.5 1.5 0 0 0 11 4.5h2V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h5.5z" />
                                                    </svg>
                                                    <span className="mx-3 fs-normal-reg text-green" >Catalogue</span>

                                                </button>
                                            </a>
                                        </div>
                                    </div>
                                    <hr></hr>
                                    <div className=" d-flex flex-row justify-content-left">
                                        <div className="col-md-4 me-3">


                                            <div className="btn  btn-box w-100 d-flex flex-row justify-content-between ">

                                                <button className="btn ms-0 my-auto p-0" onClick={() => { setValueIncrement() }}><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-plus-lg" viewBox="0 0 20 20">
                                                    <path fillRule="evenodd" d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2Z" />
                                                </svg></button>
                                                <p className="mb-0 mx-2 my-auto fs-x-large-regular " >{incValue}pcs</p>
                                                <button className=" btn my-auto p-0" onClick={() => { setValueDecrement() }}><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-dash-lg" viewBox="0 0 20 20">
                                                    <path fillRule="evenodd" d="M2 8a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11A.5.5 0 0 1 2 8Z" />
                                                </svg></button>

                                            </div>


                                        </div>
                                        <div className="col-md-4">
                                            <a onClick={openSnackBar} className="pointer">
                                                <button className="btn  btn-box w-100 bg-green">

                                                    <p className="mb-0 fs-x-large-regular text-light" >Add to cart</p>

                                                </button>
                                            </a>
                                        </div>
                                    </div>


                                </div>
                                <div className="col-md-10 mt-3 mt-md-0">

                                    <hr></hr>
                                    <div className="accordion accordion-flush prod-accordion zi-0" id="detailsAccordion">
                                        <div className="accordion-item bg-transparent">
                                            <h2 className="accordion-header fs-x-large-regular my-auto" id="flush-headingDetails">
                                                <button className="accordion-button collapsed  p-0 bg-transparent" type="button" data-bs-toggle="collapse" data-bs-target="#collapseDetails" id="details" aria-expanded="false" aria-controls="collapseDetails">
                                                    &nbsp;&nbsp;&nbsp;Details
                                                </button>
                                            </h2>
                                            <div id="collapseDetails" className="accordion-collapse collapse" aria-labelledby="flush-headingDetails" data-bs-parent="#detailsAccordion">
                                                <div className="accordion-body px-0 u-list">
                                                    <div className=" d-flex flex-row my-2">
                                                        <ul className="ul-list">
                                                            <li><span className="fs-normal-light me-4">Wing Length</span></li>
                                                            <li><span className="fs-normal-light me-4">Wing Breadth</span></li>
                                                            <li><span className="fs-normal-light me-4">Ideal for</span></li>
                                                            <li><span className="fs-normal-light me-4">Speed settings</span></li>
                                                            <li><span className="fs-normal-light me-4">Power</span></li>

                                                        </ul>
                                                        <ul className="ul-list">
                                                            <li><span className="fs-normal-med">60 cm</span></li>
                                                            <li><span className="fs-normal-med">20 cm</span></li>
                                                            <li><span className="fs-normal-med">Home | Office</span></li>
                                                            <li><span className="fs-normal-med">3 Levels</span></li>
                                                            <li><span className="fs-normal-med">70 Watts</span></li>

                                                        </ul>

                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <hr></hr>
                                    <div className="accordion accordion-flush prod-accordion zi-0" id="warrantyAccordion">
                                        <div className="accordion-item bg-transparent">
                                            <h2 className="accordion-header fs-x-large-regular my-auto" id="flush-headingWarranty">
                                                <button className="accordion-button collapsed  p-0 bg-transparent" type="button" data-bs-toggle="collapse" data-bs-target="#collapseWarranty" id="warranty" aria-expanded="false" aria-controls="collapseWarranty">
                                                    &nbsp;&nbsp;&nbsp;Warranty
                                                </button>
                                            </h2>
                                            <div id="collapseWarranty" className="accordion-collapse collapse" aria-labelledby="flush-headingWarranty" data-bs-parent="#warrantyAccordion">
                                                <div className="accordion-body px-0 u-list">
                                                    <div className=" d-flex flex-row my-2">
                                                        <ul className="ul-list">
                                                            <li><span className="fs-normal-light me-4">Wing Length</span></li>
                                                            <li><span className="fs-normal-light me-4">Wing Breadth</span></li>
                                                            <li><span className="fs-normal-light me-4">Ideal for</span></li>
                                                            <li><span className="fs-normal-light me-4">Speed settings</span></li>
                                                            <li><span className="fs-normal-light me-4">Power</span></li>

                                                        </ul>
                                                        <ul className="ul-list">
                                                            <li><span className="fs-normal-med">60 cm</span></li>
                                                            <li><span className="fs-normal-med">20 cm</span></li>
                                                            <li><span className="fs-normal-med">Home | Office</span></li>
                                                            <li><span className="fs-normal-med">3 Levels</span></li>
                                                            <li><span className="fs-normal-med">70 Watts</span></li>

                                                        </ul>

                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <hr></hr>
                                </div>


                            </div>
                        </div> <div className="col-md-12 mt-2 mb-3 mt-md-3">
                            <div className="row   justify-content-between mt-3">
                                <p className="fs-large-regular">Similar products</p>

                                <div className="col-md-3 w-col-md-22 mb-3 col-6">
                                    <a href="/product" className="a-nonlink"><div className="card h-100 rounded-card">
                                        <div className="text-center mt-4 mt-md-5">
                                            <img src={fanImg} className="img-fluid w-100" />
                                        </div>
                                        <div className="card-body p-md-3 p-2 mt-2">
                                            <a>
                                                <button className="btn  rounded-btn fs-x-small-reg">
                                                    High speed ceiling fan
                                                </button></a>
                                            <p className="fs-normal-reg mt-1 mt-md-2 mb-0">Ceiling fan black with
                                                golden accents
                                            </p>
                                            <h1 className="fs-large-semibold  mt-1 mt-md-2">Rs. 10,000/-</h1>
                                        </div>

                                    </div></a>
                                </div>
                                <div className="col-md-3 w-col-md-22 col-6  mb-3">
                                    <a href="/product" className="a-nonlink"><div className="card h-100 rounded-card">
                                        <div className="text-center mt-4 mt-md-5">
                                            <img src={fanImg} className="img-fluid w-100" />
                                        </div>
                                        <div className="card-body p-md-3 p-2 mt-2">
                                            <a>
                                                <button className="btn  rounded-btn fs-x-small-reg">
                                                    High speed ceiling fan
                                                </button></a>
                                            <p className="fs-normal-reg mt-1 mt-md-2 mb-0">Ceiling fan black with
                                                golden accents
                                            </p>
                                            <h1 className="fs-large-semibold  mt-1 mt-md-2">Rs. 10,000/-</h1>
                                        </div>
                                    </div></a>
                                </div>
                                <div className="col-md-3 w-col-md-22 col-6  mb-3">
                                    <a href="/product" className="a-nonlink"><div className="card h-100 rounded-card">
                                        <div className="text-center mt-4 mt-md-5">
                                            <img src={fanImg} className="img-fluid w-100" />
                                        </div>
                                        <div className="card-body p-md-3 p-2 mt-2">
                                            <a>
                                                <button className="btn  rounded-btn fs-x-small-reg">
                                                    High speed ceiling fan
                                                </button></a>
                                            <p className="fs-normal-reg mt-1 mt-md-2 mb-0">Ceiling fan black with
                                                golden accents
                                            </p>
                                            <h1 className="fs-large-semibold  mt-1 mt-md-2">Rs. 10,000/-</h1>
                                        </div>
                                    </div></a>
                                </div>
                                <div className="col-md-3 w-col-md-22 col-6  mb-3">
                                    <a href="/product" className="a-nonlink"><div className="card h-100 rounded-card">
                                        <div className="text-center mt-4 mt-md-5">
                                            <img src={fanImg} className="img-fluid w-100" />
                                        </div>
                                        <div className="card-body p-md-3 p-2 mt-2">
                                            <a>
                                                <button className="btn  rounded-btn fs-x-small-reg">
                                                    High speed ceiling fan
                                                </button></a>
                                            <p className="fs-normal-reg mt-1 mt-md-2 mb-0">Ceiling fan black with
                                                golden accents
                                            </p>
                                            <h1 className="fs-large-semibold  mt-1 mt-md-2">Rs. 10,000/-</h1>
                                        </div>
                                    </div></a>
                                </div>
                            </div>


                        </div>
                    </div>
                </div>
                <div className="d-sm-block d-md-none d-lg-none">
                    <div className="row justify-content-between py-2 w-100 sticky-div" >
                        <div className="col-6 ">
                            <div className="btn  btn-box w-100 d-flex flex-row justify-content-between ">

                                <button className="btn ms-0 my-auto p-0 fs-small-title-reg"
                                    name="btnInc3" value={state.btnInc3 == null ? 4 : state.btnInc3}
                                    onClick={setValueIncrement}>+</button>
                                <p className=" mx-2 my-auto fs-x-large-regular " >{state.btnInc3 == null ? 4 : state.btnInc3}pcs</p>
                                <button className=" btn my-auto p-0 fs-small-title-reg"
                                    name="btnInc3" value={state.btnInc3 == null ? 4 : state.btnInc3}
                                    onClick={setValueDecrement}>-</button>

                            </div>
                        </div>

                        <div className="col-6 ">
                            <a className=" a-non-hover w-100 pointer" onClick={openSnackBar}>
                                <button className="btn  btn-box bg-green w-100">
                                    <div className=" d-flex flex-row justify-content-center ">
                                        <p className="mb-0 fs-x-large-regular text-light" >Add to cart</p>
                                    </div>
                                </button>
                            </a>

                        </div>
                    </div>
                </div>

                <br></br>

            </div>
        </UserLayout>
    );

}
export default Magnify
